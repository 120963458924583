import React from "react";
import { Box, TextField, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";


const CustomInput = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    width: 275,
    height: 45,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",

    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: lightTheme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: lightTheme.palette.primary.main,
    },
  },
});


export default function CustomizedInputs() {
  return (
    <CustomInput />
  );
}