import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPipe,
  faChevronRight,
  faChevronDown,
  faCalendarDays,
  faAddressCard,
  faUserPlus,
  faImages,
} from '@fortawesome/pro-regular-svg-icons'

export const SidebarData = [
  /* {
    title: "Calendrier",
    path: "/calendrier",
    icon: <FontAwesomeIcon icon={faCalendarDays} style={{fontSize: "20px"}}/>,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      {
        title: "Calendrier",
        path: "calendrier",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
      {
        title: "Liste des rendez-vous",
        path: "/calendrier/rendez-vous",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
      {
        title: "Demander un rendez-vous",
        path: "calendrier/demande-de-rendez-vous",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
      {
        title: "Définir les disponibilités",
        path: "calendrier/definir-les-disponibilites",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
    ]
  }, */
  {
    title: 'Gestion des entreprises',
    path: '/gestion-client',
    counter: 'entreprise',
    icon: <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      {
        title: 'Liste des clients entreprises',
        path: 'gestion-client/liste-clients-entreprises',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'client',
      },
      {
        title: 'Liste des prospects entreprises',
        path: 'gestion-prospect/liste-prospects-entreprises',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'prospect',
      },
      /* {
        title: "Demandes de mise en relation",
        path: "gestion-client/mise-en-relation",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
      /* {
        title: "Liste des clients candidats",
        path: "gestion-client/liste-clients-candidats",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "candidat"
      }, */
      /* {
        title: "Dem. de validation de résumé",
        path: "gestion-client/validation-resume",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
      /*  {
        title: "Fiches de poste",
        path: "gestion-client/liste-besoins-entreprises",
        icon: <FontAwesomeIcon icon={faPipe} />
      }, */
    ],
  },
  {
    title: 'Gestion des candidats',
    path: '/gestion-prospect',
    counter: 'candidat',
    icon: <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: '20px' }} />,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      /* {
        title: "Liste des prospects entreprises",
        path: "gestion-prospect/liste-prospects-entreprises",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "entreprise"
      }, */
      {
        title: 'Liste des collaborateurs',
        path: 'gestion-client/liste-clients-candidats',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'client',
      },
      {
        title: 'Liste des candidats',
        path: 'gestion-prospect/liste-prospects-candidats',
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: 'prospect',
      } /* 
      {
        title: "Demandes de rendez-vous",
        path: "gestion-prospect/demandes-de-rendez-vous",
        icon: <FontAwesomeIcon icon={faPipe} />,
        counter: "demande"
      }, */,
    ],
  },
  /* {
    title: "Gestion Cont./Atel.",
    path: "/gestion-contenus-ateliers",
    icon: <FontAwesomeIcon icon={faImages} style={{fontSize: "20px"}}/>,
    iconClosed: <FontAwesomeIcon icon={faChevronRight} />,
    iconOpened: <FontAwesomeIcon icon={faChevronDown} />,
    subNav: [
      {
        title: "Contenus",
        path: "/gestion-contenus-ateliers/contenus",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
      {
        title: "Ateliers",
        path: "/gestion-contenus-ateliers/ateliers",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
      {
        title: "Liste abonnés Newsletter",
        path: "/gestion-contenus-atelier/abonnés-newsletter",
        icon: <FontAwesomeIcon icon={faPipe} />
      },
    ]
  }, */
]
