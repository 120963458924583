import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  List,
  ListItem,
  NoSsr,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import {
  HeadingMethod,
  HeadingNumber,
} from '../../../../../components/headings'
import {
  BannerBackgroundLeft,
  BannerBackgroundRight,
} from '../../../../../components/layouts'
import viewsSite from '../../../../../assets/images/view.png'
import { ButtonPopup } from '../../../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/pro-regular-svg-icons'
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons'
import iconUser from '../../../../../assets/images/icons/userlove.svg'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { FlowerImage } from '../../../../../components/images'
import { useTranslation } from 'react-i18next'
import { contentsService } from '../../../../../services/contents.service'
import { contentsOptions } from '../../../../../services/request'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { CustomButton } from '../../../../../components/buttons'
import { useNavigate } from 'react-router-dom'
import {
  SelectInputBasic,
  SelectInputObject,
} from '../../../../../components/inputs'
import { Markup } from 'interweave'
import { useSelector } from 'react-redux'

const FirstSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '20px',
  paddingBottom: '30px',
  columnGap: '25px',
  flexDirection: 'column',
  rowGap: '112px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '220px',
    flexDirection: 'row',
    paddingBottom: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '250px',
    paddingBottom: '30px',
    columnGap: '100px',
  },
}))

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',

  [theme.breakpoints.up('sm')]: {
    width: '40%',
    rowGap: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '15px',

  [theme.breakpoints.up('sm')]: {
    rowGap: '25px',
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const ViewImage = styled(Box)(({ theme }) => ({
  width: 350,
  height: 225,
  position: 'relative',

  img: {
    position: 'absolute',
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    width: 385,
    height: 250,
  },
  [theme.breakpoints.up('lg')]: {
    width: 450,
    height: 295,
  },
}))

const LiItem = styled(ListItem)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  color: lightTheme.palette.text.primary,
  letterSpacing: '0.2px',
  display: 'list-item',
  listStyleType: 'disc',
  listStyle: 'inside',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const SecondSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '125px',
  paddingBottom: '47px',
  columnGap: '25px',
  flexDirection: 'column',
  rowGap: '95px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '72px',
    flexDirection: 'row',
    columnGap: '100px',
    paddingBottom: '52px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '125px',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '205px',
    paddingBottom: '82px',
    columnGap: '100px',
  },
}))

export default function MethodsSection() {
  const { t, i18n } = useTranslation()
  const [networks, setNetworks] = useState([])
  const [results, setResults] = useState([])
  const [offers, setOffers] = useState([])
  const [offersEN, setOffersEN] = useState([])
  const [errorOffre, setErrorOffre] = useState(false)
  const [offreSelect, setOffreSelect] = useState(offers[0])
  const navigate = useNavigate()
  const user = useSelector((state) => state.userAuth.user)
  if (sessionStorage.getItem('offerSelected'))
    sessionStorage.removeItem('offerSelected')

  useEffect(() => {
    getOfferFr()
    getOfferEn()
    contentsService
      .getAllContents(contentsOptions)
      .then((res) => {
        const response = res.data
        const dataNetworks = response.filter(
          (data) => data.section === 'acces-reseau'
        )
        const dataResults = response.filter(
          (data) => data.section === 'recrutement-resultat'
        )
        setNetworks(dataNetworks)
        setResults(dataResults)
      })
      .catch((err) => console.log(err))
  }, [])

  const handleOffreSelect = (event) => {
    const inputValue = event.target.value
    if(inputValue.id !== 0) setErrorOffre(false)
    setOffreSelect(inputValue)
  }

  const getOfferFr = async () => {
    //setLoading(true)

    try {
      const result = await Api.siteVitrine.getOffers({}, 'fr', 'entreprise')
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        let offres = [{ id: 0, titre: t('button.shooseOffer') }].concat(
          res.data
        )
        setOffers(offres)
        setOffreSelect(offres[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getOfferEn = async () => {
    //setLoading(true)

    try {
      const result = await Api.siteVitrine.getOffers({}, 'en', 'entreprise')
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        let offres = [{ id: 0, titre: t('button.shooseOffer') }].concat(
          res.data
        )
        setOffersEN(offres)
        setOffreSelect(offres[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box component={'section'} width={'100%'} position={'relative'}>
      <HeadingMethod
        left={0}
        type={'left'}
        maxWidthText={{ md: '100%' }}
        sx={{
          justifyContent: 'center',
        }}
        sxTextHeading={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: { sm: 'center', lg: 'flex-start' },
        }}
      >
        {t('company.methodTitle')}
        {/*Pour vous permettre de trouver le candidat idéal, vous avez le choix entre deux méthodes d’accompagnement*/}
      </HeadingMethod>
      <BannerBackgroundRight />
      <BannerBackgroundLeft />
      <Container maxWidth={'lg'}>
        <FirstSectionContainer component={'section'}>
          <br />
          {i18n.language === 'fr' ? (
            <>
              {networks
                .filter((network) => network.lang === 'fr')
                .map((network, index) => (
                  <ContentBox key={index}>
                    {!network.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-title'}
                      />
                    ) : (
                      <HeadingNumber type={'animation'} number={'1'}>
                        {network.titre}
                      </HeadingNumber>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: { xs: '15px', sm: '20px' },
                      }}
                    >
                      {!network.detail ? (
                        <Box width={'100%'}>
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant={'body2'}
                          color={lightTheme.palette.text.primary}
                        >
                          <Markup content={network.detail} />
                          <br />
                          <SelectInputObject
                            items={offers}
                            value={offreSelect}
                            handleChange={handleOffreSelect}
                          />
                          {errorOffre && (
                            <Typography className={'error-message'}>
                              {t('button.shooseOffer')}
                            </Typography>
                          )}
                          <br />
                          <br />
                          <CustomButton
                            handleClick={() => {
                              if (offreSelect.id == 0) {
                                setErrorOffre(true)
                                return
                              }
                              sessionStorage.setItem(
                                'offerSelected',
                                JSON.stringify(offreSelect)
                              )
                              user
                                ? navigate('/user')
                                : navigate('/se-connecter')
                            }}
                            displayIcon={'none'}
                          >
                            {t(`button.findCandidate`)}
                          </CustomButton>
                        </Typography>
                      )}
                    </Box>
                  </ContentBox>
                ))}
            </>
          ) : (
            <>
              {networks
                .filter((network) => network.lang === 'en')
                .map((network, index) => (
                  <ContentBox key={index}>
                    {!network.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-title'}
                      />
                    ) : (
                      <HeadingNumber type={'animation'} number={'1'}>
                        {network.titre}
                      </HeadingNumber>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: { xs: '15px', sm: '20px' },
                      }}
                    >
                      {!network.detail ? (
                        <Box width={'100%'}>
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br /> test abdel
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant={'body2'}
                          color={lightTheme.palette.text.primary}
                        >
                          <Markup content={network.detail} />

                          <br />
                          <SelectInputObject
                            items={offersEN}
                            value={offreSelect}
                            handleChange={handleOffreSelect}
                          />
                          {errorOffre && (
                            <Typography className={'error-message'}>
                              {t('button.shooseOffer')}
                            </Typography>
                          )}
                          <br />
                          <br />
                          <CustomButton
                            handleClick={() => {
                              if (offreSelect.id == 0) {
                                setErrorOffre(true)
                                return
                              }
                              sessionStorage.setItem(
                                'offerSelected',
                                JSON.stringify(offreSelect)
                              )
                              user
                                ? navigate('/user')
                                : navigate('/se-connecter')
                            }}
                            displayIcon={'none'}
                          >
                            {t(`button.findCandidate`)}
                          </CustomButton>
                        </Typography>
                      )}
                    </Box>
                  </ContentBox>
                ))}
            </>
          )}
          <ImageBox>
            <Box>
              <ButtonPopup
                icon={<FontAwesomeIcon icon={faSliders} />}
                borderRadius={'30px 30px 30px 0px'}
              >
                {t('company.filter')}
              </ButtonPopup>
            </Box>
            <ViewImage>
              <img src={viewsSite} alt={'Hcub3 Cvtheque'} />
            </ViewImage>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: { xs: '20px', sm: '25px', lg: '100px' },
              }}
            >
              <ButtonPopup
                icon={<img src={iconUser} alt={'Hcub3 Cvtheque'} />}
                borderRadius={'30px 0px 30px 30px'}
              >
                {t('company.save')}
              </ButtonPopup>
              <ButtonPopup
                icon={<FontAwesomeIcon icon={faUserGroup} />}
                borderRadius={'0px 30px 30px 30px'}
              >
                {t('company.connection')}
              </ButtonPopup>
            </Box>
          </ImageBox>
        </FirstSectionContainer>

        <SecondSectionContainer component={'section'}>
          <ImageBox
            sx={{
              order: { xs: 2, sm: 1 },
            }}
          >
            <FlowerImage />
          </ImageBox>
          {i18n.language === 'fr' ? (
            <>
              {results
                .filter((result) => result.lang === 'fr')
                .map((result, index) => (
                  <ContentBox
                    sx={{
                      order: { xs: 1, sm: 2 },
                    }}
                    key={index}
                  >
                    {!result.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-title'}
                      />
                    ) : (
                      <HeadingNumber type={'animation'} number={'2'}>
                        {result.titre}
                      </HeadingNumber>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: { xs: '15px', sm: '20px' },
                      }}
                    >
                      {!result.detail ? (
                        <Box width={'100%'}>
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant={'body2'}
                          color={lightTheme.palette.text.primary}
                        >
                          <Markup content={result.detail} />
                        </Typography>
                      )}
                    </Box>
                  </ContentBox>
                ))}
            </>
          ) : (
            <>
              {results
                .filter((result) => result.lang === 'en')
                .map((result, index) => (
                  <ContentBox
                    sx={{
                      order: { xs: 1, sm: 2 },
                    }}
                    key={index}
                  >
                    {!result.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-title'}
                      />
                    ) : (
                      <HeadingNumber type={'animation'} number={'2'}>
                        {result.titre}
                      </HeadingNumber>
                    )}

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: { xs: '15px', sm: '20px' },
                      }}
                    >
                      {!result.detail ? (
                        <Box width={'100%'}>
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text'}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            className={'skeleton-text-50'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant={'body2'}
                          color={lightTheme.palette.text.primary}
                        >
                          <Markup content={result.detail} />
                        </Typography>
                      )}
                    </Box>
                  </ContentBox>
                ))}
            </>
          )}
        </SecondSectionContainer>
      </Container>
    </Box>
  )
}
