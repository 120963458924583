import React, { useEffect, useState } from "react";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import lightTheme from "../../../../../styles/theme/lightTheme";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";

const CustomBox = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "40px",
  order: 2,
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    order: 1,
    width: "60%",
  },
}));

const ContentContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px",
}));

export default function AboutSection () {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [moreFr, setMoreFr] = useState([]);
  const [moreEn, setMoreEn] = useState([]);

  useEffect(() => {
    getMoreFr();
    getMoreEn();
  }, []);

  const getMoreFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getContenuFr({
        section: "en-savoir-plus"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setMoreFr(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  }

  const getMoreEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getContenuEn({
        section: "en-savoir-plus"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setMoreEn(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  }


  return (
    <Box
      component={"section"}
      position={"relative"}
      overflow={"hidden"}
    >
      {i18n.language === "fr" ? (
        <>
          {moreFr.map((more, index) => (
              <CustomBox key={index}>
                <ContentContainer>
                  {more.detail?.length > 0 ? (
                    <Typography>
                      <Markup content={more.detail} />
                    </Typography>
                  ) : (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                      <br/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                      <br/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  )}

                </ContentContainer>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Great Vibes",
                    fontSize: {xs: "25px"},
                    lineHeight: "140%",
                    letterSpacing: "0.2px",
                    color: lightTheme.palette.text.primary
                  }}
                >
                  {more.sousTitre?.length > 0 ? (
                    <>
                      {more.sousTitre}
                    </>
                  ) : (
                    <Skeleton variant={"text"} animation={"wave"} width={"20%"} height={14} />
                  )}
                </Typography>
              </CustomBox>
            ))}
        </>
      ):(
        <>
          {moreEn.map((more, index) => (
              <CustomBox key={index}>
                <ContentContainer>
                  {more.detail?.length > 0 ? (
                    <Typography>
                      <Markup content={more.detail} />
                    </Typography>
                  ) : (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                      <br/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                      <br/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  )}

                </ContentContainer>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Great Vibes",
                    fontSize: {xs: "25px"},
                    lineHeight: "140%",
                    letterSpacing: "0.2px",
                    color: lightTheme.palette.text.primary
                  }}
                >
                  {more.sousTitre?.length > 0 ? (
                    <>
                      {more.sousTitre}
                    </>
                  ) : (
                    <Skeleton variant={"text"} animation={"wave"} width={"20%"} height={14} />
                  )}
                </Typography>
              </CustomBox>
            ))}
        </>
      )}
    </Box>
  )
}