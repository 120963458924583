import React from "react";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import logo from "../../assets/images/logos/offerLogo-light.svg";
import darkLogo from "../../assets/images/logos/offerLogo-dark.svg";
import "../../styles/components/_skeleton.scss";
import { CustomButton } from "../buttons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CardContainer = styled(Box)(({ theme }) => ({
    width: 205,
    height: "auto",
    borderRadius: "35px",
    /*overflow: "hidden",*/
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",

    [theme.breakpoints.up("sm")]: {
        width: 220,
        height: 325,
    },
    [theme.breakpoints.up("lg")]: {
        width: 350,
        height: 550,
    },
}));

const HeaderCard = styled(Box)(({ theme, type }) => ({
    width: "100%",
    height: 75,
    /*backgroundColor: lightTheme.palette.primary.b3,*/
    backgroundColor: type === "light"
        ? lightTheme.palette.primary.b3
        : type === "dark"
            ? lightTheme.palette.primary.main
            : lightTheme.palette.primary.b3,
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    padding: "10px 15px",
    position: "relative",
    borderRadius: "35px 35px 0 0",


    [theme.breakpoints.up("lg")]: {
        height: 125,
        padding: "20px 35px",
    },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: 60,
    height: 60,
    top: "-30px",

    img: {
        position: "absolute",
        width: "100%",
        height: "100%"
    },

    [theme.breakpoints.up("lg")]: {
        width: 100,
        height: 100,
        top: "-55px",
    },
}));

const BodyCard = styled(Box)(({ theme }) => ({
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
    justifyContent: "space-between",
    height: "calc(100% - 75px)",

    [theme.breakpoints.up("sm")]: {
        rowGap: "25px",
    },
    [theme.breakpoints.up("lg")]: {
        rowGap: "40px",
        padding: "40px 35px",
        height: "calc(100% - 125px)"
    },

}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    rowGap: "15px",
}));

const TitleContent = styled(Typography)(({ theme }) => ({
    color: lightTheme.palette.primary.main,
    textTransform: "uppercase",
}));

const ListWrap = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "15px",

    [theme.breakpoints.up("sm")]: {
        rowGap: "10px",
    },
    [theme.breakpoints.up("lg")]: {
        rowGap: "30px",
    },
}));

const ListItem = styled('li')(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "10px",

    [theme.breakpoints.up("sm")]: {
        columnGap: "15px"
    },
    [theme.breakpoints.up("lg")]: {
        columnGap: "30px",
    },
}));

const IconContainer = styled(Box)(({ theme }) => ({
    color: lightTheme.palette.primary.b3,
    "& .icon": {
        fontSize: 20,
    }
}));

const Card = ({ items, type }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.userAuth.user);
    if(sessionStorage.getItem("offerSelected")) sessionStorage.removeItem("offerSelected")
    //console.log(sessionStorage.getItem("currency"))
    return (
        <>
            {items.points ? (
                <>
                    <Box height={"100%"}>
                        <HeaderCard type={type}>

                            {type === "light" ? (
                                <ImageContainer>
                                    <img src={logo} alt={"Hcub3 Cvtheque"} />
                                </ImageContainer>
                            ) : (
                                <ImageContainer>
                                    <img src={darkLogo} alt={"Hcub3 Cvtheque"} />
                                </ImageContainer>
                            )}

                            {!items.titre ? (
                                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                            ) : (
                                <Typography
                                    variant={"subtitle2"}
                                    color={"#FFFFFF"}
                                    textTransform={"uppercase"}
                                    textAlign={"center"}
                                >
                                    {items.titre}
                                </Typography>
                            )}

                        </HeaderCard>
                        <BodyCard>
                            <ContentBox>
                                {!items.prix ? (
                                    <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"} />
                                ) : (
                                    <TitleContent
                                        variant={"subtitle2"}
                                        textAlign={"center"}
                                    >
                                        Prix : {(items.prix * JSON.parse(sessionStorage.getItem("currency")).currentRate).toFixed(2)} {JSON.parse(sessionStorage.getItem("currency")).devise}
                                    </TitleContent>
                                )}

                                <Box>
                                    <List lists={items.points} />
                                </Box>
                            </ContentBox>
                            <CustomButton handleClick={() => {
                                sessionStorage.setItem("offerSelected", JSON.stringify(items));
                                (user) ? navigate("/user") : navigate("/se-connecter");
                            }} displayIcon={"none"}>{t(`button.findJob`)}</CustomButton>
                        </BodyCard>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

const List = ({ lists }) => {
    return (
        <ListWrap>

            {lists[1]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[1]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}

            {lists[2]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[2]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}

            {lists[3]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[3]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}

            {lists[4]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[4]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}

            {lists[5]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[5]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}

            {lists[6]?.length > 0 ? (
                <ListItem>
                    <IconContainer>
                        <FontAwesomeIcon icon={faCircleCheck} className={"icon"} />
                    </IconContainer>
                    <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                        {lists[6]}
                    </Typography>
                </ListItem>
            ) : (
                <></>
            )}
        </ListWrap>
    )
}

export default function OffersCard(props, { color }) {
    return (
        <>
            {props.items && props.items.map((item, index) => (
                <CardContainer key={index}>
                    <Card items={item} type={color} />
                </CardContainer>
            ))}
        </>
    )
}