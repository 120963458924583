import React, { createRef, useState } from "react";
import { LayoutCalendar } from "./styles";
import { Box, Typography } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "date-fns/locale/fr";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import lightTheme from "../../styles/theme/lightTheme";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';

const CalendarRdv = ({currentEvents, dateClick, eventContent, eventClick, value, setValue, register, errors}) => {
  const calendarRef = createRef();

  const state = {
    initialView: "dayGridMonth",
  };

  return (
    <LayoutCalendar className={"demo-app-main"}>
      <Box class="fc-right-custom">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
          <DatePicker
            onChange={setValue}
            value={value}
            inputFormat={"DD/MM/YYYY"}
            renderInput={({inputRef, inputProps, InputProps}) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 45,
                  backgroundColor: lightTheme.palette.primary.b2,
                  borderRadius: "5px",

                  "& .css-1laqsz7-MuiInputAdornment-root": {
                    height: "100%",
                    width: "50px",
                    margin: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    "& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
                      margin: 0,
                      color: "#FFFFFF",
                    }
                  }
                }}
              >
                <input
                  ref={inputRef}
                  {...inputProps}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px 0 0 5px",
                    border: "none",
                    padding: "10px 15px",
                    fontFamily: "Century Gothic",
                    fontSize: 14,
                    lineHeight: "140%",
                    letterSpacing: "0.2px",
                    color: lightTheme.palette.text.primary,
                    backgroundColor: "#FFFFFF",
                  }}
                  {...register}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
        {errors.dateDebut && (
          <Typography className={"error-message"}>
            {errors.dateDebut.message}
          </Typography>
        )}
      </Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        locale={frLocale}
        timeZone={"UTC"}
        headerToolbar={{
          left: 'prev title next',
          right: ''
        }}
        editable={true}
        selectable={false}
        selectMirror={true}
        initialView={state.initialView}
        events={currentEvents}
        dateClick={dateClick}
        eventContent={eventContent}
        eventClick={eventClick}
      />
    </LayoutCalendar>
  )
}

export default CalendarRdv;