import React, { useEffect, useState } from 'react'
import {
  Box,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Switch,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import {
  LANGUES,
  SOFTSKILLS,
  TYPE_CONTRAT,
  TYPE_DEVISE,
  VALEURS,
  niveauLanguesList,
} from '../../data'
import { countries } from '../../data'
import {
  faClose,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
//import isoCountries from "i18n-iso-countries";

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditBesoinEntreprise = ({ open, onClose, onSave, id, besoin, role }) => {
  const { t, i18n } = useTranslation()

  const proSchema = yup.object({
    nomSite: yup.string().required('Ce champ est obligatoire.'),
    adresse: yup.string().required('Ce champ est obligatoire.'),
    codePostal: yup.number().required(t('errorMessage.zipCodeValid')).typeError(t('errorMessage.zipCodeValid')),
  })

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [refresh, setRefresh] = useState(1)
  
  const [selectedCountry, setSelectedCountry] = useState(
    besoin ? { label: besoin.pays } : { label: 'France' }
  )
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState(
    besoin ? besoin.ville : 'Paris'
  )
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      adresse: besoin ? besoin.adresse : '',
      ville: besoin ? besoin.ville : '',
      pays: besoin ? besoin.pays : '',
      nomSite: besoin ? besoin.nomSite : '',
      codePostal: besoin ? besoin.codePostal : '',
    },
    resolver: yupResolver(proSchema),
  })

  useEffect(() => {
    handleCountryChange(selectedCountry, besoin)
  }, ['selectedCountry'])

  const handleCountryChange = async (country, besoin = null) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        if (!besoin) setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.bebug(error)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    let datas = {
      entreprise: '/api/entreprises/' + id,
      pays: selectedCountry.label,
      ville: selectedCity,
      ...data,
    }

    datas.ville = selectedCity
    datas.pays = selectedCountry.label

   /*  console.log(datas)
    return */

    //return
    if (besoin) {
      try {
        const result = await Api.adresse.edit(besoin.id, datas)
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    } else {
      try {
        const result = await Api.adresse.add(datas, {})
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {besoin ? 'Modifier l\'adresse' : 'Ajouter une adresse'}

          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              {/* <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                Nom du site
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  disableClearable
                  value={posteSelect ? posteSelect : {}}
                  id="asynchronous-postes"
                  sx={{ width: '100%', height: '45px' }}
                  open={posteOpen}
                  onChange={(event, newValue) => {
                    setPosteSelect(newValue)
                  }}
                  onKeyDown={(event, newInputValue) => {
                    clearTimeout(typingTimer)
                    if (event.target.value) {
                      typingTimer = setTimeout(function () {
                        getPostes(event.target.value)
                      }, doneTypingInterval)
                    }
                  }}
                  onOpen={() => {
                    setPosteOpen(true)
                  }}
                  onClose={() => {
                    setPosteOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.titre === value.titre
                  }
                  getOptionLabel={(option) => option.titre}
                  options={postes}
                  loading={loadingPoste}
                  renderInput={(params) => (
                    <TextField
                      id="text-asynchronous-postes"
                      {...params}
                      sx={{ backgroundColor: '#fff' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingPoste ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid> */}

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>Nom du site</FormLabel>
                <TextInputClassic
                  placeholder={'Nom du site'}
                  type={'text'}
                  register={{ ...register('nomSite') }}
                />
                {errors.nomSite && (
                  <Typography className={'error-message'}>
                    {errors.nomSite.message}
                  </Typography>
                )}
              </Grid>

              {/* <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobContractType')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <SelectInputBasic
                  type={'border'}
                  items={typesContrat}
                  value={typeContratSelect}
                  handleChange={handleTypeContratSelect}
                  //register={{ ...register('typeContrat') }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobPositionType')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleChangeType}
                    checked={selectedTypeValue === 'Remplacement de poste'}
                    value="Remplacement de poste"
                    control={<Radio />}
                    label={t('box.jobReplacement')}
                  />
                  <FormControlLabel
                    onChange={handleChangeType}
                    checked={selectedTypeValue === 'Création de poste'}
                    value="Création de poste"
                    control={<Radio />}
                    label={t('box.jobCreation')}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobExpectedStartDate')}
                </FormLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'fr'}
                >
                  <DatePicker
                    onChange={changeDate}
                    value={dateValue}
                    inputFormat={'DD/MM/YYYY'}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: 45,
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root': {
                            height: '100%',
                            width: '50px',
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                              {
                                margin: 0,
                                color: '#FFFFFF',
                              },
                          },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #9E9E9E',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>{t('box.jobPositionHours')}</FormLabel>
                <TextInputClassic
                  placeholder={'9h-16h'}
                  type={'text'}
                  register={{ ...register('horaires') }}
                />
                {errors.horaires && (
                  <Typography className={'error-message'}>
                    {errors.horaires.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobSalary')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={'10000'}
                  type={'text'}
                  register={{ ...register('salaire') }}
                />
                {errors.salaire && (
                  <Typography className={'error-message'}>
                    {errors.salaire.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobCurrency')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <SelectInputBasic
                  type={'border'}
                  items={typesDevise}
                  value={typeDeviseSelect}
                  handleChange={handleTypeDeviseSelect}
                  //register={{ ...register('typeContrat') }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                {t('box.jobSalaryOn')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 12}
                    value="12"
                    control={<Radio />}
                    label={'12 ' + t('box.mounth')}
                  />
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 13}
                    value="13"
                    control={<Radio />}
                    label={'13 ' + t('box.mounth')}
                  />
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 14}
                    value="14"
                    control={<Radio />}
                    label={'14 ' + t('box.mounth')}
                  />
                </RadioGroup>
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  Pays
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  value={selectedCountry ? selectedCountry : {}}
                  onChange={(event, newValue) => {
                    handleCountryChange(newValue)
                  }}
                  id="country-select"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  Ville
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>

                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  value={selectedCity ? selectedCity : {}}
                  onChange={(event, newValue) => {
                    setSelectedCity(newValue)
                  }}
                  id="country-select"
                  options={cities}
                  autoHighlight
                  //getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('contact.zipCode')}
                </FormLabel>
                <TextInputClassic
                  placeholder={t('contact.zipCode')}
                  width={'100%'}
                  register={{ ...register('codePostal') }}
                />
                {errors.codePostal && (
                  <Typography className={'error-message'}>
                    {errors.codePostal.message}
                  </Typography>
                )}
              </Grid>

              
              {/* <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>Langues</FormLabel>
                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  multiple
                  options={LANGUES}
                  defaultValue={langues}
                  freeSolo
                  onChange={(event, newValue) => {
                    setLangues(newValue)
                  }}
                  renderTags={(langues, getTagProps) =>
                    langues.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ option })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Ajouter les langues" />
                  )}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>Adresse</FormLabel>
                <TextareaInputClassic
                  placeholder={'Adresse'}
                  width={'100%'}
                  register={{ ...register('adresse') }}
                />
                {errors.adresse && (
                  <Typography className={'error-message'}>
                    {errors.adresse.message}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* <Grid
              container
              spacing={2}
              sx={{
                display: step === 2 ? 'flex' : 'none',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobBenefits')}
                </FormLabel>
                <TextInputClassic
                  placeholder={t('box.jobBenefits')}
                  type={'text'}
                  register={{ ...register('avantages') }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobTelework')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={'0'}
                  type={'text'}
                  register={{ ...register('teletravailPropose') }}
                />
                {errors.teletravailPropose && (
                  <Typography className={'error-message'}>
                    {errors.teletravailPropose.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobReport')}
                </FormLabel>
                <TextInputClassic
                  placeholder={''}
                  type={'text'}
                  register={{ ...register('reportDirect') }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.competences')}
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={competences}
                  options={[]}
                  onChange={(event, newValue) => {
                    setCompetences(newValue)
                  }}
                  freeSolo
                  renderTags={(competences, getTagProps) =>
                    competences.map((competence, index) => (
                      <Chip
                        variant="outlined"
                        label={competence}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.softskils')}
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={softskils}
                  options={SOFTSKILLS[i18n.language].sort()}
                  onChange={(event, newValue) => {
                    setSoftskils(newValue)
                  }}
                  renderTags={(softskils, getTagProps) =>
                    softskils.map((softskill, index) => (
                      <Chip
                        variant="outlined"
                        label={softskill}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.values')}
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={valeurs}
                  options={VALEURS[i18n.language]}
                  onChange={(event, newValue) => {
                    setValeurs(newValue)
                  }}
                  renderTags={(valeurs, getTagProps) =>
                    valeurs.map((valeur, index) => (
                      <Chip
                        variant="outlined"
                        label={valeur}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeDeplacement}
                      defaultChecked={deplacementValue}
                    />
                  }
                  label={t('box.jobTrip')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangePermis}
                      defaultChecked={permisValue}
                    />
                  }
                  label={t('box.jobPermit')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeVehicule}
                      defaultChecked={vehiculeValue}
                    />
                  }
                  label={t('box.jobVehicle')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: step === 3 ? 'flex' : 'none',
                flexDirection: { xs: 'column', sm: 'column' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                  minHeight: '400px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.languages')}
                </FormLabel>
                {langues.map((lng, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Autocomplete
                        sx={{
                          width: '100%',
                          backgroundColor: '#fff',
                        }}
                        options={LANGUES}
                        value={lng.langue}
                        freeSolo
                        onChange={(event, newValue) => {
                          langues[index].langue = newValue
                          setLangues(langues)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('box.addLanguages')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Autocomplete
                        disableClearable
                        value={niveauLanguesList[lng.niveau - 1]}
                        sx={{ width: '100%', height: '45px' }}
                        onChange={(event, newValue) => {
                          langues[index].niveau = newValue.id
                          setLangues(langues)
                          setRefresh(Math.random())
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.titre === value.titre
                        }
                        getOptionLabel={(option) => option.titre}
                        options={niveauLanguesList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ backgroundColor: '#fff' }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      sx={{
                        padding: 1,
                        alignContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        fontSize={20}
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          langues.splice(index, 1)
                          setLangues(langues)
                          setRefresh(Math.random())
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Box
                  width={'100%'}
                  border={'1px dotted #666'}
                  padding={1}
                  borderRadius={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={handleAddLangue}
                >
                  <FontAwesomeIcon
                    icon={faSquarePlus}
                    fontSize={20}
                    color={'#666'}
                    style={{ margin: '0 5px -2px 0' }}
                  />
                  {t('box.addOtherLanguage')}
                </Box>
              </Grid> 
            </Grid>*/}
          </form>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditBesoinEntreprise
