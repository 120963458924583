import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Slide,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { SelectDevise, SelectLangages } from '../inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faRightToBracket,
  faSquareUser,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faLinkedinIn,
  faInstagram,
  faFacebook,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import meetupIcon from '../../assets/images/icons/meetup.png'
import {
  Link,
  NavLink,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom'
import logo from '../../assets/images/logos/hcube-logo.png'
import { faChevronDown, faGear } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { BorderButton, CustomButton, WhiteButton } from '../buttons'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'

const TopBar = styled(Box)(({ theme }) => ({
  display: 'none',
  height: 50,
  width: '100%',
  backgroundColor: lightTheme.palette.primary.b2,

  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}))

const CustomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const HeaderBar = styled(Box)(({ theme }) => ({
  height: 100,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  /* zIndex: 3,*/
  justifyContent: 'space-between',
}))

const MenuContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'checked',
  name: 'MenuContainer',
})(({ theme, checked }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: 'all .8 ease-in-out',
  border: checked ? '1px solid' : 'none',
  borderRadius: '50%',
  width: 35,
  height: 25,
  cursor: 'pointer',

  ['& .checkbox']: {
    display: 'none',
  },

  ['& .hamburger-lines']: {
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'flex-end',
    alignItems: 'center',
    transition: 'all 500ms ease-in',

    position: 'relative',
    width: 35,
    height: 25,

    ['& .line']: {
      display: 'block',
      height: 4,
      /*width: "32px",*/
      background: theme.palette.primary.b2,

      position: 'absolute',
      transition: 'all 700ms ease-in-out',
    },

    ['& .line1']: {
      width: '70%',
      top: 0,
      right: 0,
    },
    ['& .line2']: {
      width: '100%',
      top: '11px',
    },
    ['& .line3']: {
      width: '70%',
      /*top: "25px",*/
      bottom: 0,
      right: 0,
    },
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines"]: {
    justifyContent: 'space-between',
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line"]: {
    backgroundColor: theme.palette.primary.b2,
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines .line1"]: {
    transform: 'rotate(135deg)',
    top: '11px',
    width: '100%',
    transition: 'all 700ms ease-in-out',
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line2"]: {
    transform: 'rotate(45deg)',
    top: '11px',
    width: '100%',
    transition: 'all 700ms ease-in-out',
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line3"]: {
    width: 0,
  },

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

const ListContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'activeLink',
  name: 'ListContainer',
})(({ theme, activeLink }) => ({
  display: 'flex',
  rowGap: '30px',
  columnGap: '30px',
  flexDirection: 'column',
  height: '100%',
  /*justifyContent: "space-between",*/
  justifyContent: 'center',
  alignItems: 'center',

  ['.menu-items']: {
    fontFamily: activeLink ? 'Century Gothic Bold' : 'Century Gothic',
    fontSize: 14,
    lineHeight: '140%',
    letterSpacing: '0.2px',
    color: activeLink
      ? lightTheme.palette.primary.main
      : lightTheme.palette.grey.dark,
    cursor: 'pointer',
    position: 'relative',
    listStyle: 'none',
    textAlign: 'center',

    button: {
      background: 'transparent',
      border: 'none',
      fontFamily: activeLink ? 'Century Gothic Bold' : 'Century Gothic',
      fontSize: 14,
      lineHeight: '140%',
      letterSpacing: '0.2px',
      color: activeLink
        ? lightTheme.palette.primary.main
        : lightTheme.palette.grey.dark,
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        fontFamily: 'Century Gothic Bold',
        color: lightTheme.palette.primary.main,
      },
    },

    '&:hover': {
      fontFamily: 'Century Gothic Bold',
      color: lightTheme.palette.primary.main,
    },
  },

  ['.dropdown']: {
    display: 'none',
    position: 'absolute',
    top: '40px',
    left: '50%',
    margin: '0 auto',
    width: 250,
    height: 90,
    borderRadius: '10px',
    boxShadow: lightTheme.palette.shadow.dropdownShadow,
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    paddingLeft: '20px',
    background: '#FFFFFF',
    transform: 'translate(-50%, 0)',

    '&.show': {
      display: 'flex',
    },
  },

  ['.active']: {
    color: lightTheme.palette.primary.main,
    fontFamily: 'Century Gothic Bold',
  },

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',

    ['.dropdown']: {
      top: '75px',
      left: 'auto',
    },
  },
}))

function MenuLink({ label, to, activeOnlyWhenExact }) {
  let match = useMatch({
    path: to,
    exact: activeOnlyWhenExact,
  })

  return (
    <NavLink to={to} className={match ? 'active' : ''}>
      {label}
    </NavLink>
  )
}

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false)
  let ref = useRef()

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false)
      }
    }
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [dropdown])

  return (
    <li className="menu-items" ref={ref}>
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
            /*className={asPath === items.submenu ? "active" : ""}*/
          >
            {items.title} <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <Dropdown submenus={items.submenu} dropdown={dropdown} />
        </>
      ) : (
        <MenuLink to={`${items.url}`} label={items.title} />
      )}
    </li>
  )
}

const Dropdown = ({ submenus, dropdown }) => {
  return (
    <ul className={`dropdown ${dropdown ? 'show' : ''}`}>
      {submenus.map((submenu, index) => (
        <li key={index} className="menu-items">
          <MenuLink to={submenu.url} label={submenu.title} />
        </li>
      ))}
    </ul>
  )
}

export default function AppNavBar() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [checked, setChecked] = useState(false)
  const [activeLink, setActiveLink] = useState(0)
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const user = useSelector((state) => state.userAuth.user)
  const dispatch = useDispatch()

  const menuItems = [
    {
      title: `${t('header.offers')}`,
      submenu: [
        {
          title: `${t('header.candidate')}`,
          url: '/candidats',
        },
        {
          title: `${t('header.company')}`,
          url: '/entreprises',
        },
      ],
    },
    /*{
        title: `${t('header.contents')}`,
        url: "/contenus",
    },
    {
        title: `${t('header.events')}`,
        url: "/evenements",
    },*/
  ]

  const menuUserItems = [
    {
      title: 'Contenus',
      url: '/contenus',
    },
    {
      title: 'Ateliers',
      url: '/ateliers',
    },
    {
      title: 'Candidat',
      url: '/candidat',
    },
    {
      title: 'Calendrier',
      submenu: [
        {
          title: 'Item 1',
          url: '/item1',
        },
        {
          title: 'Item 2',
          url: '/item2',
        },
      ],
    },
  ]

  const handleClick = useCallback((i) => {
    setActiveLink(i)

    const anchor = document.querySelector(`#sec${i + 1}`)

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [])

  const logout = () => {
    dispatch(userLogout())
    navigate('/')
  }

  return (
    <AppBar
      position={
        pathname === '/candidats' || pathname === '/entreprises'
          ? 'static'
          : 'sticky'
      }
      elevation={0}
      sx={{
        backgroundColor: '#FFFFFF',
        boxShadow: lightTheme.palette.shadow.headerShadow,
      }}
    >
      <Container
        maxWidth={'lg'}
        sx={{
          zIndex: 3,
          backgroundColor: '#FFFFFF',
        }}
      >
        <Toolbar disableGutters>
          <HeaderBar>
            <Link to={'/'}>
              <Box
                component={'img'}
                src={logo}
                alt={'Hcub3 Cvtheque'}
                sx={{ height: { xs: 140, lg: 70 } }}
              />{' '}
              <Box
                sx={{
                  color: '#3caab5',
                  float: 'right',
                  width: 129,
                  lineHeight: '23px',
                  marginLeft: '10px',
                }}
              >
                Gestion Recrutement et Commerciale
              </Box>
            </Link>
            <MenuContainer
              onClick={() => setChecked(!checked)}
              checked={checked}
            >
              <input
                className="checkbox"
                type="checkbox"
                name=""
                id=""
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <div className="hamburger-lines">
                <span className="line line1" />
                <span className="line line2" />
                <span className="line line3" />
              </div>
            </MenuContainer>

            <Box
              sx={{
                display: { xs: 'none', lg: 'flex' },
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: '30px',
              }}
            >
              {/*  {!user && ( */}
              {/*<ListContainer>
                 {menuItems.map((menu, index) => {
                  return (
                    <MenuItems
                      items={menu}
                      key={index}
                      activeLink={activeLink}
                    />
                  )
                })}
              </ListContainer> */}
              {/* )} */}

              <Box
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                }}
              >
                {user && user.data.role === 'ROLE_ENTREPRISE' && (
                  <>
                    <BorderButton
                      displayIconLeft={'none'}
                      displayIconRight={'none'}
                      handleClick={() => navigate('/user/candidat/favoris')}
                    >
                      {t('header.favoris')}
                    </BorderButton>
                    <BorderButton
                      displayIconLeft={'none'}
                      displayIconRight={'none'}
                      handleClick={() => navigate('/user/candidats')}
                    >
                      {t('header.chtheque')}
                    </BorderButton>
                  </>
                )}
                {user ? (
                  <BorderButton
                    type={'rainbow'}
                    textColor={'rainbow'}
                    displayIconLeft={'none'}
                    displayIconRight={'flex'}
                    handleClick={() => navigate('/admin')}
                    icon={
                      <FontAwesomeIcon
                        icon={faSquareUser}
                        style={{
                          background:
                            '-webkit-linear-gradient(#9c47fc, #356ad2)',
                          WebkitBackgroundClip: 'text',
                          textFillColor: 'transparent',
                          color: '#9c47fc',
                        }}
                      />
                    }
                  >
                    {t('header.myProfil')}
                  </BorderButton>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </HeaderBar>
        </Toolbar>
      </Container>

      {!isLg && (
        <Slide direction={'down'} in={checked} timeout={700}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', lg: 'flex' },
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              top: '100px',
              left: 0,
              width: '100%',
              height: { xs: 600, md: 580 },
              backgroundColor: '#FFFFFF',
              zIndex: 2,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
              }}
            >
              <Container
                maxWidth={'lg'}
                sx={{
                  height: '100%',
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                  /*rowGap: "30px",*/
                }}
              >
                <BorderButton
                  type={'rainbow'}
                  textColor={'rainbow'}
                  displayIconLeft={'none'}
                  displayIconRight={'none'}
                  handleClick={() => navigate('/contact')}
                >
                  {t('button.rdv')}
                </BorderButton>

                <ListContainer activeLink={activeLink}>
                  {menuItems.map((menu, i) => {
                    return (
                      <MenuItems
                        items={menu}
                        key={i}
                        activeLink={activeLink}
                        onClick={(e) => {
                          e.preventDefault()
                          handleClick(i)
                          setChecked(!checked)
                        }}
                      />
                    )
                  })}
                </ListContainer>
              </Container>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: 220,
                backgroundColor: lightTheme.palette.primary.b3,
              }}
            >
              <Container
                maxWidth={'lg'}
                sx={{
                  paddingTop: '25px',
                  paddingBottom: '25px',

                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '25px',

                    height: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  <WhiteButton
                    width={'100%'}
                    handleClick={() => navigate('/se-connecter')}
                  >
                    {t('header.login')}
                  </WhiteButton>

                  <Box
                    sx={{
                      display: 'grid',
                      flexDirection: { xs: 'column', sm: 'row' },
                      /*rowGap: "30px",*/
                      rowGap: '10px',

                      /*gridTemplateColumns: { xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)"},
                      gridTemplateRows: { xs: "repeat(2, 1fr)", sm: "1fr"},*/
                      gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(3, 1fr)',
                      },
                      gridTemplateRows: { xs: 'repeat(2, 1fr)', sm: '1fr' },
                      justifyItems: 'center',

                      '.firstBloc': {
                        gridRowStart: 1,
                        gridColumnStart: 1,
                        gridColumnEnd: { xs: 3, sm: 2 },
                        width: '100%',
                      },
                      '.secondBloc': {
                        gridRowStart: { xs: 2, sm: 1 },
                      },
                      '.thirdBloc': {
                        /*gridRowStart: {xs: 2, sm: 1}*/
                        gridRowStart: { xs: 3, sm: 1 },
                      },
                    }}
                  >
                    <Box className="firstBloc">
                      <SelectLangages />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <CustomBox className="secondBloc">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ marginRight: '5px', color: '#FFFFFF' }}
                        />
                        <a href={'mailto:contact@hcub3-consulting.fr'}>
                          <Typography variant={'body2'} color={'#FFFFFF'}>
                            contact@hcub3-consulting.fr
                          </Typography>
                        </a>
                      </CustomBox>
                      {/* <CustomBox
                        className="thirdBloc"
                        sx={{
                          columnGap: '10px',
                        }}
                      >
                        <Typography variant={'body2'} color={'#FFFFFF'}>
                          {t('header.follow')} !
                        </Typography>
                        <CustomBox
                          sx={{
                            columnGap: '5px',
                          }}
                        >
                          <a
                            href={
                              'https://www.linkedin.com/company/jobertechcorp/?'
                            }
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <FontAwesomeIcon
                              icon={faLinkedinIn}
                              style={{ fontSize: 15, color: '#FFFFFF' }}
                            />
                          </a>
                          <a
                            href={'https://www.instagram.com/jobertech/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <FontAwesomeIcon
                              icon={faInstagram}
                              style={{ fontSize: 15, color: '#FFFFFF' }}
                            />
                          </a>
                          <a
                            href={'https://az-az.facebook.com/JoberTech/'}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <FontAwesomeIcon
                              icon={faFacebook}
                              style={{ fontSize: 15, color: '#FFFFFF' }}
                            />
                          </a>
                          <a
                            href={'https://www.youtube.com/watch?v=0jC2LzB7vfY'}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <FontAwesomeIcon
                              icon={faYoutube}
                              style={{
                                fontSize: 15,
                                color: lightTheme.palette.grey.dark,
                              }}
                            />
                          </a>
                        </CustomBox>
                      </CustomBox> */}
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Slide>
      )}
    </AppBar>
  )
}
