import React, { useEffect, useState } from "react";
import {Box, Button, MenuItem, Select, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {faMagnifyingGlass} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomContainer = styled(Box)(({theme}) => ({
    width: "calc(100% - 50px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    rowGap: "40px",
    zIndex: 1,
    bottom: "-130px",
    left: "25px",
    right: "25px",

    [theme.breakpoints.up("sm")]: {
        height: 100,
        flexDirection: "row",
        borderRadius: "10000px",
        boxShadow: lightTheme.palette.shadow.cardShadow,
        overflow: "hidden",
        bottom: "-48px",
    },
    [theme.breakpoints.up("lg")]: {
        width: 760,
        left: 0,
        right: 0,
        margin: "0 auto"
    },
}));

const CustomBox = styled(Box)(({theme}) => ({
    width: "100%",
    height: 90,
    borderRadius: "20px",
    boxShadow: lightTheme.palette.shadow.cardShadow,
    overflow: "hidden",

    [theme.breakpoints.up("sm")]: {
        height: "100%",
        borderRadius: 0,
        boxShadow: "none",
    },

}));

const LabelContainer = styled(Box)(({theme}) => ({
    width: "100%",
    height: 45,
    background: lightTheme.palette.gradients.gradientGreen,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
        height: 50
    },
}));

const LabelBox = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

const InputContainer = styled(Box)(({theme}) => ({
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",

    [theme.breakpoints.up("sm")]: {
        height: 50
    },

}));

const CustomSelect = styled(Select)(({theme}) => ({
    width: "100%",
    height: "100%",
    borderWidth: 0,
    borderRadius: 0,
    overflow: "hidden",
    textAlign: "center",
    fontFamily: "Century Gothic",
    fontSize: 12,
    lineHeight: "140%",
    color: lightTheme.palette.grey.grey,
    textTransform: "uppercase",

    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
    },

    [theme.breakpoints.up("lg")]: {
        fontSize: 14,
    },

}));


const CustomButton = styled(Button)(({theme}) => ({
    width: 250,
    height: 45,
    background: lightTheme.palette.gradients.gradientGreen,
    borderRadius: "30px",
    fontFamily: "Century Gothic Bold",
    fontSize: 12,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: lightTheme.palette.shadow.cardShadow,

    [theme.breakpoints.up("sm")]: {
        height: "100%",
        background: lightTheme.palette.primary.b2,
        borderRadius: 0,

        ".searchIcon": {
            paddingRight: "25px"
        },

        ":hover": {
            background:lightTheme.palette.primary.b2,
        },

    },

    [theme.breakpoints.up("lg")]: {
        fontSize: 14,
    },
}));


export default function SearchBar() {
    const { t, i18n } = useTranslation();
    const navigation = useNavigate();
    const [nextLink, setNextLink] = useState(false);

    const options = [
        {value: 'candidat', text: `${t('home.candidate')}`},
        {value: 'professionnel', text: `${t('home.professional')}`}
    ];


    const options1 = [
        {value: 'parcours-emploi', text: `${t('home.jobPath')}`},
        {value: 'transition-professionnel', text: `${t('home.careerTransition')}`}
    ];

    const options2 = [
        {value: 'recrutement', text: `${t('home.recruitment')}`},
        {value: 'recherche-candidat', text: `${t('home.candidateOpti')}`}
    ];

    const [statusSelected, setStatusSelected] = useState(options[0].value);

    const handleChangeStatus = event => {
        const status = event.target.value;
        setStatusSelected(status);
    };

    const [candidatSelected, setCandidatSelected] = useState([options1[0].value]);
    const [companySelected, setCompanySelected] = useState([options2[0].value]);


    const handleChangeOptions = event => {
        if (statusSelected === 'candidat') {
            const status = event.target.value;
            setCandidatSelected(status);
        } else  {
            const status = event.target.value;
            setCompanySelected(status);
        }
    }

    useEffect(() => {
        moveNextLink()
    }, [nextLink])

    function moveNextLink() {
        if(nextLink && statusSelected === 'candidat' ) {
            navigation('/candidats');
        } else if(nextLink && statusSelected === 'professionnel') {
            navigation('/entreprises')
        }
    }
    
    return (
      <CustomContainer>
          <CustomBox>
              <LabelContainer>
                  <LabelBox
                    sx={{
                        borderRight: "1px solid #FFFFFF"
                    }}
                  >
                      <Typography variant={"subtitle1"} color={"#FFFFFF"} textAlign={"center"}>{t('home.youAre')} ...</Typography>
                  </LabelBox>
                  <LabelBox
                    sx={{
                        borderRight: {xs: "none", sm: "1px solid #FFFFFF"}
                    }}
                  >
                      <Typography variant={"subtitle1"} color={"#FFFFFF"} textAlign={"center"}>{t('home.inSearch')} ...</Typography>
                  </LabelBox>
              </LabelContainer>
              <InputContainer>

                  <CustomSelect
                    onChange={handleChangeStatus}
                    value={statusSelected}
                    IconComponent={() => (<FontAwesomeIcon icon={faChevronDown} style={{position: "absolute", right: "14px"}}/>)}
                  >
                      {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
                      ))}
                  </CustomSelect>
                  
                  {statusSelected === 'candidat' ? (
                    <CustomSelect
                      onChange={handleChangeOptions}
                      value={candidatSelected}
                      IconComponent={() => (<FontAwesomeIcon icon={faChevronDown} style={{position: "absolute", right: "14px"}}/>)}
                    >
                        {options1.map((option, index) => (
                          <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
                        ))}
                    </CustomSelect>
                  ) : (
                    <CustomSelect
                      onChange={handleChangeOptions}
                      value={companySelected}
                      IconComponent={() => (<FontAwesomeIcon icon={faChevronDown} style={{position: "absolute", right: "14px"}}/>)}
                    >
                        {options2.map((option, index) => (
                          <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
                        ))}
                    </CustomSelect>
                  )}
              </InputContainer>
          </CustomBox>

          <CustomButton
            endIcon={<FontAwesomeIcon icon={faMagnifyingGlass} className="searchIcon"/>}
            onClick={() => setNextLink(true)}
          >
              {t('home.solution')}
          </CustomButton>
      </CustomContainer>

    )
}