import { Box, Container, Grid, Skeleton, styled, Typography } from "@mui/material";
import lightTheme from "../../../../../styles/theme/lightTheme";
import image from "../../../../../assets/images/article-3.jpg";
import { CustomButton } from "../../../../../components/buttons";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import { Markup } from "interweave";
import { useNavigate } from "react-router-dom";

const CustomLayout = styled(Box)(({theme}) => ({
  width: "100%",
  height: "100%",
  backgroundColor: lightTheme.palette.primary.b6,
  padding: "20px 0",

  [theme.breakpoints.up("sm")]: {
    padding: "50px 0"
  },

  [theme.breakpoints.up("lg")]: {
    padding: "100px 0"
  },
}));

const ImgContainer = styled(Box)(({theme}) => ({
  width: "100%",
  height: 135,
  borderRadius: 25,
  position: "relative",
  overflow: "hidden",

  img: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  [theme.breakpoints.up("sm")]: {
    width: "100%",
    height: 283,
  },

  [theme.breakpoints.up("lg")]: {
    width: "100%",
    height: 400
  },

}));

const SeparateBanner = styled(Box)(({theme}) => ({
  width: 200,
  height: 20,
  borderRadius: "100px",
  backgroundColor: lightTheme.palette.error.main,
  animation: `${colorEffect} 15s linear infinite`,

  [theme.breakpoints.up("sm")]: {
    width: 220,
  },

  [theme.breakpoints.up("lg")]: {
    width: 230,
  },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const StrategyHome = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contentCandidatFr, setContentCandidatFr] = useState([]);
  const [contentCandidatEn, setContentCandidatEn] = useState([]);
  const [contentEntrepriseFr, setContentEntrepriseFr] = useState([]);
  const [contentEntrepriseEn, setContentEntrepriseEn] = useState([]);


  useEffect(() => {
    getContentCandidatFr();
    getContentEntrepriseFr();
  }, []);

  useEffect(() => {
    getContentCandidatEn();
    getContentEntrepriseEn();
  }, []);

  const getContentCandidatFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "fr",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "candidat");
        setContentCandidatFr(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentEntrepriseFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "fr",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "entreprises");
        setContentEntrepriseFr(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentCandidatEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "en",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "candidat");
        setContentCandidatEn(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentEntrepriseEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "en",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "entreprises");
        setContentEntrepriseEn(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <CustomLayout component={"section"}>
      <Container maxWidth={"lg"}>
        <Grid container spacing={{ xs: 4, sm: 8 }}>
          {i18n.language === "fr" ? (
            <>
              {contentCandidatFr.map((item) => (
                <>
                  <Grid item xs={6}>
                    {!item.photos[0] ? (
                      <Skeleton variant={"rounded"} animation={"wave"} width={"100%"} height={{xs: 100, sm: 135}}/>
                    ) : (
                      <ImgContainer>
                        <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`}/>
                      </ImgContainer>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "45px",
                      justifyContent: "center"
                    }}
                  >
                    {!item.titre ? (
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                    ) : (
                      <Typography variant={"h2"} textTransform={"uppercase"} textAlign={{xs: "center", sm: "left"}}>{item.titre}</Typography>
                      )}

                    {!item.detail ? (
                      <Box width={"100%"} display={{xs: "none", sm: "block"}}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"} display={{xs: "none", sm: "block"}}>
                        <Markup content={item.detail} />
                      </Typography>
                      )}

                    <Box display={{xs: "none", lg: "flex"}} justifyContent={"center"}>
                      <CustomButton displayIcon={"none"} handleClick={() => navigate("/candidats")} width={{xs: "330px" }}>{t('button.findJob')}</CustomButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12} display={{xs: "flex", sm: "none"}}>
                    {!item.detail ? (
                      <Box width={"100%"}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={{xs: "flex", lg: "none"}}
                    justifyContent={"center"}
                  >
                    <CustomButton displayIcon={"none"} width={{xs: "330px" }} handleClick={() => navigate("/candidats")}>{t('button.findJob')}</CustomButton>
                  </Grid>
                </>
              ))}

              <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <SeparateBanner/>
              </Grid>

              {contentEntrepriseFr.map((item) => (
                <>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "45px",
                      justifyContent: "center"
                    }}
                  >
                    {!item.titre ? (
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                    ) : (
                      <Typography variant={"h2"} textTransform={"uppercase"} textAlign={{xs: "center", sm: "left"}}>{item.titre}</Typography>
                    )}

                    {!item.detail ? (
                      <Box width={"100%"} display={{xs: "none", sm: "block"}}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"} display={{xs: "none", sm: "block"}}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}

                    <Box display={{xs: "none", lg: "flex"}} justifyContent={"center"}>
                      <CustomButton displayIcon={"none"} width={{xs: "330px" }} handleClick={() => navigate("/entreprises")}>{t('button.findCandidate')}</CustomButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {!item.photos[0] ? (
                      <Skeleton variant={"rounded"} animation={"wave"} width={"100%"} height={{xs: 100, sm: 135}}/>
                    ) : (
                      <ImgContainer>
                        <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`}/>
                      </ImgContainer>
                    )}
                  </Grid>

                  <Grid item xs={12} display={{xs: "flex", sm: "none"}}>
                    {!item.detail ? (
                      <Box width={"100%"}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={{xs: "flex", lg: "none"}}
                    justifyContent={"center"}
                  >
                    <CustomButton displayIcon={"none"} width={{xs: "330px" }} handleClick={() => navigate("/entreprises")}>{t('button.findCandidate')}</CustomButton>
                  </Grid>
                </>
              ))}
            </>
          ) : (
            <>
              {contentCandidatEn.map((item) => (
                <>
                  <Grid item xs={6}>
                    {!item.photos[0] ? (
                      <Skeleton variant={"rounded"} animation={"wave"} width={"100%"} height={{xs: 100, sm: 135}}/>
                    ) : (
                      <ImgContainer>
                        <img src={item.photos[0].lien} alt={"Hcub3 Cvtheque - strategie"}/>
                      </ImgContainer>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "45px",
                      justifyContent: "center"
                    }}
                  >
                    {!item.titre ? (
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                    ) : (
                      <Typography variant={"h2"} textTransform={"uppercase"} textAlign={{xs: "center", sm: "left"}}>{item.titre}</Typography>
                    )}

                    {!item.detail ? (
                      <Box width={"100%"} display={{xs: "none", sm: "block"}}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"} display={{xs: "none", sm: "block"}}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}

                    <Box display={{xs: "none", lg: "flex"}} justifyContent={"center"}>
                      <CustomButton displayIcon={"none"} width={{xs: "330px" }}>{t('button.findJob')}</CustomButton>
                    </Box>
                  </Grid>

                  <Grid item xs={12} display={{xs: "flex", sm: "none"}}>
                    {!item.detail ? (
                      <Box width={"100%"}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={{xs: "flex", lg: "none"}}
                    justifyContent={"center"}
                  >
                    <CustomButton displayIcon={"none"} width={{xs: "330px" }}>{t('button.findJob')}</CustomButton>
                  </Grid>
                </>
              ))}

              <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                <SeparateBanner/>
              </Grid>

              {contentEntrepriseEn.map((item) => (
                <>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "45px",
                      justifyContent: "center"
                    }}
                  >
                    {!item.titre ? (
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                    ) : (
                      <Typography variant={"h2"} textTransform={"uppercase"} textAlign={{xs: "center", sm: "left"}}>{item.titre}</Typography>
                    )}

                    {!item.detail ? (
                      <Box width={"100%"} display={{xs: "none", sm: "block"}}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"} display={{xs: "none", sm: "block"}}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}

                    <Box display={{xs: "none", lg: "block"}}>
                      <CustomButton displayIcon={"none"} width={{xs: "330px" }}>{t('button.findCandidate')}</CustomButton>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    {!item.photos[0] ? (
                      <Skeleton variant={"rounded"} animation={"wave"} width={"100%"} height={{xs: 100, sm: 135}}/>
                    ) : (
                      <ImgContainer>
                        <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`}/>
                      </ImgContainer>
                    )}
                  </Grid>

                  <Grid item xs={12} display={{xs: "flex", sm: "none"}}>
                    {!item.detail ? (
                      <Box width={"100%"}>
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"100%"} height={14} />
                        <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14} />
                      </Box>
                    ) : (
                      <Typography variant={"body2"}>
                        <Markup content={item.detail} />
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    display={{xs: "flex", lg: "none"}}
                    justifyContent={"center"}
                  >
                    <CustomButton displayIcon={"none"} width={{xs: "330px" }}>{t('button.findCandidate')}</CustomButton>
                  </Grid>
                </>
              ))}
            </>
            )}
        </Grid>
      </Container>
    </CustomLayout>
  )
}

export default StrategyHome;