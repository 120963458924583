import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import moment from 'moment-timezone'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { faCircleCheck, faClose } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { FILE_URL } from '../../config'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 100,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditEvent = ({
  open,
  onClose,
  onSave,
  poste,
  id,
  role,
  event,
  showParams = true,
}) => {
  const { t, i18n } = useTranslation()
  const currentDateFive = new Date()
  const currentDateThirt = new Date()
  currentDateFive.setMinutes(currentDateFive.getMinutes() + 5)
  currentDateThirt.setMinutes(currentDateThirt.getMinutes() - 30)

  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [fonctionSelect, setFonctionSelect] = useState(poste)
  const [posteSelect, setPosteSelect] = useState(poste)
  const [errorTime, setErrorTime] = useState(false)
  const [dateValue, setDateValue] = useState(null)
  const [feedbackValue, setFeedbackValue] = useState('')
  const [errorFeedback, setErrorFeedback] = useState(false)

  const changeDate = (dateValue) => {
    setDateValue(dateValue.target.value)
  }

  const changeFeedback = (e) => {
    if (e.target.value === '') {
      setErrorFeedback(true)
    } else {
      setErrorFeedback(false)
    }
    setFeedbackValue(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        /* if (res.entreprise?.fonctions?.id)
          setFonctionSelect(res.entreprise?.fonctions?.titre) */
        //if (res.candidat?.poste) setPosteSelect(res.candidat?.poste)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitNewDate = async () => {
    setLoading(true)

    try {
      const result = await Api.calendar.edit(event.id, {
        dateDebut: dateValue,
        dateFin: dateValue,
        statusUser: 'planifier',
      })
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const statusEvent = async (data) => {
    setLoading(true)

    try {
      const result = await Api.calendar.edit(event.id, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const feedbackEvent = async () => {
    if (feedbackValue === '') {
      setErrorFeedback(true)
      return
    }

    let data = {
      feedbackCandidat: feedbackValue,
    }
    if (role === 'ROLE_ENTREPRISE') {
      data = {
        feedbackEntreprise: feedbackValue,
      }
    }
    setLoading(true)
    try {
      const result = await Api.calendar.edit(event.id, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.feedbackSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  if (event.type === 'rdv_entreprise')
    return (
      <>
        <Modal
          open={open}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(51, 51, 51, 0.65)',
            },
          }}
          style={{ overflow: 'auto' }}
        >
          <ModalContainer>
            <Typography variant={'subtitle2'} textTransform={'uppercase'}>
              {t('box.title.meetingSuggestion')}
              <Box
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={25}
                  onClick={onClose}
                />
              </Box>
            </Typography>
            <BoxContainer>
              <form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  {role === 'ROLE_ENTREPRISE' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b> {t('contact.candidate')} :</b>{' '}
                          {event.statusUser === 'accepter'
                            ? event?.candidat?.profile?.nom +
                              ' ' +
                              event?.candidat?.profile?.prenom
                            : '#Jober000' + event?.candidat?.profile?.id}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {role !== 'ROLE_ENTREPRISE' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={role !== 'ROLE_ENTREPRISE' ? 6 : 12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t('contact.company')} :</b>{' '}
                          {event?.entreprise?.raisonSocial}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {posteSelect && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('filter.poste')} :</b> {posteSelect}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('profile.date')} :</b>{' '}
                      {moment(event.dateDebut)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('profile.hour')} :</b>{' '}
                      {moment(event.dateDebut)
                        .tz('Europe/Paris')
                        .format('H:mm')}
                    </Typography>
                  </Grid>
                  {event.statusUser === 'accepter' &&
                    event.candidat?.profile?.documents[0] &&
                    currentDateFive > new Date(event.dateDebut) && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t('box.candidateCv')} :</b>{' '}
                          <a
                            href={
                              FILE_URL +
                              event.candidat?.profile?.documents[0].nom
                            }
                            target={'_blank'}
                          >
                            {event.candidat?.profile?.documents[0].nom}
                          </a>
                        </Typography>
                      </Grid>
                    )}
                  {event.statusUser === 'accepter' && event.lien && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('box.videoconference')} :</b>{' '}
                        <a href={event.lien} target={'_blank'}>
                          {t('button.clickHere')}
                        </a>
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('box.appointmentStatus')} : </b>{' '}
                      {event.statusUser
                        ? event.statusUser?.toUpperCase()
                        : t('box.proposal')}
                    </Typography>
                  </Grid>
                  {role !== 'ROLE_CANDIDAT' &&
                    event.statusUser === 'replanifier' && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.scheduleNewDate')}</b>
                          </Typography>
                          <TextField
                            type="datetime-local"
                            onChange={changeDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                            defaultValue={
                              moment(event.dateDebut)
                                .tz('Europe/Paris')
                                .format('yyyy-MM-DD') +
                              'T' +
                              moment(event.dateDebut)
                                .tz('Europe/Paris')
                                .format('H:mm')
                            }
                          />

                          {errorTime && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.date')}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Box className={'blockButtons'}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <CustomButton
                                  displayIcon={'none'}
                                  handleClick={submitNewDate}
                                >
                                  {t('button.reschedule')}
                                </CustomButton>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </LocalizationProvider>
                    )}
                  {(role === 'ROLE_ENTREPRISE' || role === 'ROLE_CANDIDAT') &&
                    event.statusUser === 'accepter' &&
                    currentDateThirt > new Date(event.dateDebut) && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        {role === 'ROLE_ENTREPRISE' &&
                        event.feedbackEntreprise ? (
                          <Typography>
                            <b>{t('box.yourFeedback')}</b> <br></br>
                            {event.feedbackEntreprise}
                          </Typography>
                        ) : role === 'ROLE_CANDIDAT' &&
                          event.feedbackCandidat ? (
                          <Typography>
                            <b>{t('box.yourFeedback')}</b> <br></br>
                            {event.feedbackCandidat}
                          </Typography>
                        ) : (
                          <>
                            <Typography>
                              <b>{t('box.giveYourFeedback')}</b>
                            </Typography>

                            <TextareaInputClassic
                              placeholder={'Feedback'}
                              width={'100%'}
                              onChange={changeFeedback}
                            />
                          </>
                        )}

                        {errorFeedback && (
                          <Typography className={'error-message'}>
                            {t('errorMessage.feedback')}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  {role !== 'ROLE_ENTREPRISE' &&
                    role !== 'ROLE_CANDIDAT' &&
                    event.statusUser === 'accepter' &&
                    (event.feedbackEntreprise || event.feedbackCandidat) && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        {event.feedbackEntreprise && (
                          <Typography>
                            <b>{t('box.companyFeedback')}</b> <br></br>
                            {event.feedbackEntreprise}
                          </Typography>
                        )}

                        {event.feedbackCandidat && (
                          <Typography>
                            <b>{t('box.candidateFeedback')}</b> <br></br>
                            {event.feedbackCandidat}
                          </Typography>
                        )}
                      </Grid>
                    )}
                </Grid>
              </form>
            </BoxContainer>
            {/*<Box className={"wrapperButtons"} width={"100%"}>*/}

            {((role === 'ROLE_ENTREPRISE' && !event.feedbackEntreprise) ||
              (role === 'ROLE_CANDIDAT' && !event.feedbackCandidat)) &&
              event.statusUser === 'accepter' &&
              currentDateThirt > new Date(event.dateDebut) && (
                <Box className={'blockButtons'}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={(e) => {
                        feedbackEvent()
                      }}
                    >
                      {t('button.giveYourFeedback')}
                    </CustomButton>
                  )}
                </Box>
              )}
            {role === 'ROLE_CANDIDAT' &&
              event.statusUser !== 'accepter' &&
              event.statusUser !== 'refuser' &&
              event.statusUser !== 'replanifier' && (
                <Box className={'blockButtons'}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <BorderButtonPurple
                        displayIcon={'none'}
                        handleClick={(e) => {
                          statusEvent({ statusUser: 'refuser' })
                        }}
                      >
                        {t('button.refuse')}
                      </BorderButtonPurple>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={(e) => {
                          statusEvent({ statusUser: 'accepter' })
                        }}
                      >
                        {t('button.accept')}
                      </CustomButton>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={(e) => {
                          statusEvent({ statusUser: 'replanifier' })
                        }}
                      >
                        {t('button.reschedule')}
                      </CustomButton>
                    </>
                  )}
                </Box>
              )}
            {/*</Box>*/}
          </ModalContainer>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
          transition={Slide}
          className={'toast'}
        />
      </>
    )

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
        }}
      >
        <ModalContainer>
          <Typography variant={'subtitle2'} textTransform={'uppercase'}>
            {t('profile.rdv')}
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          <BoxContainer>
            <form>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                {role === 'ROLE_ENTREPRISE' && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('header.company')} :</b>{' '}
                        {snapShot?.entreprise?.raisonSocial}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('contact.function')} :</b> {fonctionSelect}
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  sm={role !== 'ROLE_ENTREPRISE' && posteSelect ? 6 : 12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Typography>
                    <b>{t('box.referent')} :</b> {snapShot?.nom}{' '}
                    {snapShot?.prenom}
                  </Typography>
                </Grid>
                {role !== 'ROLE_ENTREPRISE' && posteSelect && (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('filter.poste')} :</b> {posteSelect}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Typography>
                    <b>{t('profile.date')} :</b>{' '}
                    {moment(event.dateDebut)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Typography>
                    <b>{t('profile.hour')} :</b>{' '}
                    {moment(event.dateDebut).tz('Europe/Paris').format('H:mm')}{' '}
                    - {moment(event.dateFin).tz('Europe/Paris').format('H:mm')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <Typography>
                    <b>{t('box.moreInformations')} :</b>{' '}
                    {snapShot?.candidat?.besoin} {snapShot?.entreprise?.besoin}
                  </Typography>
                </Grid>
                {event.statusAdmin && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('box.appointmentStatus')} :</b>{' '}
                      {event.statusAdmin.toUpperCase()}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </BoxContainer>
          {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
          {showParams && (
            <Box className={'blockButtons'}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    displayIcon={'none'}
                    handleClick={(e) => {
                      statusEvent({ statusAdmin: 'refuser' })
                    }}
                  >
                    {t('button.refuse')}
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={(e) => {
                      statusEvent({ statusAdmin: 'accepter' })
                    }}
                  >
                    {t('button.accept')}
                  </CustomButton>
                </>
              )}
            </Box>
          )}

          {/*</Box>*/}
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default EditEvent
