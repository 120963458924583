import React, {useCallback} from "react";
import { AppBar, Box, Container, styled, Toolbar, Typography } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { WhiteButton } from "../buttons";
import { useLocation, useNavigate } from "react-router-dom";

const TitleBar = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 20,
  lineHeight: "120%",
  color: lightTheme.palette.text.secondary,
  display: "none",
  textTransform: "uppercase",

  [theme.breakpoints.up("lg")]: {
    display: "block",
    fontSize: 20
  },
}));

const ListContainer = styled('ul')(({theme}) => ({
  display: "none",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "20px",
  padding: 0,

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ListItem = styled('li')(({theme}) => ({
  listStyle: "none",
  cursor: "pointer",

  a: {
    fontFamily: "Century Gothic",
    fontSize: 12,
    lineHeight: "140%",
    color: lightTheme.palette.text.secondary,
  },

  [theme.breakpoints.up("lg")]: {
    a: {
      fontSize: 14
    }
  },
}));



export default function StickyNavbar(props, display) {
  const location = useLocation();
  const navigate = useNavigate();

  //console.log("pathname:",location.pathname);

  const handleClick = useCallback(
    (i) => {

      const anchor = document.querySelector(`#sec${i + 1}`);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    },[]);

  const handleLink = event => {

    if(location.pathname === "/candidats") {
      const anchor = document.querySelector(`#sec3`);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    } else if(location.pathname === "/entreprises") {
      navigate("/contact");
    }
  }


  const Header = ({items, display}) => {
    return (
      <>
        {items.list ? (
          <AppBar
            position={"sticky"}
            elevation={0}
            sx={{
              height: {xs: 70, md: 80},
              backgroundColor: lightTheme.palette.primary.b3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              top: 0,
              borderRadius: "0 0 30px 30px",
              boxShadow: lightTheme.palette.shadow.headerShadow,
            }}
          >
            <Container maxWidth={"lg"}>
              <Toolbar disableGutters>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: {xs: "center", md: "space-between" },
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  <TitleBar>{items.title}</TitleBar>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {xs: "center", md: "space-between", lg: "center" },
                      alignItems: "center",
                      columnGap: "20px",
                      width: {xs: "100%", lg: "auto"}
                    }}
                  >
                    <List lists={items.list}/>
                    <Box display={display}>
                      <WhiteButton handleClick={handleLink}>{items.button}</WhiteButton>
                    </Box>
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        ) : (
          <></>
        )}
      </>
    )
  }

  const List = ({lists}) => {
    return (
      <ListContainer>
        {lists.map((list, index) => (
          <ListItem key={index} onClick={() => handleClick(index)}>
            {list.text}
          </ListItem>
        ))}
      </ListContainer>
    )
  }


    return (
        <>
          {props.items && props.items.map((item, index) => (
            <Header items={item} key={index} display={display}/>
          ))}
        </>
    )
}