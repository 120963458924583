import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { FRONT_URL, STRIPE_URL } from '../../config'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

export default function CheckoutFormTest({
  paymentIntentId,
  userId,
  offreId,
  offrePrice,
  onSuccess,
  onClose,
}) {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  let user_id = userId //id utilisateur
  let offre_id = offreId //id offre
  let price = offrePrice // prix total a paye

  useEffect(() => {
    if (!stripe) {
      return
    }
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Url de la page de fin de paiement
        return_url: FRONT_URL,
      },
      redirect: 'if_required',
    })

    fetch(`${STRIPE_URL}` + 'payment_insert', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        request_type: 'payment_insert',
        payment_intent_id: paymentIntentId,
        id_offre: offre_id,
        id_client: user_id,
        prix: price,
        devise: (sessionStorage.getItem("currency")) ? JSON.parse(sessionStorage.getItem("currency")).devise : 'CHF'
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        data = JSON.parse(data)
        if (data.payment_id && data.payment_status === "succeeded") {
          setMessage(t('successMessage.paymentOk'))
          setIsLoading(false)
          setTimeout(() => {
            onSuccess()
          }, 1000);
        } else {
          setMessage(t('successMessage.paymentNok'))
          setIsLoading(false)
          setTimeout(() => {
            onClose()
          }, 1000);
        }

      })
      .catch(console.error)

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    /* if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('une erreur inattendue est apparue.')
    } */

    //setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element"/>
      {isLoading ? (
        <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          <CircularProgress />
        </Box>
      ) : (
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
          {t('button.pay')}
          </span>
        </button>
      )}
      {/* Afficher les messages d'erreur ou de réussite */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
