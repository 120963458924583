import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";


const CustomLabel = styled('label')(({theme}) => ({
  display: "block",
  position: "relative",
  paddingLeft: "35px",
  marginBottom: "12px",
  cursor: "pointer",
  fontSize: 10,
  fontFamily: "Century Gothic Bold",
  userSelect: "none",

  "& input": {
    position: "absolute",
    opacity: 0,
    cursor: "pointer",
  },

  "& .checkmark": {
    position: "absolute",
    top: 0,
    left: 0,
    height: 20,
    width: 20,
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    border: "1px solid #9E9E9E",

    "&::after": {
      content: "''",
      position: "absolute",
      display: "none",
    }
  },

  "&:hover input ~ .checkmark": {
    backgroundColor: "#FFFFFF",
  },

  "& input:checked ~ .checkmark": {
    backgroundColor: "#FFFFFF",
  },

  "& input:checked ~ .checkmark:after": {
    display: "block"
  },

  "& .checkmark:after": {
    top: "50%",
    left: "50%",
    width: 13,
    height: 13,
    borderRadius: "50%",
    background: lightTheme.palette.primary.main,
    transform: "translate(-50%, -50%)"
  },

  [theme.breakpoints.up("md")]: {
    fontSize: 12,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 14,
  },
}));

const RadioInputBasic = ({value, register, isChecked, label, handleChange}) => {
  return (
    <CustomLabel className="container">{label}
      <input type="radio" checked={isChecked} name="radio" value={value} {...register} onClick={() => handleChange(value)} />
      <span className="checkmark"/>
    </CustomLabel>
  )
}

export default RadioInputBasic;
