import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  styled, TextField,
  Typography
} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckboxInput, DatePickerInput } from "../inputs";
import { BorderButton, CustomButton } from "../buttons";

const ModalContainer = styled(Box)(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  height: 450,
  backgroundColor: "#FFFFFF",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  rowGap: "25px",

  [theme.breakpoints.up("sm")]: {
    width: 730,
    height: 510,
    rowGap: "35px",
  },
  [theme.breakpoints.up("lg")]: {
    width: 720,
    height: 510,
  },
}));

const Label = styled(FormLabel)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary
}));

const FiltersModal = ({open, onClose}) => {

  /*const [value, setValue] = useState<Dayjs | null>(dayjs());*/

  const [value, setValue] = useState(null);


  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
      >
        <ModalContainer>
          <Typography
            variant={"subtitle2"}
            sx={{
              textTransform: "uppercase",
              textAlign: "center"
            }}
          >
            Filtre(s)
          </Typography>
          <Box
            sx={{
              width: 660,
              height: 315,
              backgroundColor: lightTheme.palette.primary.b6,
              padding: {xs: "30px 20px", md: "35px", lg: "25px" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",

              }}
            >
              <FormControl component={"fieldset"}>
                <Label component={"legend"}>Langue</Label>
                  <FormGroup
                    aria-label={"position"}
                    row
                    sx={{
                      columnGap: "45px",
                      paddingLeft: "20px",
                    }}
                  >
                    <FormControlLabel
                      control={<CheckboxInput/>}
                      label={"Français"}
                      labelPlacement={"end"}
                    />
                    <FormControlLabel
                      control={<CheckboxInput/>}
                      label={"Anglais"}
                      labelPlacement={"end"}
                    />
                  </FormGroup>
              </FormControl>

              <FormControl component={"fieldset"}>
                <Label component={"legend"}>Date d'inscription</Label>
                <FormGroup
                  aria-label={"position"}
                  row
                >

                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                    <DatePicker
                      label="Custom input"
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <input ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>

                  {/*<LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={(newValue) => {setValue(newValue)}}
                      value={value}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>*/}
                </FormGroup>
              </FormControl>
            </Box>

            <DatePickerInput/>

          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "15px"
            }}
          >
            <BorderButton
              type={"basic"}
              displayIconLeft={"none"}
              displayIconRight={"none"}
              width={{xs: 140, sm: 200}}
            >
              Réinitialiser les filtres
            </BorderButton>
            <CustomButton
              displayIcon={"none"}
              width={{xs: 140, md: 200}}
            >
              Appliquer les filtres
            </CustomButton>
          </Box>

        </ModalContainer>
      </Modal>
    </div>
  )
}

export default FiltersModal;