import ApiRequest from "../request/ApiRequest";

export default class adresse {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("adresses", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`adresses/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`adresses/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get("adresses");
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`adresses/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async matchingCandidatesByOffre(id) {
    try {
      const res = await this.ApiRequest.get(`matcher/candidat/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("adresses.jsonld", data, "jsonld");
      return res;
    } catch (error) {
      return error.response;
    }
  }
}
