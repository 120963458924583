import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import '../../styles/components/_errors.scss'
import { SelectInputBasic, SelectInputObject } from '../inputs'
import { useTranslation } from 'react-i18next'
import { TYPE_ACTIONS } from '../../data'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import Api from '../../services/api.service'
import { useSelector } from 'react-redux'
import { handlingErrors } from '../../utils'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    height: 500,
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    height: 300,
  },
}))

const parSchema = yup.object({
  action: yup.string().required("Merci de selectionner l'action commercial."),
})

const ChoiceOfferModal = ({ open, onClose, candidat, onSave }) => {
  const currentUser = useSelector((state) => state.userAuth.user)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(parSchema),
  })
  const { t, i18n } = useTranslation()
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)

  const onSubmit = async (data) => {
    setLoadingButton(true)

    data.candidat = '/api/utilisateurs/' + candidat.id
    data.commercial = '/api/utilisateurs/' + currentUser.data.id
    /* console.log(data)
    return */

    try {
      const result = await Api.action.add(data)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        onClose()
        //setSnapShot(res);
        /* setOpenOffre(false) */
        setLoadingButton(false)
        /* fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }
  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setOffreSelect(inputValue)
  }
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.offer')}
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.selectOffer')}
                </FormLabel>
                {/*  <SelectInputObject
                  items={items}
                  ref={register}
                  name={"func"}
                  handleChange={handleChange}
                  value={value}
                /> */}
                <SelectInputBasic
                  items={TYPE_ACTIONS[i18n.language]}
                  /* value={offreSelect} */
                  /* handleChange={handleChangeSelect} */
                  type={'border'}
                  register={{ ...register('action') }}
                />
                {errors.action && (
                  <Typography className={'error-message'}>
                    {errors.action.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>Commentaire</FormLabel>
                <TextareaInputClassic
                  placeholder={'Commentaire'}
                  width={'100%'}
                  register={{ ...register('commentaire') }}
                />
              </Grid>
            </Grid>
          </form>
        </BoxContainer>
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loadingButton ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.addOffer')}
            </CustomButton>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ChoiceOfferModal
