import React, { useState } from 'react'
import { Box, FormLabel, Grid, Modal, styled, Typography } from '@mui/material'
import { BorderButtonPurple } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import moment from 'moment-timezone'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { truncate } from '../../config'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const DetailBesoinEntreprise = ({ open, onClose, besoin }) => {
  const [seeMore, setSeeMore] = useState(false)
  const handleSeeMore = (event) => {
    setSeeMore(!seeMore)
  }
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Détail de l'adresse
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                Nom du site : <b>{besoin?.nomSite}</b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                Adresse :{' '}
                <b>
                  {besoin.adresse}, {besoin.ville}, {besoin.codePostal} {besoin?.pays}
                </b>
              </FormLabel>
            </Grid>
            
          </Grid>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            Fermer
          </BorderButtonPurple>
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default DetailBesoinEntreprise
