import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Auth/Login'
import { AuthLayout } from '../components/layouts'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import Register from '../pages/Auth/Register'

const AuthRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route index element={<Login />} />
        <Route path={'se-connecter'} element={<Login />} />
        <Route path={'mot-de-passe-oublie'} element={<ForgotPassword />} />
        <Route
          path={'reinitialisation-mot-de-passe/:token'}
          element={<ResetPassword />}
        />
        {/* <Route path={'inscription'} element={<Register />} /> */}
      </Route>
    </Routes>
  )
}

export default AuthRouter
