import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import { Box, Grid, styled } from '@mui/material'
import '../../../../styles/components/_box.scss'
import {
  SearchInputBasic,
  SelectInputObject,
} from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import {
  DeleteGeneralModal,
  EditBesoinEntreprise,
} from '../../../../components/modals'
import BesoinTable from '../../../../components/tables/BesoinTable'
import DetailBesoinEntreprise from '../../../../components/modals/DetailBesoinEntreprise'
import { debounce } from '../../../../config'
import { userLogout } from '../../../../store/slices/userAuthReducer'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const Besoins = () => {
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()
  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState([])
  const [loadingBesoin, setLoadingBesoin] = useState(true)
  const [besoinId, setBesoinId] = useState()

  const [filter, setFilter] = useState([
    { id: 0, titre: 'Entreprise', filtre: 'entreprise.raisonSocial' },
    { id: 1, titre: 'Poste', filtre: 'poste.titre' },
    { id: 2, titre: 'Lieu', filtre: 'search' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  useEffect(() => {
    getBesoinsCompanys(1)
  }, [search])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    //fetchData()
  }
  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }
  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompanys(page)
  }

  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }

  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinId(idBesoin)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompanys(page)
  }

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const getBesoinsCompanys = async (page) => {
    setLoadingBesoin(true)

    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    if (filterSelect.id != 2) {
      payload = {
        [filterSelect.filtre]: search,
      }
    }

    try {
      const result = await Api.besoinEntreprise.listWithFilter({
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingBesoin(false)
  }

  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoins.findIndex((bs) => bs.id === besoinId)

        if (indexBesoin > -1) {
          besoins.splice(indexBesoin, 1)
        }
        setBesoins(besoins)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
    setBesoinId('')
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Listes des fiches de poste
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <SearchInputBasic
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
          </WrapperButton>

          <BesoinTable
            besoins={besoins}
            loading={loadingBesoin}
            handleRemove={handleRemoveBesoin}
            handleEdit={handleEditBesoin}
            handleDetailBesoin={handleDetailBesoin}
            handlePageChange={handlePageChange}
            count={count}
            page={page}
            role={'ROLE_ADMIN'}
            showDetail={true}
          />
        </Box>
      </div>

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={besoin.entreprise.id}
          besoin={besoin}
        />
      )}

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
        />
      )}

      {openDeleteBesoinModal && (
        <DeleteGeneralModal
          open={openDeleteBesoinModal}
          onClose={handleCloseDeleteBesoinModal}
          onConfirm={handleConfirmDeleteBesoinModal}
          loading={loadingDelete}
        />
      )}
    </>
  )
}

export default Besoins
