import React from "react";
import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CardContainer = styled(Box)(({theme}) => ({
  width: "80%",
  height: "initial",
  margin: "0 10%",
  backgroundColor: lightTheme.palette.primary.b6,
  borderRadius: "25px",
  padding: "25px",
  position: "absolute",

  [theme.breakpoints.up("sx")]: {
    width: "80%",
    height: "initial",
    padding: "40px",
    margin: "0 10%"
  },

  [theme.breakpoints.up("sm")]: {
    width: "80%",
    height: "initial",
    padding: "40px",
    margin: "0 10%"
  },

  [theme.breakpoints.up("lg")]: {
    width: 470,
    height: 500,
    padding: "25px",
    margin: 0
  },
}));

const RegisterCard = ({children}) => {
  return (
    <CardContainer>
      {children}
    </CardContainer>
  )
}

export default RegisterCard;