import React, { useEffect, useState } from "react";
import { Box, Container, Skeleton, styled, Typography } from "@mui/material";
import {SectionsHeading} from "../../../../../components/headings";
import {ImageBorderRadius} from "../../../../../components/images";
import img from "../../../../../assets/images/portrait2.jpg";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { useTranslation } from "react-i18next";
import { contentsService } from "../../../../../services/contents.service";
import { contentsOptions } from "../../../../../services/request";
import { Markup } from "interweave";
import "../../../../../styles/components/_skeleton.scss";

const SectionBox = styled(Box)(({theme}) => ({
    paddingTop: "62px",
    paddingBottom: "125px",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
        paddingTop: "67px",
        paddingBottom: "60px",
    },

    [theme.breakpoints.up("lg")]: {
        paddingTop: "80px",
        paddingBottom: "80px"
    },
}));

const CustomBoxMobile = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
        display: "none"
    },
}));

const TextMobile = styled(Typography)(({theme}) => ({
    color: lightTheme.palette.text.primary,
    width: "45%",
}));

const ContentBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "25px",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
        width: "60%"
    },

}));

const TextBox = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
}));

const Text = styled(Typography)(({theme}) => ({
    color: lightTheme.palette.text.primary,
}));

export default function TransitionSection () {
    const { t, i18n } = useTranslation();
    const [transitionSections, setTransitionSections] = useState([]);

    useEffect(() => {
        contentsService.getAllContents(contentsOptions)
          .then(res => {
              const response = res.data;
              const dataTransition = (response.filter(data => data.section === "transition"));
              setTransitionSections(dataTransition);
          })
          .catch(err => console.log(err))
    })

    return (
      <SectionBox component={"section"} id={"sec2"}>
          {i18n.language === "fr" ? (
            <>
                {transitionSections
                  .filter(transitionSection => transitionSection.lang === "fr")
                  .map((transitionSection, index) => (
                    <Container
                      maxWidth={"lg"}
                      sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "50px"
                      }}
                      key={index}
                    >
                        {!transitionSection.titre ? (
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                        ) : (
                          <SectionsHeading
                            justifyContent={{xs: "center", sm: "flex-start"}}
                            width={{xs: 280, sm: 465, lg: "100%"}}
                            lineHeight={"150%"}
                            top={{xs: "1px", sm: "2px", lg: "9px"}}
                            textAlign={{xs: "center", sm: "left"}}
                            display={{xs: "flex", lg: "none"}}
                            type={"animation"}
                          >
                              {transitionSection.titre}
                          </SectionsHeading>
                          )}

                        {!transitionSection.photos[0] ? (
                          <CustomBoxMobile>
                              <Skeleton variant={"rounded"} animation={"wave"} className={"image-border-radius"} />
                          </CustomBoxMobile>
                        ) : (
                          <CustomBoxMobile>
                              <ImageBorderRadius image={transitionSection.photos[0].lien}/>
                          </CustomBoxMobile>
                          )}

                        <TextBox>
                            <ContentBox>
                                {!transitionSection.titre ? (
                                  <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} sx={{display: {xs: "none", lg: "flex"}}} />
                                ) : (
                                  <SectionsHeading
                                    type={"animation"}
                                    justifyContent={{xs: "center", sm: "flex-start"}}
                                    width={{xs: 280, sm: 465, lg: "100%"}}
                                    lineHeight={"150%"}
                                    top={{xs: "1px", sm: "2px", lg: "9px"}}
                                    textAlign={{xs: "center", sm: "left"}}
                                    display={{xs: "none", lg: "flex"}}
                                  >
                                      {transitionSection.titre}
                                  </SectionsHeading>
                                  )}

                                {!transitionSection.detail ? (
                                  <Box width={"100%"} display={"block"}>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                                      <br/>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                                      <br/>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                                  </Box>
                                ) : (
                                  <Text variant={"body2"}>
                                      <Markup content={transitionSection.detail} />
                                  </Text>
                                  )}
                            </ContentBox>

                            {!transitionSection.photos[0] ? (
                                <Box display={{xs: "none", sm: "flex"}}>
                                    <Skeleton variant={"rounded"} animation={"wave"} className={"image-border-radius"} />
                                </Box>
                            ) : (
                              <Box display={{xs: "none", sm: "flex"}}>
                                  <ImageBorderRadius image={transitionSection.photos[0].lien}/>
                              </Box>
                              )}
                        </TextBox>
                    </Container>
                  ))}
            </>
          ):(
            <>
                {transitionSections
                  .filter(transitionSection => transitionSection.lang === "en")
                  .map((transitionSection, index) => (
                    <Container
                      maxWidth={"lg"}
                      sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "50px"
                      }}
                      key={index}
                    >
                        {!transitionSection.titre ? (
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                        ) : (
                          <SectionsHeading
                            justifyContent={{xs: "center", sm: "flex-start"}}
                            width={{xs: 280, sm: 465, lg: "100%"}}
                            lineHeight={"150%"}
                            top={{xs: "1px", sm: "2px", lg: "9px"}}
                            textAlign={{xs: "center", sm: "left"}}
                            display={{xs: "flex", lg: "none"}}
                            type={"animation"}
                          >
                              {transitionSection.titre}
                          </SectionsHeading>
                          )}

                        <CustomBoxMobile>
                            {!transitionSection.photos[0] ? (
                              <Skeleton variant={"rounded"} animation={"wave"} className={"image-border-radius"} />
                            ) : (
                              <ImageBorderRadius image={transitionSection.photos[0].lien}/>
                              )}
                        </CustomBoxMobile>
                        <TextBox>
                            <ContentBox>
                                {!transitionSection.titre ? (
                                  <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                                ) : (
                                  <SectionsHeading
                                    type={"animation"}
                                    justifyContent={{xs: "center", sm: "flex-start"}}
                                    width={{xs: 280, sm: 465, lg: "100%"}}
                                    lineHeight={"150%"}
                                    top={{xs: "1px", sm: "2px", lg: "9px"}}
                                    textAlign={{xs: "center", sm: "left"}}
                                    display={{xs: "none", lg: "flex"}}
                                  >
                                      {transitionSection.titre}
                                  </SectionsHeading>
                                  )}

                                {!transitionSection.detail ? (
                                  <Box width={"100%"}>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                                      <br/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                                      <br/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                                      <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                                  </Box>
                                ) : (
                                  <Text variant={"body2"}>
                                      <Markup content={transitionSection.detail} />
                                  </Text>
                                  )}

                            </ContentBox>

                            {!transitionSection.photos[0] ? (
                              <Box display={{xs: "none", sm: "flex"}}>
                                  <Skeleton variant={"rounded"} animation={"wave"} className={"image-border-radius"} />
                              </Box>
                            ) : (
                              <Box display={{xs: "none", sm: "flex"}}>
                                  <ImageBorderRadius image={transitionSection.photos[0].lien}/>
                              </Box>
                              )}
                        </TextBox>
                    </Container>
                  ))}
            </>
            )}



      </SectionBox>
    )
}