//export const BASE_URL = 'https://127.0.0.1:8002/'
//export const BASE_URL = 'https://api.fibme.fr/'
export const BASE_URL = "https://admin-cvtheque.hcub3-consulting.fr/";

export const BASE_URL_UP = BASE_URL + 'api/'
export const STRIPE_URL = BASE_URL
export const FILE_URL = BASE_URL + 'file/'
export const FRONT_URL = 'https://cvtheque.hcub3-consulting.fr/'
export const STRIPE_KEY = "pk_test_51Lxs90FckL3ExSvQylfgGe5lehUlFm1383cSGbH1LO3asplC4G9LYraES4v7vAIMXJRt7gpS8GAhnYTDpbJTsvuv00HhhNYDRN"

export const JITSI_APP_ID = "vpaas-magic-cookie-06ee6bdc55d149d9823f430b5cb83831"
/*
export const phoneRegExp = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
*/

export const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

/*export const phoneRegExp = new RegExp(/"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"/gmi);*/
export const passwordRegExp =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,100}$/

export function unserialize(serializedString) {
  if (serializedString === 'a:0:{}' || serializedString === '') return ''
  // Extract text between double quotes using regular expression
  const extractedValues = serializedString
    .match(/"(.*?)"/g)
    .map((value) => value.replace(/"/g, ''))

  return extractedValues
}

export function truncate(str) {
  return str.length > 150
    ? str.substring(0, 150) + '...'
    : str
}

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}



