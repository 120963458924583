import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Typography, styled } from "@mui/material";
import { SectionsHeading } from "../../../../../components/headings";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";
import "../../../../../styles/components/_slick.scss";
import { useTranslation } from "react-i18next";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import { Markup } from "interweave";

const BoxItem = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "15px",
}));

const TitleComment = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  textTransform: "uppercase",

  [theme.breakpoints.up("sm")]: {
    fontSize: 14
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 18
  },
}));

const FonctionComment = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.grey.grey,
  fontStyle: "italic",

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 14
  },

}))

const SeparatePoint = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "15px",

  span: {
    width: 10,
    height: 10,
    borderRadius: "100px",
    backgroundColor: lightTheme.palette.error.main,
    animation: `${colorEffect} 15s linear infinite`
  },


}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const CommentsHome = () => {
  let settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "350px",
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "200px",
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "100px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "50px",
        }
      }
    ]
  };

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [temoignagesFr, setTemoignagesFr] = useState([]);
  const [temoignagesEn, setTemoignagesEn] = useState([]);

  useEffect(() => {
    getTemoignageFr();
    getTemoignageEn();
  }, []);

  const getTemoignageFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listTemoignagesWithFilter({
        lang: "fr",
        status: "active"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        setTemoignagesFr(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const getTemoignageEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listTemoignagesWithFilter({
        lang: "en",
        status: "active"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        setTemoignagesEn(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      style={{
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        rowGap: "75px",
        padding: "100px 0",
      }}
    >
        <SectionsHeading
          type={"animation"}
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          {t(`home.testimonialsTitle`)}
        </SectionsHeading>
        <Slider {...settings}>
          {temoignagesFr.map((item, index) => (
            <div key={index}>
              <BoxItem className={"h3"}>
                <Box>
                  <TitleComment>{item.titre}</TitleComment>
                  <FonctionComment>{item.fonction}</FonctionComment>
                </Box>
                <SeparatePoint>
                  <span/>
                  <span/>
                  <span/>
                </SeparatePoint>
                <Typography variant={"body2"} textAlign={"center"}>
                  <Markup content={item.texte} />
                </Typography>
              </BoxItem>
            </div>
          ))}
        </Slider>
    </div>
  )
}

export default CommentsHome;