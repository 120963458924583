import React from 'react'
import { Box, Container, Grid, Paper, styled, Typography } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faFilePlus,
  faCalendarPlus,
} from '@fortawesome/pro-regular-svg-icons'
import lock from '../../assets/images/lock.png'
import '../../styles/components/_box.scss'
import { CustomButton } from '../buttons'
import { useTranslation } from 'react-i18next'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: 435,
  borderRadius: 0,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
}))

const ItemLong = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '15px',
}))

const BannerCircle = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.secondary.main,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 10,
    height: 10,
    backgroundColor: lightTheme.palette.secondary.main,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const WrapperIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
}))

const BannerSquare = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.warning.dark,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 10,
    height: 10,
    backgroundColor: '#FFFFFF',
    /*border: "1px solid #FFFFFF",
    borderRadius: "50%"*/
  },
}))

const UserMasonryLayout = ({
  user,
  profil,
  besoinClient,
  resumeClient,
  rendezVous,
  offre,
  ged,
  relation,
  valeurs,
  competences,
  softskils,
  type,
  displayValeurSection,
  displayCompetencesSection,
  displaySoftskilSection,
  handleOpenDoc,
  handleOpenBesoin,
  handleOpenResume,
  handleOpenCompetences,
  handleOpenValeurs,
  handleOpenSoftskils,
  handleOpenOffer,
  handleOpenAddEvent,
}) => {
  const { t } = useTranslation()
  return (
    <Container
      maxWidth={'lg'}
      sx={{ paddingTop: '25px', paddingBottom: '25px' }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={3.5} order={{ sm: 1, lg: 1 }}>
          {type === 'entreprise' ? (
            <BannerCircle
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>
                {t('profile.entreprise.title')}
              </TitleBox>
            </BannerCircle>
          ) : (
            <BannerSquare
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>
                {t('profile.candidat.title')}
              </TitleBox>
            </BannerSquare>
          )}

          <Item
            sx={{
              height: { sm: '100%', lg: 'calc(100% - 50px)' },
            }}
          >
            {profil}
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={5}
          order={{ sm: 3, lg: 2 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={offre ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>{t('profile.purchaseHistory')}</TitleBox>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {offre}
            </Box>

            <CustomButton displayIcon={'none'} handleClick={handleOpenOffer}>
              {t('profile.addOffer')}
            </CustomButton>
          </Item>
          {/* {!offre && (
            <img
              src={lock}
              alt={'Hcub3 Cvtheque - not access'}
              style={{
                width: 150,
                height: 150,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-40%, -45%)',
              }}
            />
          )} */}
        </Grid>
        <Grid item xs={12} sm={6} lg={3.5} order={{ sm: 2, lg: 4 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>{t('profile.candidateSearch')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenBesoin}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{besoinClient}</Box>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3.5}
          order={{ sm: 6, lg: 3 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
            }}
            className={''}
          >
            <TitleContainer>
              <TitleBox>{t('profile.rdv')}</TitleBox>
              <IconContainer onClick={handleOpenAddEvent}>
                <FontAwesomeIcon
                  icon={faCalendarPlus}
                  fontSize={15}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>

            <Box>{rendezVous}</Box>
          </Item>
          {/*  <img
            src={lock}
            alt={"Hcub3 Cvtheque - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          /> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={5}
          order={{ sm: 5, lg: 5 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: { sm: 400, lg: 435 },
            }}
            className={ged ? '' : 'blurLayout'}
          >
            <TitleContainer>
              <TitleBox>{t('profile.ged')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenDoc}>
                  <FontAwesomeIcon
                    icon={faFilePlus}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {ged}
            </Box>
          </Item>
          {!ged && (
            <img
              src={lock}
              alt={'Hcub3 Cvtheque - not access'}
              style={{
                width: 150,
                height: 150,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-40%, -45%)',
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3.5}
          order={{ sm: 4, lg: 6 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: { sm: 400, lg: 435 },
            }}
            className={''}
          >
            <TitleBox>{t('profile.linking')}</TitleBox>

            <Box>{relation}</Box>
          </Item>
          {/* <img
            src={lock}
            alt={"Hcub3 Cvtheque - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} order={{ sm: 7 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>{t('profile.candidateSummary')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenResume}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{resumeClient}</Box>
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 7 }}
          display={displayCompetencesSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>{t('filter.competences')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCompetences}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {competences &&
                  competences.map((competence, i) => (
                    <li key={i}>{competence}</li>
                  ))}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 8 }}
          display={displaySoftskilSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>{t('filter.softskils')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenSoftskils}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {softskils &&
                  softskils.map((softskil, i) => <li key={i}>{softskil}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 9 }}
          display={displayValeurSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>{t('filter.values')}</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenValeurs}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {valeurs &&
                  valeurs.map((valeur, i) => <li key={i}>{valeur}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserMasonryLayout
