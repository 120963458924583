import React from "react";
import { Box, styled } from "@mui/material";

const ImageBox = styled(Box)(({theme}) => ({
    width: 165,
    height: 185,
    borderRadius: "50px",
    filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05))",
    overflow: "hidden",
    position: "relative",

    img: {
        position: "absolute",
        objectFit: "cover",
        width: "100%",
        height: "100%"
    },

    ".skeleton-rounded": {
        width: 165,
        height: 185,
        borderRadius: "50px",
    },

    [theme.breakpoints.up("sm")]: {
        width: 222,
        height: 250,

        ".skeleton-rounded": {
            width: 222,
            height: 250,
        },
    },

    [theme.breakpoints.up("lg")]: {
        width: 350,
        height: 400,

        ".skeleton-rounded": {
            width: 350,
            height: 400,
        },
    },
}));

export default function ImageBorderRadius ({image}) {
    return (
      <ImageBox>
          <img src={image} alt={"Hcub3 Cvtheque"}/>
      </ImageBox>

    )
}