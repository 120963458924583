import { Box, Container, Grid, Skeleton, styled, Typography } from "@mui/material";
import { SectionsHeading } from "../../../../../components/headings";
import image from "../../../../../assets/images/article-2.jpg";
import image2 from "../../../../../assets/images/article-3.jpg";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import { Markup } from "interweave";

const GridItem = styled(Grid)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "20px"

}))

const ImageContainer = styled(Box)(({theme}) => ({
  height: 100,
  borderRadius: "25px",
  position: "relative",
  overflow: "hidden",

  "& .image": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  [theme.breakpoints.up("sm")]: {
    height: 135
  },

  [theme.breakpoints.up("lg")]: {

  },
}));

const BadgeButton = styled(Box)(({theme}) => ({
  width: 95,
  height: 25,
  borderRadius: "25px",
  backgroundColor: lightTheme.palette.error.main,
  animation: `${colorEffect} 15s linear infinite`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF",
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  textTransform: "uppercase",
  lineHeight: "140%",
  letterSpacing: "0.2px",

  [theme.breakpoints.up("sm")]: {
    width: 130,
    fontSize: 12,
  },

  [theme.breakpoints.up("lg")]: {
    width: 170,
    height: 35,
    fontSize: 14,
  },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const SpecialityHome = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [contentCandidatFr, setContentCandidatFr] = useState([]);
  const [contentCandidatEn, setContentCandidatEn] = useState([]);
  const [contentEntrepriseFr, setContentEntrepriseFr] = useState([]);
  const [contentEntrepriseEn, setContentEntrepriseEn] = useState([]);

  useEffect(() => {
    getContentCandidatFr();
    getContentEntrepriseFr();
  }, []);

  useEffect(() => {
    getContentCandidatEn();
    getContentEntrepriseEn();
  }, []);

  const getContentCandidatFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "fr",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "nos-specialites-candidats");
        setContentCandidatFr(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentEntrepriseFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "fr",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "nos-specialites-entreprises");
        setContentEntrepriseFr(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentCandidatEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "en",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "nos-specialites-candidats");
        setContentCandidatEn(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const getContentEntrepriseEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "en",
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "nos-specialites-entreprises");
        setContentEntrepriseEn(datas);
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <Box
      component={"section"}
      width={"100%"}
      sx={{
        padding: {xs: "90px 0", sm: "110px 0", lg: "160px 0 80px 0"}
      }}
    >
      <Container
        maxWidth={"md"}
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "80px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: {xs: "40px", sm: "5px", lg: 0 }
          }}
        >
          <SectionsHeading
            type={"animation"}
            display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
            leftCircle={{xs: "47px", sm: 0 }}
            /*display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
            top={{xs: "4px", lg: "12px" }}*/
          >
            {t('home.specialist')}
          </SectionsHeading>
          <Typography
            textAlign={"center"}
            fontFamily={"Century Gothic Bold"}
            fontSize={{xs: 16, sm: 18, lg: 24}}
            textTransform={"uppercase"}
          >
            {t('home.makeSens')}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {i18n.language === "fr" ? (
            <>
              {contentCandidatFr.map((item, index) => (
                <GridItem item xs={12} lg={6} key={index}>
                  {!item.photos[0] ? (
                    <Skeleton variant={"rounded"} animation={"wave"} width={"100%"} height={{xs: 100, sm: 135}}/>
                  ) : (
                    <ImageContainer>
                      <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`} className={"image"}/>
                    </ImageContainer>
                  )}

                  {!item.titre ? (
                    <Skeleton variant={"text"} animation={"wave"} height={14}/>
                  ) : (
                    <BadgeButton>{item.titre}</BadgeButton>
                    )}

                  {!item.detail ? (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  ) : (
                    <Typography>
                      <Markup content={item.detail} />
                    </Typography>
                    )}
                </GridItem>
              ))}

              {contentEntrepriseFr.map((item, index) => (
                <GridItem item xs={12} lg={6} key={index}>
                  {!item.photos[0] ? (
                    <Skeleton variant={"rounded"} width={"100%"} height={{xs: 100, sm: 135}}/>
                  ) : (
                    <ImageContainer>
                      <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`} className={"image"}/>
                    </ImageContainer>
                  )}

                  {!item.titre ? (
                    <Skeleton variant={"text"} animation={"wave"} height={14}/>
                  ) : (
                    <BadgeButton>{item.titre}</BadgeButton>
                  )}

                  {!item.detail ? (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  ) : (
                    <Typography>
                      <Markup content={item.detail} />
                    </Typography>
                  )}
                </GridItem>
              ))}
            </>
          ) : (
            <>
              {contentCandidatEn.map((item, index) => (
                <GridItem item xs={12} lg={6} key={index}>
                  {!item.photos[0] ? (
                    <Skeleton variant={"rounded"} width={"100%"} height={{xs: 100, sm: 135}}/>
                  ) : (
                    <ImageContainer>
                      <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`} className={"image"}/>
                    </ImageContainer>
                  )}

                  {!item.titre ? (
                    <Skeleton variant={"text"} animation={"wave"} height={14}/>
                  ) : (
                    <BadgeButton>{item.titre}</BadgeButton>
                  )}

                  {!item.detail ? (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  ) : (
                    <Typography>
                      <Markup content={item.detail} />
                    </Typography>
                  )}
                </GridItem>
              ))}

              {contentEntrepriseEn.map((item, index) => (
                <GridItem item xs={12} lg={6} key={index}>
                  {!item.photos[0] ? (
                    <Skeleton variant={"rounded"} width={"100%"} height={{xs: 100, sm: 135}}/>
                  ) : (
                    <ImageContainer>
                      <img src={item.photos[0].lien} alt={`Hcub3 Cvtheque - ${item.photos[0].titre}`} className={"image"}/>
                    </ImageContainer>
                  )}

                  {!item.titre ? (
                    <Skeleton variant={"text"} animation={"wave"} height={14}/>
                  ) : (
                    <BadgeButton>{item.titre}</BadgeButton>
                  )}

                  {!item.detail ? (
                    <Box width={"100%"} display={"block"}>
                      <Skeleton variant={"text"} animation={"wave"} height={14}/>
                      <Skeleton variant={"text"} animation={"wave"} width={"50%"} height={14}/>
                    </Box>
                  ) : (
                    <Typography>
                      <Markup content={item.detail} />
                    </Typography>
                  )}
                </GridItem>
              ))}
            </>
            )}
          <Grid item xs={0} sm={12}>
            <Typography
              sx={{
                textAlign: "center",
                textTransform: "uppercase",
                fontFamily: "Century Gothic Bold",
                fontSize: {sm: 12, lg: 14},
                color: lightTheme.palette.text.primary,
                lineHeight: "140%",
                letterSpacing: "0.2px",
                paddingTop: "50px"
              }}
            >
              {t("home.jobertechAccompagne")}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default SpecialityHome;