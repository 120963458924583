import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const BannerLeft = styled(Box)(({theme}) => ({
    height: 380,
    width: "100%",
    backgroundColor: lightTheme.palette.primary.b6,
    position: "absolute",
    left: 0,
    top: 1320,
    zIndex: -1,

    [theme.breakpoints.up("sm")]: {
        width: "60%",
        borderRadius: "0 125px 125px 0px",
        height: 420,
        top: 720,
    },
    [theme.breakpoints.up("lg")]: {
        width: "50%",
        height: 550,
        top: 870
    },
}));

export default function BannerBackgroundRight() {
    return (
        <BannerLeft/>
    )
}