import React, {useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { Box } from "@mui/material";
import "../../styles/components/_calendar.scss";
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  EventBox,
  LayoutCalendar,
  ListItem,
} from "./styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import capitalizeFirstLetter, { getDayName, ViewsOptions } from "./config";
import lightTheme from "../../styles/theme/lightTheme";

const CalendarFull = ({currentEvents, dateClick, eventContent, eventClick}) => {
  const [showSelectChangeView, setShowSelectChangeView] = useState(false);
  const [view, setView] = useState("Mois");
  const calendarRef = React.createRef();

  const handleChangeView = (view) => {
    calendarRef.current.getApi().changeView(view.value);
  };

  /*const handleDateClick = (arg) => { // bind with an arrow function
    alert(arg.dateStr)
  }*/

  const state = {
    initialView: "dayGridMonth",
  };


  return (
    <LayoutCalendar className="demo-app-main">
      <Box class="fc-right-custom">
        <DropDownContainer>
          {!showSelectChangeView && (
            <DropDownHeader
              onClick={(e) => {
                e.preventDefault();
                setShowSelectChangeView(true);
              }}
            >
              {view}
              <Box className="icon_down">
                <FontAwesomeIcon icon={faChevronDown} style={{color: lightTheme.palette.text.primary, fontSize: "15px"}} />
              </Box>
            </DropDownHeader>
          )}
          {showSelectChangeView && (
            <DropDownListContainer>
              <DropDownList>
                {ViewsOptions.map((item, i) => {
                  return (
                    <ListItem
                      key={"listitem" + i}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowSelectChangeView(false);
                        handleChangeView(item);
                        setView(item.label);
                      }}
                    >
                      {item.label}
                      {i === 0 ? (
                        <Box className="icon_down">
                          <FontAwesomeIcon icon={faChevronUp} style={{color: lightTheme.palette.text.primary, fontSize: "15px"}} />
                        </Box>
                      ) : null}
                    </ListItem>
                  );
                })}
              </DropDownList>
            </DropDownListContainer>
          )}
        </DropDownContainer>
      </Box>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        locale={frLocale}
        timeZone={"UTC"}
        headerToolbar={{
          left: 'prev title next',
          right: ''
        }}
        editable={true}
        selectable={false}
        selectMirror={true}
        initialView={state.initialView}
        events={currentEvents}
        dateClick={dateClick}
        eventContent={eventContent}
        eventClick={eventClick}
      />
    </LayoutCalendar>

  )
}


export default CalendarFull;
