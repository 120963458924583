import React, { useState } from "react";
import { styled, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine} from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../styles/theme/lightTheme";

const UploadButton = styled(Button)(({theme}) => ({
  width: "100%",
  height: 45,
  backgroundColor: lightTheme.palette.primary.b2,
  borderRadius: "3px",
  padding: "10px",
  color: "#FFFFFF",
  fontFamily: "Century Gothic Bold",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  textTransform: "initial",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "15px",

  "&:hover": {
    backgroundColor: lightTheme.palette.primary.main,
  },
  "&:active": {
    backgroundColor: lightTheme.palette.primary.b2
  },
}));
const UploadFileInput = ({ onChange, onClick, fileName }) => {

  /*const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  }

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
    if(fileUploaded) {
      setFile(fileUploaded)
    }
  }*/

  return (
    <>
      <UploadButton onClick={onClick}>
        Appuyer pour sélectionner un fichier
        <FontAwesomeIcon icon={faArrowDownToLine} />
      </UploadButton>
      <input type={"file"} onChange={onChange} style={{display: "none"}}/>
      <Typography>{fileName}</Typography>
    </>
  )
}

export default UploadFileInput;