import React, { useEffect, useState } from "react";
import { AdminHeading } from "../../../../../components/headings";
import { Box, CircularProgress, FormControl, FormLabel, Grid, styled, Typography } from "@mui/material";
import "../../../../../styles/components/_layouts.scss";
import "../../../../../styles/components/_grids.scss";
import "../../../../../styles/components/_box.scss";
import "../../../../../styles/components/_inputs.scss";
import { SelectInputBasic, TextInputAdmin, TextInputClassic } from "../../../../../components/inputs";
import { BorderButtonPurple, CustomButton } from "../../../../../components/buttons";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import { Slide, toast, ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { phoneRegExp } from "../../../../../config";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";


const InputContainer = styled(FormControl)(({theme}) => ({
  rowGap: "5px"
}));

const WrapperButtons = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between"
}));

const BlockButtons = styled(Box)(({theme}) => ({
  display: "flex",
  columnGap: "20px",

  [theme.breakpoints.up("lg")]: {
    columnGap: "30px"
  },

}));

const schema = yup.object({
  prenom: yup.string().required("Merci de renseigner ce champ."),
  nom: yup.string().required("Merci de renseigner ce champ."),
  email: yup
    .string()
    .email("Merci de rentrer un email valide.")
    .required("Merci de renseigner ce champ."),
  telephone: yup
    .string()
    .required("Merci de renseigner ce champ.")
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide.")*/
    .nullable(),
  adresse: yup.string().required("Merci de renseigner ce champ."),
  ville: yup.string().required("Merci de renseigner ce champ."),
  codePostal: yup.number().required("Merci de renseigner ce champ."),
  pays: yup.string().required("Merci de renseigner ce champ."),
  /*roles: yup.string().required("Merci de selectionner un rôle.")*/
})
  .required();


const AddProspectCandidate = () => {
  let navigate = useNavigate();
  const [id, setId] = useState(null);
  const [openCard, setOpenCard] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [isAdmin, setIsAdmin] = useState("");
  const [phoneValue, setPhoneValue] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getAdmins();
  }, [id]);

  const handleAdminSelect = (evt) => {
    const inputValue = evt.target.value;
    setIsAdmin(inputValue)
  };

  const getAdmins = async () => {
    setLoading(true);

    try {
      const result = await Api.user.listWithFilter({
        roles: "ROLE_ADMIN",
      });
      const res = handlingErrors(result);

      if (res.status === 401) {
        toast.error("Votre session à expiré ! Merci de vous reconnecter.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
      }

      if (res.ok) {
        setAdmins(res.data);
      }

      if (res.status === 0) {
        toast.error("Quelque chose s'est mal passé ! Merci de réessayer plus tard.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
      }
    } catch (error) {
      toast.error("Quelque chose s'est mal passé ! Merci de réessayer plus tard.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
      window.scrollTo(0, 0);
    }

    setLoading(false);
  };
  const addAdmin = async (data) => {
    setLoadingAddAdmin(true);
    try {
      const result = await Api.user.add(data);
      const res = handlingErrors(result);
      if (res.ok) {
        toast.success("La création de l'admin à été réalisé avec succès !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleCheck} fontSize={25} color={lightTheme.palette.success.main}/>
        });
        await getAdmins();

        setTimeout(() => {
          navigate("/admin");
        }, 1000);
      }

      if (res.status === 422) {
        toast.error("L'email est déjà utilisé pour un autre compte !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
      }

      if (res.status === 401) {
        toast.error("Votre session à expiré ! Merci de vous reconnecter.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
        return;
      }

      if (res.status === 0) {
        toast.error("Quelque chose s'est mal passé ! Merci de réessayer plus tard.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
      }
    } catch (error) {
      toast.error("Quelque chose s'est mal passé ! Merci de réessayer plus tard.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
    }
    setLoadingAddAdmin(false);
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      roles: ["ROLE_CANDIDAT"],
      password: "password",
      status: "classic",
    };
    addAdmin(payload);
  };

  return (
    <>
      <div style={{width: "100%"}}>
        <AdminHeading handleClickIcon={() => navigate("/admin/gestion-prospect/liste-prospects-candidats")}>Ajouter un prospect candidat</AdminHeading>
        <Box
          className={"BoxContainer"}
          sx={{
            padding: "50px",
            display: "flex",
            flexDirection: "column",
            rowGap: {xs: "30px", sm: "55px", lg: "70px"}
          }}
        >
          <form>
            <Grid container spacing={2} className={"gridContainer"}>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Prénom</FormLabel>
                  <TextInputClassic
                    placeholder={"Prénom"}
                    width={"100%"}
                    register={{...register("prenom")}}
                  />
                  {errors.prenom && (
                    <Typography className={"error-message"}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Nom</FormLabel>
                  <TextInputClassic
                    placeholder={"Nom"}
                    width={"100%"}
                    register={{...register("nom")}}
                  />
                  {errors.nom && (
                    <Typography className={"error-message"}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Mail</FormLabel>
                  <TextInputClassic
                    placeholder={"Mail"}
                    width={"100%"}
                    type={"email"}
                    register={{...register("email")}}
                  />
                  {errors.email && (
                    <Typography className={"error-message"}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Numéro de téléphone</FormLabel>
                  {/*<TextInputClassic
                    placeholder={"Numéro de téléphone"}
                    width={"100%"}
                    register={{...register("telephone")}}
                  />*/}


                  <Controller
                    name="phone-input"
                    control={control}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value)
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        international
                        value={phoneValue}
                        onChange={setPhoneValue}
                        defaultCountry="CH"
                        id="phone-input"
                        {...register('telephone')}
                        containerStyle={{
                          border: "10px solid black",
                          background: "pink"
                        }}
                        inputStyle={{
                          background: "lightblue"
                        }}
                      />
                    )}
                  />

                  {errors.telephone && (
                    <Typography className={"error-message"}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Adresse</FormLabel>
                  <TextInputClassic
                    placeholder={"Adresse de l'entreprise"}
                    width={"100%"}
                    register={{...register("adresse")}}
                  />
                  {errors.adresse && (
                    <Typography className={"error-message"}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Ville</FormLabel>
                  <TextInputClassic
                    placeholder={"Ville"}
                    width={"100%"}
                    register={{...register("ville")}}
                  />
                  {errors.ville && (
                    <Typography className={"error-message"}>
                      {errors.ville.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Code postal</FormLabel>
                  <TextInputClassic
                    placeholder={"Code postal"}
                    width={"100%"}
                    register={{...register("codePostal")}}
                  />
                  {errors.codePostal && (
                    <Typography className={"error-message"}>
                      {errors.codePostal.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={"labelForm"}>Pays</FormLabel>
                  <TextInputClassic
                    placeholder={"Pays"}
                    width={"100%"}
                    register={{...register("pays")}}
                  />
                  {errors.pays && (
                    <Typography className={"error-message"}>
                      {errors.pays.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box
            className={"wrapperButtons"}
            sx={{
              justifyContent: "flex-end"
            }}
          >
            <Box className={"blockButtons"}>
              {loadingAddAdmin ? (
                <CircularProgress/>
              ) : (
                <>
                  <BorderButtonPurple
                    width={{xs: "50%", sm: 160, lg: 200}}
                    handleClick={() => navigate("/admin/gestion-prospect/liste-prospects-candidats")}
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={"none"}
                    width={{xs: "50%", sm: 160, lg: 200}}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Ajouter
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={"toast"}
      />
    </>
  )
}

export default AddProspectCandidate;