/*
import { useEffect, useState } from "react";
import { Box, Container, styled, Typography } from "@mui/material";
import { SectionsHeading } from "../../../../../components/headings";
import { FILE_URL } from "../../../../../config";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import Slider from "react-slick";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";

const Wrap = styled('div')(({theme}) => ({
  padding: "30px 0",
  backgroundColor: "#FFFFFF",

  ".slick-track": {
    display: "flex",
    alignItems: "center",
  },
  ".list-items": {
    display: "grid",
    gap: "2rem",
    gridTemplateColumns: "repeat(4,1fr)",

    "& .item-custom": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },
}));

const Item = styled("div")(({theme}) => ({
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top"
  }
}));

const CustomSlider = styled(Slider)(({theme}) => ({
  ".slick-slide": {
    backgroundColor: "#FFFFFF",
  },

  ".slick-slide div": {
    outline: "none",
    backgroundColor: "transparent",
    width: "200px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
}));

const ImageContainer = styled(Box)(({theme}) => ({
  width: 200,
  height: 200,
  borderRadius: "15px",
  overflow: "hidden",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)"
}));

const ContentContainer = styled(Box)(({theme}) => ({
  paddingTop: "20px",
  display: "flex",
  flexDirection: "column",


  "& .name": {
    fontFamily: "Century Gothic Bold",
    fontSize: 16,
    lineHeight: "140%",
    color: lightTheme.palette.text.primary,
  },

  "& .fonction": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    color: lightTheme.palette.grey.grey,
    textAlign: "center"
  }
}));

const ConsultantSection = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState([]);

  const settings = {
    autoplay: false,
    autoplaySpeed: 2000,
    dots: false,
    infinite: consultants.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    getConsultants();
  }, []);

  const getConsultants = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getConsultants();
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setConsultants(res.data);
        console.log("consultants", consultants.length);
      }
    } catch (error) {
      console.log("erreur");
    }
  }



  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        padding: "50px 0"
      }}
    >
      <Container maxWidth={"xl"}>
        {consultants && (
          <SectionsHeading type={"animation"}>{t('candidats.consultants')}</SectionsHeading>
        )}
      </Container>
      <Wrap>
        <CustomSlider {...settings}>
          {consultants.map((consultant, index) => (
            <Item className={"item"} key={consultant.id}>
              <ImageContainer>
                <Box component={"img"} src={consultant.photo} />
              </ImageContainer>
              <ContentContainer>
                <Typography className={"name"}>{consultant.prenom}</Typography>
                <Typography className={"fonction"}>{consultant.fonction}</Typography>
                <Typography>
                  <Markup
                    content={consultant.descriptif}
                  />
                </Typography>
              </ContentContainer>
            </Item>
          ))}
        </CustomSlider>
      </Wrap>
    </div>
  )
}

export default ConsultantSection;*/

import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Container, Box, Typography, styled } from "@mui/material";
import { SectionsHeading } from "../../../../../components/headings";
import lightTheme from "../../../../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";
import "../../../../../styles/components/_slick.scss";
import { useTranslation } from "react-i18next";
import Api from "../../../../../services/api.service";
import { handlingErrors } from "../../../../../utils";
import { Markup } from "interweave";

const BoxItem = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "15px",
  /*width: 200,

  [theme.breakpoints.up("sm")]: {
    rowGap: "25px",
    width: 290,
  },

  [theme.breakpoints.up("sm")]: {
    width: 570,
  },*/


}));

const TitleComment = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 12,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  textTransform: "uppercase",

  [theme.breakpoints.up("sm")]: {
    fontSize: 14
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 18
  },
}));

const FonctionComment = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.grey.grey,
  fontStyle: "italic",

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 14
  },

}))

const SeparatePoint = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "15px",

  span: {
    width: 10,
    height: 10,
    borderRadius: "100px",
    backgroundColor: lightTheme.palette.error.main,
    animation: `${colorEffect} 15s linear infinite`
  },


}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const ConsultantSection = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState([]);

  let settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    /*infinite: consultants.length > 3,*/
    centerPadding: "350px",
    slidesToShow: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "200px",
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: "100px",
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "50px",
        }
      }
    ]
  };

  useEffect(() => {
    getConsultants();
  }, []);

  const getConsultants = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getConsultants();
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setConsultants(res.data);
      }
    } catch (error) {
      console.log("erreur");
    }
  }

  return (
    <div
      style={{
        maxWidth: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        rowGap: "75px",
        padding: "100px 0",
      }}
    >
      <SectionsHeading
        type={"animation"}
        display={"flex"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        {t('candidats.consultants')}
      </SectionsHeading>
      <Slider {...settings}>
        {/*{i18n.language === "fr" ? (
            <>
              {temoignagesFr.map((item, index) => (
                <div>
                  <BoxItem className={"h3"}>
                    <TitleComment>{item.titre} - {item.type}</TitleComment>
                    <SeparatePoint>
                      <span/>
                      <span/>
                      <span/>
                    </SeparatePoint>
                    <Typography variant={"body2"} textAlign={"center"}>
                      <Markup content={item.texte} />
                    </Typography>
                  </BoxItem>
                </div>
              ))}
            </>
          ) : (
            <>

            </>
            )}*/}



        {consultants.map((item, index) => (
          <div key={index}>
            <Box
              component={"img"}
              src={item.photo}
              sx={{
                width: 200,
                height: 200,
                borderRadius: "15px",
                objectFit: "cover",
                objectPosition: "top",
                margin: "0 auto",
              }}
            />
            <BoxItem className={"h3"}>
              <Box>
                <TitleComment>{item.nom}</TitleComment>
                <FonctionComment>{item.fonction}</FonctionComment>
              </Box>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>
                <Markup content={item.descriptif} />
              </Typography>
            </BoxItem>
          </div>
        ))}

        {/*<div>
            <BoxItem className={"h3"}>
              <TitleComment>premier avis</TitleComment>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
            </BoxItem>
          </div>
          <div>
            <BoxItem className={"h3"}>
              <TitleComment>premier avis</TitleComment>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
            </BoxItem>
          </div>
          <div>
            <BoxItem className={"h3"}>
              <TitleComment>premier avis</TitleComment>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
            </BoxItem>
          </div>
          <div>
            <BoxItem className={"h3"}>
              <TitleComment>premier avis</TitleComment>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
            </BoxItem>
          </div>
          <div>
            <BoxItem className={"h3"}>
              <TitleComment>premier avis</TitleComment>
              <SeparatePoint>
                <span/>
                <span/>
                <span/>
              </SeparatePoint>
              <Typography variant={"body2"} textAlign={"center"}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</Typography>
            </BoxItem>
          </div>*/}
      </Slider>
    </div>
  )
}

export default ConsultantSection;
