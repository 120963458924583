import React from "react";
import {Box, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CardContainer = styled(Box)(({theme}) => ({
    width: 220,
    height: 280,
    minWidth: 220,
    background: "#FFFFFF",
    borderRadius: "25px",
    padding: "20px 15px 20px 15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",

    "&:hover": {
        "& .textrainbow": {
            backgroundImage: lightTheme.palette.gradients.gradientGreen,
            textFillColor: "transparent",
        }
    },

    [theme.breakpoints.up("lg")]: {
        width: 370,
        height: 370,
        padding: "20px 25px 20px 25px",
    },
}));

const ImageContainer = styled(Box)(({theme}) => ({
    width: 150,
    height: 120,
    position: "relative",
    /*transition: "all 3s ease-in-out",*/

    img: {
        position: "absolute",
        width: "100%",
        /*transition: "all 3s ease-in-out"*/
    },

    [theme.breakpoints.up("lg")]: {
        width: 250,
        height: 200,
    },
}));

const CardText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "textRainbow",
    name: "CardText",
})(({theme}) => ({
    textTransform: "uppercase",
    backgroundImage: lightTheme.palette.grey.dark,
    backgroundClip: "text",
    textFillColor: lightTheme.palette.grey.dark,
    backgroundSize: "150% 150%",
    textAlign: "center",
}));

export default function  CustomCard({textRainbow, children, picture, onMouseOver, onMouseOut}) {
    return (
      <CardContainer onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <ImageContainer>
              <img src={picture} alt={"Hcub3 Cvtheque"}/>
          </ImageContainer>
          <CardText variant={"subtitle2"} /*textRainbow={textRainbow}*/ className={"textrainbow"}>
              {children}
          </CardText>
      </CardContainer>
    )
}