import { Dialog, styled, Slide } from "@mui/material";
import { forwardRef } from "react";

export const EventModalCalendar = styled(Dialog)(({theme}) => ({
  "& .MuiDialogContent-root": {},
  "& .MuiDialogActions-root": {},
  "& .MuiDialog-paper": {
    background: "#F4FCFE",
    borderRadius: "inherit",
    width: "100%",
    maxWidth: "444px",
    height: "fit-content",
    padding: "16px",
    ".title": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "36px",
      fontFamily: "'Mulish_700Bold'",
      textAlign: "center",
      lineHeight: "45.18px",
    },
    ".container__content": {
      padding: ".5em 2em 0",
    },
    ".close__btn": {
      width: "20px",
      position: "absolute",
      right: "24px",
      top: "10px",
      cursor: "pointer",
    },
    ".block__content": {
      marginBottom: "10px",
      ".field__name": {
        display: "flex",
        alignItems: "center",
        fontFamily: "'Mulish_400Regular'",
        fontStyle: "italic",
        lineHeight: "17.5px",
        // marginBottom: "5px",
        img: {
          width: "24px",
          height: "24px",
          marginRight: "10px",
        },
        p: {
          fontSize: "14px !important",
        },
      },
      ".field__content": {
        background: "#E4F5FA",
        padding: "10px",
        textAlign: "center",
        borderRadius: "5px",
        fontFamily: "'Mulish_700Bold'",
        fontSize: "16px",
        lineHeight: "20px",
        span: {
          width: "210px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        },
      },
    },
    ".intervenant": {
      backgroundColor: "#CBE7EE",
      borderRadius: "5px",
      margin: "0 1.5em",
      padding: "0.5em",
    },
    ".attribuer_partenaire": {
      marginTop: "1em",
      paddingBottom: "1em",
      borderTop: "1px solid #4564AD",
    },
    ".cancel": {
      cursor: "pointer",
      fontFamily: "'Mulish_400Regular'",
      fontStyle: "italic",
      lineHeight: "15px",
      fontSize: "12px",
      position: "absolute",
      left: "-5px",
      bottom: "-25px",
      img: {
        width: "10px",
        height: "10px",
        marginRight: "10px",
      },
    },
    ".page_service": {
      cursor: "pointer",
      fontFamily: "'Mulish_400Regular'",
      fontStyle: "italic",
      lineHeight: "15px",
      fontSize: "12px",
      position: "absolute",
      right: "-5px",
      bottom: "-25px",
      img: {
        width: "10px",
        height: "10px",
        marginLeft: "10px",
      },
    },
  },
  [theme.breakpoints.down("xl")]: {},
  [theme.breakpoints.down("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down(480)]: {
    "& .MuiDialog-paper": {
      maxWidth: "unset",
      maxHeight: "unset",
      margin: 0,
      height: "100%",
      position: "fixed",
    },
    ".title": { fontSize: "32px" },
  },
}));

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});