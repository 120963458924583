import React from "react";
import { Box, styled, Typography } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import logo from "../../assets/images/logos/hcube-logo.png";

const AuthContainer = styled(Box)(({theme, padding, rowGap}) => ({
  width: 350,
  height: 440,
  backgroundColor: lightTheme.palette.primary.b6,
  borderRadius: "25px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "65px 25px 45px",
  rowGap: "30px",

  [lightTheme.breakpoints.up("sm")]: {
    width: 730,
    height: 530,
    padding: "75px 95px",
  },
  [lightTheme.breakpoints.up("lg")]: {
    width: 570,
    /*height: 480,*/
    height: "auto",
    /*padding: "65px 70px 45px",*/
    padding: padding ? padding : "65px 70px 45px",
    rowGap: rowGap ? rowGap : "25px",
    /*rowGap: "0px"*/
  },
}));

const LogoContainer = styled(Box)({
  width: "initial",
  height: 85,

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },

  [lightTheme.breakpoints.up("sm")]: {
    width: "initial",
    height: 115,
  },
  [lightTheme.breakpoints.up("lg")]: {
    width: "initial",
    height: 125,
  },
});

const Title = styled(Typography)({
  textTransform: "uppercase",
  textAlign: "center",
})

const AuthCard = ({children, title, padding, rowGap}) => {
  return (
    <AuthContainer padding={padding} rowGap={rowGap}>
      <LogoContainer>
        <img src={logo} alt={"Hcub3 Cvtheque"}/>
      </LogoContainer>
      <Title variant={"h2"} children={""}/>
      {children}
    </AuthContainer>
  )
}

export default AuthCard;