import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'

const InputContainer = styled.div`
  position: relative;

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 14px;
    line-height: 140%;
    color: #ffffff;
    background-color: #3caab5;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: #2b9eaa;
  }

  .inputfile + label {
    cursor: pointer; /* "hand" cursor */
  }

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .inputfile + label * {
    pointer-events: none;
  }

  .icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
  }
`

const ButtonInputUpload = ({ onChange }) => {
  return (
    <InputContainer>
      <input
        type={'file'}
        name={'file'}
        id={'file'}
        accept={'.doc,.docx,.pdf'}
        onChange={onChange}
        className={'inputfile'}
        multiple
      />
      <label htmlFor={'file'}>
        Télécharger le CV<span
          style={{
            color: lightTheme.palette.error.main,
          }}
        >*</span>
      </label>
      <FontAwesomeIcon icon={faArrowDownToLine} className={'icon'} />
    </InputContainer>
  )
}

export default ButtonInputUpload
