import { AdminHeading } from '../../../components/headings'
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { ButtonRadioInput, RadioInputBasic } from '../../../components/inputs'
import { CustomButton } from '../../../components/buttons'
import React, { useEffect, useState } from 'react'
import Api from '../../../services/api.service'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../styles/theme/lightTheme'
import { handlingErrors } from '../../../utils'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarRdv from '../../../components/CalendarFull/CalendarRdv'
import { fetchData } from '../../../services/request'
import { EventBox } from '../../../components/CalendarFull/styles'
import {
  capitalizeFirstLetter,
  getDayName,
} from '../../../components/CalendarFull/config'
import moment from 'moment/moment'

const schema = yup.object({
  dateDebut: yup.string().required('Merci de sélectionner une date'),
  type: yup.string().required('Merci de sélectionner'),
  user: yup.string().required('Merci de sélectionner un nom'),
})

const DemandeRendezVous = () => {
  const [loading, setLoading] = useState([])
  const [users, setUsers] = useState([])
  const [value, setValue] = useState(dayjs(new Date()))
  const [slots, setSlots] = useState([])
  const [times, setTimes] = useState('')
  const [type, setType] = useState('physique')
  const [role, setRole] = useState([])
  const [currentEvents, setCurrentEvents] = useState([])
  const [events, setEvents] = useState([])
  const [showDetailEvent, setShowDetailEvent] = useState(false)
  const [showDetail, setShowDetail] = useState({})
  const [selectTime, setSelectTime] = useState(null)
  const [id, setId] = useState([])
  const [userValueId, setUserValueId] = useState('')
  const [inputValueAutocomplete, setInputValueAutocomplete] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    setLoading(true)
    try {
      const result = await Api.calendar.list()
      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        const datas = res.data
        setEvents(datas)
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    handleData()
  }, [events])

  const handleData = () => {
    const eventData = events.map((item) => {
      return {
        id: item.id,
        title: item.detail,
        start: item.dateDebut,
        end: item.dateFin,
        status: item.status,
        extendedProps: {
          nomCandidat: item.candidat?.profile?.nom,
          nomEntreprise: item.entreprise?.raisonSocial,
          candidat: item.candidat,
          entreprise: item.entreprise,
        },
      }
    })
    setCurrentEvents(eventData)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const handleChangeRadio = (inputValue) => {
    setType(inputValue)
  }

  const getUsers = async () => {
    setLoading(true)

    try {
      const result = await Api.user.list()
      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (res.ok) {
        setUsers(res.data)
        setRole(res.data.role)
        setId(res.data.id)
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }

    setLoading(false)
  }

  useEffect(() => {
    getDateDisponible()
  }, [value])

  const DateValue = format(new Date(value), 'yyyy-MM-dd')

  const getDateDisponible = async () => {
    const DateFormat = format(new Date(value), 'yyyy-MM-dd')

    try {
      let result = await Api.calendar.listPerDate(DateFormat)
      let res = handlingErrors(result)

      if (res.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.ok) {
        const datas = res.data
        const DateValue = format(new Date(value), 'dd/MM/yyyy')

        setSlots(datas[DateValue])
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const addEvent = async (data) => {
    setLoading(true)

    try {
      const result = await Api.calendar.add(data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success('La demande de rendez-vous a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        await getUsers()
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleChangeButtonRadio = (inputValue) => {
    setTimes(inputValue)
  }

  const onSubmit = (data) => {
    const payload = {
      ...data,
      dateDebut: `${DateValue}T${times}.211Z`,
      dateFin: `${DateValue}T${times}.211Z`,
      type: 'demande_rdv',
      entreprise: `/api/entreprises/${id}`,
      candidat: `/api/candidats/${id}`,
    }
    addEvent(payload)
  }

  const handleDateSelect = (selectInfo) => {
    const day = getDayName(selectInfo.dateStr)
    const date = `${capitalizeFirstLetter(day)}, ${selectInfo.dateStr
      .split('T')[0]
      .replaceAll('-', '/')}`
    const splited = selectInfo.dateStr.split('T')

    if (selectInfo.allDay) {
      const current = splited[0] + 'T08:00:00'
    } else {
      const current = moment
        .utc(selectInfo.dateStr)
        .format('YYYY-MM-DDTHH:mm:ss')
    }
  }

  const handleEventClick = async (clickInfo) => {
    const day = getDayName(clickInfo.event.extendedProps.date_debut)
    const date = `${capitalizeFirstLetter(day)}, aaa`

    setShowDetailEvent(true)
    setShowDetail(clickInfo.event)
  }

  function renderEventContent(eventInfo) {
    if (loading)
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )

    return (
      <EventBox>
        {/*{typeEvent === "rdv_decouverte" ? (
          <>*/}
        <Box
          className={'heading__services'}
          sx={{
            borderBottom: {
              xs: 'none !important',
              sm: `1px solid "#4564AD"`,
            },
          }}
        >
          <Typography className={'hour'}>
            {eventInfo.timeText.replace(/:/g, 'H')}
          </Typography>
          <Typography className={'name'}>
            {eventInfo.event.extendedProps.candidat === null ? (
              <>{eventInfo.event.extendedProps.nomEntreprise}</>
            ) : (
              <>{eventInfo.event.extendedProps.nomCandidat}</>
            )}
          </Typography>
        </Box>
        {/*</>
        ) : (
          <>
            <Box className={"healing__list"}>
              <Typography className="hour">{eventInfo.timeText}</Typography>
            </Box>
            <Box className="body__list">
              <Box className="desc">{eventInfo.event.title}</Box>
            </Box>
          </>
        )}*/}
      </EventBox>
    )
  }

  /* useEffect(() => {
    console.log('userValueId', userValueId)
  }, [setUserValueId, setInputValueAutocomplete]) */

  return (
    <div style={{ width: '100%' }}>
      <AdminHeading displayIconReturn={'none'}>
        Demander un rendez-vous
      </AdminHeading>
      <form>
        <Box className={'splitLayoutContainer'}>
          <Paper elevation={2} className={'firstSplit splitPaper'}>
            <CalendarRdv
              value={value}
              setValue={(newValue) => {
                setValue(newValue)
              }}
              currentEvents={currentEvents}
              eventContent={renderEventContent}
              dateClick={handleDateSelect}
              eventClick={handleEventClick}
              register={{ ...register('dateDebut') }}
              errors={errors}
            />
          </Paper>
          <Paper elevation={2} className={'secondSplit splitPaper'}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Personne à inviter</FormLabel>
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={userValueId}
                    onChange={(event, newValue) => {
                      setUserValueId(newValue)
                    }}
                    inputValue={inputValueAutocomplete}
                    onInputChange={(event, newInputValue) => {
                      setInputValueAutocomplete(newInputValue)
                    }}
                    options={users.map((option) => option.nom)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Entrer un nom"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                        sx={{
                          border: '1px solid #9E9E9E',
                          borderRadius: '5px',
                          height: 45,
                          marginTop: '10px',
                        }}
                        {...register('user')}
                      />
                    )}
                    sx={{
                      '.MuiOutlinedInput-root': {
                        height: 45,
                      },
                    }}
                  />
                  {errors.user && (
                    <Typography className={'error-message'}>
                      {errors.user.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Je souhaite un rdv :</FormLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      columnGap: '20px',
                      marginTop: '10px',
                    }}
                  >
                    <RadioInputBasic
                      value={'physique'}
                      label={'physique'}
                      isChecked={type === 'physique'}
                      handleChange={handleChangeRadio}
                    />
                    <RadioInputBasic
                      value={'distanciel'}
                      label={'distanciel'}
                      isChecked={type === 'distanciel'}
                      handleChange={handleChangeRadio}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>Liste des créneaux disponibles</FormLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      /*flexWrap: "wrap",*/
                      rowGap: '20px',

                      height: 240,
                      overflowY: 'scroll',
                      marginTop: '10px',

                      '&::-webkit-scrollbar': {
                        width: 10,
                      },

                      '&::-webkit-scrollbar-track': {
                        background: lightTheme.palette.primary.b5,
                      },

                      '&::-webkit-scrollbar-thumb': {
                        background: lightTheme.palette.primary.b3,
                      },

                      '&::-webkit-scrollbar-thumb:hover': {
                        background: lightTheme.palette.primary.b2,
                      },
                    }}
                  >
                    {slots.map((slot) => {
                      return (
                        <ButtonRadioInput
                          value={slot}
                          handleChange={handleChangeButtonRadio}
                          border={'1px solid #9E9E9E'}
                          width={'100%'}
                          spanHeight={'55px'}
                          isChecked={times === slot}
                        >
                          {slot.replace(/:/g, 'h').substr(0, 5)}
                        </ButtonRadioInput>
                      )
                    })}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  displayIcon={'none'}
                  width={'100%'}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Valider ma demande
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
    </div>
  )
}

export default DemandeRendezVous
