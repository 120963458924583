import RegisterCard from '../../../components/cards/RegisterCard'
import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { PasswordInputBasic } from '../../../components/inputs'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import TextareaInputClassic from '../../../components/inputs/TextareaInputClassic'
import '../../../styles/components/_grids.scss'
import lightTheme from '../../../styles/theme/lightTheme'
import { BorderButton, CustomButton } from '../../../components/buttons'
import { loadStripe } from '@stripe/stripe-js'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { passwordRegExp } from '../../../config'
import '../../../styles/components/_errors.scss'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import StripeAppTest from '../../../components/Stripe/StripeAppTest'
import heartEnvelope from '../../../assets/icons/coolicon.png'
import { useNavigate } from 'react-router-dom'
import { VALEURS } from '../../../data'

const schema = yup.object({
  password: yup
    .string()
    .matches(
      passwordRegExp,
      'Votre mot de passe doit comporter au moins 6 caractères et doit contenir au moins une lettre, un chiffre et un caractère spéciale (!@#$%^&*).'
    )
    .required('Merci de créer votre mot de passe.'),
  confirmPassword: yup
    .string()
    .required('Merci de ressaisir votre mot de passe.')
    .oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas.'),
})

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [lightTheme.breakpoints.up('md')]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const StepsEntreprise = ({ token }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState(false)
  const [file, setFile] = useState()
  const navigate = useNavigate()
  const [valeurs, setValeurs] = useState([])
  const [password, setPassword] = useState('')
  const [errorLength, setErrorLength] = useState(false)
  const [errorNumber, setErrorNumber] = useState(false)
  const [errorSpecialCarter, setErrorSpecialCarter] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    const containsNumber = /\d/.test(password)
    const containsSpecialCharacter = /[!@#$%^&*]/.test(password)
    setErrorLength(password.length > 6)
    setErrorNumber(containsNumber)
    setErrorSpecialCarter(containsSpecialCharacter)
  }, [password])

  function onClose() {
    setCurrentStep(0)
  }

  function onSuccess() {
    toast.success('Félicitations ! Votre compte est confirmé.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    navigate('/se-connecter')
  }

  function nextStep() {
    setCurrentStep(currentStep + 1)
  }

  /*function handleChange(event) {
    setFile(event.target.files[0])
  }*/

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const submitFinishInscription = async (values) => {
    setLoading(true)

    const result = await Api.finalisationInscription.postInscription(token, {
      password: values.password,
      valeurs: valeurs,
    })

    const handling = handlingErrors(result)

    if (handling.status === 401) {
      console.log('erreur post')
    }

    if (handling.ok) {
      const res = handling.data
      setId(res.id)
      setSnapShot(res)
      setOpenCard(true)
      setLoading(false)
      if (res.userOffres.length === 0) {
        toast.success('Félicitations ! Votre compte est confirmé.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        navigate('/se-connecter')
      }
    } else {
      //console.log('erreur post')
      setLoading(false)
    }
  }

  /*  useEffect(() => {
    console.log("sanpshot inscription", snapShot);
  }, [id]) */

  return (
    <>
      <RegisterCard>
        <form style={{ height: '100%', position: 'relative' }}>
          <Grid container spacing={2} className={'gridContainerRegister'}>
            {!openCard ? (
              <>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Choisissez un mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Mot de passe"}
                      width={"100%"}
                      register={{...register("password")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Mot de passe'}
                      register={{ ...register('password') }}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    {errors.password && (
                      <Typography className={'error-message'}>
                        {errors.password.message}
                      </Typography>
                    )}
                    <div>
                      <ul style={{ fontSize: 12 }}>
                        <li
                          style={{
                            textDecoration: errorLength
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Au moins 6 caractères
                        </li>
                        <li
                          style={{
                            textDecoration: errorNumber
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un chiffre
                        </li>
                        <li
                          style={{
                            textDecoration: errorSpecialCarter
                              ? 'line-through'
                              : 'none',
                          }}
                        >
                          Un caractère spéciale (!@#$%^&*)
                        </li>
                      </ul>
                    </div>
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Ressaisissez le mot de passe
                    </CustomFormLabel>
                    {/*<TextInputClassic
                      placeholder={"Confirmer mot de passe"}
                      width={"100%"}
                      register={{...register("confirmPassword")}}
                      type={"password"}
                    />*/}

                    <PasswordInputBasic
                      placeholder={'Confirmer mot de passe'}
                      register={{ ...register('confirmPassword') }}
                    />

                    {errors.confirmPassword && (
                      <Typography className={'error-message'}>
                        {errors.confirmPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <CustomFormLabel>
                      Quel sont les valeurs de l'entreprise ?
                    </CustomFormLabel>
                    <Autocomplete
                      sx={{ width: '100%', backgroundColor: '#fff' }}
                      multiple
                      defaultValue={valeurs}
                      options={VALEURS['fr']}
                      onChange={(event, newValue) => {
                        setValeurs(newValue)
                      }}
                      renderTags={(valeurs, getTagProps) =>
                        valeurs.map((valeur, index) => (
                          <Chip
                            variant="outlined"
                            label={valeur}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder={' '} />
                      )}
                    />
                  </InputContainer>
                </Grid>
              </>
            ) : (
              <>
                {currentStep === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        Résumé de l'offre proposé :
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'h5'} textTransform={'uppercase'}>
                        {snapShot.userOffres[0].offre.titre}
                      </Typography>
                      <Typography
                        variant={'subtitle2'}
                        textTransform={'uppercase'}
                      >
                        {snapShot.userOffres[0].offre.sousTitre}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>Contenu :</Typography>
                      <ul>
                        {snapShot.userOffres[0].offre.points[1]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[1]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[2]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[2]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[3]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[3]}</li>
                        )}

                        {snapShot.userOffres[0].offre.points[4]?.length > 0 && (
                          <li>{snapShot.userOffres[0].offre.points[4]}</li>
                        )}
                      </ul>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={'subtitle2'}>Prix :</Typography>
                      <ul>
                        <li>
                          {(
                            snapShot.userOffres[0].offre.prix *
                            JSON.parse(sessionStorage.getItem('currency'))
                              .currentRate
                          ).toFixed(2)}
                          {''}
                          {sessionStorage.getItem('currency')
                            ? JSON.parse(sessionStorage.getItem('currency'))
                                .devise
                            : 'CHF'}
                        </li>
                      </ul>
                    </Grid>
                  </>
                )}

                {/*{currentStep === 1 && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "50px",
                        position: "absolute",
                        top: "20px",
                        width: "calc(100% - 47px)",
                        height: "calc(100% - 47px)"
                      }}
                    >
                      <Typography variant={"subtitle2"} textTransform={"uppercase"}>Prix de l'offre "{snapShot.userOffres[0].offre.titre}" - {snapShot.userOffres[0].offre.prix} €</Typography>
                      <Box>
                        <Stripe userId={snapShot.id} offerId={snapShot.userOffres[0].offre.id} offerPrice={snapShot.userOffres[0].offre.prix}/>
                      </Box>
                    </Grid>
                  </>
                )}*/}
              </>
            )}

            <Box
              sx={{
                width: 'calc(100% - 16px)',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '15px',
                justifyContent: 'flex-end',
                alignItems: 'end',
                marginLeft: '16px',
              }}
            >
              {currentStep > 0 && (
                <Box width={'50%'} zIndex={1}>
                  <BorderButton
                    type={'basic'}
                    displayIconRight={'none'}
                    handleClick={() => setCurrentStep(currentStep - 1)}
                  >
                    Étape précédente
                  </BorderButton>
                </Box>
              )}
              <Box width={'50%'}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {!openCard ? (
                      <CustomButton
                        handleClick={handleSubmit(submitFinishInscription)}
                      >
                        Étape suivante
                      </CustomButton>
                    ) : currentStep === 0 ? (
                      <CustomButton handleClick={nextStep}>
                        Paiement
                      </CustomButton>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </form>

        {currentStep === 1 && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '50px',
                position: 'absolute',
                top: '20px',
                width: 'calc(100% - 47px)',
                height: 'calc(100% - 47px)',
              }}
            >
              <Typography variant={'subtitle2'} textTransform={'uppercase'}>
                Tarif de l’offre {snapShot.userOffres[0].offre.titre}{' '}
                {snapShot.userOffres[0].offre.sousTitre} -{' '}
                {(
                  snapShot.userOffres[0].offre.prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}
                {''}
                {sessionStorage.getItem('currency')
                  ? JSON.parse(sessionStorage.getItem('currency')).devise
                  : 'CHF'}
              </Typography>
              <Box>
                <StripeAppTest
                  userId={snapShot.id}
                  offreId={snapShot.userOffres[0].id}
                  offrePrice={(
                    snapShot.userOffres[0].offre.prix *
                    JSON.parse(sessionStorage.getItem('currency')).currentRate
                  ).toFixed(2)}
                  onSuccess={onSuccess}
                  onClose={onClose}
                />
              </Box>
            </Grid>
          </>
        )}

        {currentStep === 2 && (
          <Grid item xs={12}>
            <Box>
              <img src={heartEnvelope} alt={'Hcub3 Cvtheque - Welcome'} />
            </Box>
            <Typography>Bienvenue cher Jobers</Typography>
            <Typography>
              Félicitation ! Tu es désormais un jobers, rend toi vite sur ton
              profil pour découvrir les fonctionnalités de ton compte.
            </Typography>
            <CustomButton>Accéder à votre compte</CustomButton>
          </Grid>
        )}
      </RegisterCard>
    </>
  )
}

export default StepsEntreprise
