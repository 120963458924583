import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  /*  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8), */
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const EditList = ({
  open,
  onClose,
  onSave,
  values,
  datas,
  id,
  role = 'candidat',
  type,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [dataValue, setDataValue] = useState(values)
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    switch (type) {
      case 'competences':
        setTitle('Compétences Techniques Principales (5 maximum)')
        break
      case 'competencesAnnexe':
        setTitle('Compétences Techniques Secondaires')
        break
      case 'Environnements Techniques':
        setTitle('Environnements techniques')
        break
      case 'softskils':
        setTitle('SoftSkills')
        break

      default:
        break
    }
  }, [type])

  const onSubmit = async () => {
    setLoading(true)
    try {
      const result = await Api.candidate.edit(id, { [type]: dataValue })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  const onSubmitCompany = async () => {
    setLoading(true)
    try {
      const result = await Api.company.edit(id, { [type]: values })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.editList')} {title}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {role === 'ROLE_ENTREPRISE' && <></>}

            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
            >
              {type === 'Environnements Techniques' ? (
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  options={datas[0]}
                  groupBy={(option) => option.title} // Grouper par "titre"
                  getOptionLabel={(option) => option.technology}
                  onChange={(event, newValue) => {
                    setDataValue(newValue)
                  }}
                  renderTags={(dataValue, getTagProps) =>
                    dataValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option?.technology}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('box.addthe') + title}
                    />
                  )}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
              ) : type === 'competences' ? (
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={dataValue}
                  options={datas}
                  onChange={(event, newValue) => {
                    setDataValue(newValue.slice(0, 5))
                  }}
                  freeSolo={
                    type === 'competences' || type === 'competencesAnnexes'
                      ? true
                      : false
                  }
                  renderTags={(dataValue, getTagProps) =>
                    dataValue
                      .slice(0, 5)
                      .map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('box.addthe') + title}
                    />
                  )}
                />
              ) : (
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={dataValue}
                  options={datas}
                  onChange={(event, newValue) => {
                    setDataValue(newValue)
                  }}
                  freeSolo={
                    type === 'competences' || type === 'competencesAnnexe'
                      ? true
                      : false
                  }
                  renderTags={(dataValue, getTagProps) =>
                    dataValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('box.addthe') + title}
                    />
                  )}
                />
              )}
              {/* <TextInputClassic
                value={message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={'Ajouter et cliquer sur ENTRER'}
                width={'100%'}
              /> */}
            </Grid>
            {/* <Grid item>
              {values &&
                values.map((valeur, i) => (
                  <div key={i} className="list-values">
                    {valeur}
                    <span
                      onClick={(e) => {
                        handleRemoveElement(valeur)
                      }}
                      title="supprimer ?"
                    >
                      x
                    </span>
                  </div>
                ))}
            </Grid> */}
          </Grid>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={role === 'entreprise' ? onSubmitCompany : onSubmit}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditList
