import React, { useEffect, useState } from "react";
import { Box, Skeleton, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { useTranslation } from "react-i18next";
import { contentsService } from "../../services/contents.service";
import { contentsOptions } from "../../services/request";

const CustomBox = styled(Box)(({theme}) => ({
    position: "relative",
    width: 205,
    height: 205,

    [theme.breakpoints.up("sm")]: {
        width: 350,
        height: 350,
    },

    [theme.breakpoints.up("lg")]: {
        width: 445,
        height: 445,
    },
}));

const ImageContainer = styled(Box)(({theme}) => ({
    width: 167,
    height: 167,
    borderRadius: "100%",
    border: "4px solid #FFFFFF",
    overflow: "hidden",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 1,

    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },

    ".skeleton-circular": {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },

    [theme.breakpoints.up("sm")]: {
        width: 286,
        height: 286,
    },

    [theme.breakpoints.up("lg")]: {
        width: 367,
        height: 367,
    },


}));

const CircleColor = styled(Box)(({theme}) => ({
    width: 167,
    height: 167,
    borderRadius: "100%",
    background: lightTheme.palette.gradients.gradientGreen,
    transform: "rotate(-180deg)",
    position: "absolute",
    right: 0,
    bottom:0,

    [theme.breakpoints.up("sm")]: {
        width: 286,
        height: 286,
    },

    [theme.breakpoints.up("lg")]: {
        width: 367,
        height: 367,
    },
}));

export default function RoundedImage() {
    const { i18n } = useTranslation();
    const [aboutSection, setAboutSection] = useState([]);

    useEffect(() => {
        contentsService.getAllContents(contentsOptions)
          .then(res => {
              const response = res.data;
              const dataAbout = (response.filter(data => data.section === "mot-fondateur"));
              setAboutSection(dataAbout);
          })
          .catch(err => console.log(err))
    }, [setAboutSection])

    return (
        <CustomBox>
            {i18n.language === "fr" ? (
              <>
                  {aboutSection
                    .filter(item => item.lang === "fr")
                    .map((item, index) => (
                      <div key={index}>
                          {!item.photos[0] ? (
                            <ImageContainer>
                                <Skeleton variant="circular" className={"skeleton-circular"} animation={"wave"}/>
                            </ImageContainer>
                          ) : (
                            <ImageContainer>
                                <img src={item.photos[0].lien} alt={"Hcub3 Cvtheque - Louisa Halil"}/>
                            </ImageContainer>
                            )}
                      </div>
                    ))}
              </>
            ):(
              <>
                  {aboutSection
                    .filter(item => item.lang === "en")
                    .map((item, index) => (
                      <div key={index}>
                          {!item.photos[0] ? (
                            <ImageContainer>
                                <Skeleton variant="circular" className={"skeleton-circular"} animation={"wave"}/>
                            </ImageContainer>
                          ) : (
                            <ImageContainer>
                                <img src={item.photos[0].lien} alt={"Hcub3 Cvtheque - Louisa Halil"}/>
                            </ImageContainer>
                            )}
                      </div>
                    ))}
              </>
              )}
            <CircleColor/>
        </CustomBox>
    )
}