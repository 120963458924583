import ApiRequest from "../request/ApiRequest";
import { BASE_URL_UP } from "../../config";

export default class FinalisationInscription {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async postInscription(token, data) {
    try {
      const res = await this.ApiRequest.post(`inscription/finalisation/${token}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async getInfos(token) {
    try {
      const res = await this.ApiRequest.get(`inscription/finalisation/${token}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }
}