import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box, CircularProgress
} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faChevronLeft, faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import AppPagination from "../paginations";
import Api from "../../services/api.service";
import { format } from "date-fns";


const HeaderTab = styled(TableHead)(({theme}) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6
}));

const TitleTab = styled(TableCell)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  textTransform: "uppercase",

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 14
  },
}));

const BodyTab = styled(TableCell)(({theme, type}) => ({
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
}));

const BlockTabCircle = styled(TableCell)(({theme}) => ({
  width: 25,
  height: 61,
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "5px",

  ".firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle": {
    width: 5,
    height: 5,
    border: "1px solid #FFFFFF",
    borderRadius: "50%",
  }
}));

const BlockTabSquare = styled(TableCell)(({theme}) => ({
  width: 15,
  height: 61,
  backgroundColor: lightTheme.palette.primary.b2,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
}));

const BlockTab = styled(TableCell)(({theme, type}) => ({
  width: 25,
  height: 60,
  padding: 0,
  backgroundColor: lightTheme.palette.primary.b2,
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`
}));

const ButtonIcon = styled(Box)(({theme}) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  margin: "0 auto",

  "&:hover": {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  "&:active": {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  }
}))

const ListePostes = ({type, items, setId, loading, handleClick, openCard}) => {
  const [products, setProducts] = useState(items);



  useEffect(() => {

  }, [])

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          borderRadius: 0
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={15} sx={{padding: 0}}/>
              <TitleTab>Nom</TitleTab>
              <TitleTab>Date d'entrée</TitleTab>
              <TableCell/>
              <TableCell width={25} sx={{padding: 0}}/>
            </TableRow>
          </HeaderTab>
          <TableBody>
            {!openCard ? (
              <>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <BlockTabSquare/>
                    <BodyTab type={type}>{item.titre}</BodyTab>
                    <BodyTab type={type}>{format(new Date(item.date), "dd/MM/yyyy")}</BodyTab>
                    <BodyTab sx={{padding: "10px",}}>
                      {/*<ButtonIcon
                        onClick={() => {
                          setId(item.id);
                          handleClick(`${item.nom} ${item.prenom}`);
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} color={lightTheme.palette.primary.b2} fontSize={16} />
                      </ButtonIcon>*/}
                    </BodyTab>
                    <BlockTab type={type}/>
                  </TableRow>
                ))}
              </>
            ): (
              <>
                <p>hello</p>
              </>
            )}


          </TableBody>
        </Table>
      </TableContainer>

      <AppPagination setProducts={(p) => setProducts(p)}/>
    </>

  )
}

export default ListePostes;