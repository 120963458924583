import React from "react";
import {Typography, Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";

const HeadingContainer = styled(Box)(({theme, justifyContent, display}) => ({
    width: "auto",
    /*minWidth: "min-content",*/
    height: 25,
    display: display,
    justifyContent: justifyContent,
    alignItems: "center",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
        height: 30,
        width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
        height: 50,
    },
}));

const HeadBox = styled(Box)(({theme, maxWidthBox}) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    position: "relative",
    /*width: {xs: 230, lg: 350},*/
    /*width: "100%",*/
    height: "100%",


    [theme.breakpoints.up("sm")]: {
        width: "max-content",
        /*maxWidth: 550,*/
        maxWidth: maxWidthBox
    },
    [theme.breakpoints.up("lg")]: {

    },

}));

const Circle = styled(Box, {
    shouldForwardProp: (prop) => prop !== "animation",
    name: "Circle",
})(({theme, type, color, leftCircle}) => ({
    width: 25,
    height: 25,
    borderRadius: "100%",
    backgroundColor: type === "animation"
        ? lightTheme.palette.error.main
        : type === "basic"
            ? "#FFFFFF"
            : lightTheme.palette.error.main,
    position: "absolute",
    /*left: "-20px",*/
    animation: type === "animation"
        ? `${colorEffect} 15s linear infinite`
        : type === "basic"
            ? "none"
            : "none",
    zIndex: 0,
    left: leftCircle ? 0 : leftCircle,

    [theme.breakpoints.up("sm")]: {
        height: 30,
        width: 30,
        /*left: "-10px",*/
    },
    [theme.breakpoints.up("lg")]: {
        height: 50,
        width: 50,
        /*left: "-20px",*/
    },

}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const Heading = styled(Typography)(({theme, lineHeight, top, textAlign}) => ({
    color: lightTheme.palette.grey.dark,
    textTransform: "uppercase",
    /*position: "absolute",
    left: "7px",*/
    lineHeight: lineHeight,
    /*top: top,*/
    textAlign: textAlign,
    zIndex: 1,
    paddingTop: "4px",
    paddingLeft: "8px",

    [theme.breakpoints.up("sm")]: {
        left: "10px",
        paddingTop: "5px",
        paddingLeft: "11px"
    },
    [theme.breakpoints.up("lg")]: {
        left: "17px",
        paddingTop: "11px",
        paddingLeft: "18px"
    },
}));

export default function SectionsHeading({children, type, justifyContent, lineHeight, top, textAlign, display, leftCircle, maxWidthBox}) {
    return (
        <HeadingContainer display={display} justifyContent={justifyContent}>
            <HeadBox maxWidth={maxWidthBox}>
                <Circle type={type} left={leftCircle}/>
                <Heading variant={"h1"} lineHeight={lineHeight} top={top} textAlign={textAlign}>{children}</Heading>
            </HeadBox>
        </HeadingContainer>
    )
}