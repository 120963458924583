import ApiRequest from "../request/ApiRequest";

export default class Candidate {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add() {
    try {
      const res = await this.ApiRequest.post("utilisateurs");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("candidats");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`candidats/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`candidats/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`candidats/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listProspect() {
    try {
      const res = await this.ApiRequest.get("candidats.json?profile.status=contact");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("candidats.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async addPlusCv(data) {
    try {
      const res = await this.ApiRequest.post("candidat/inscription", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async sendEval(id, data) {
    try {
      const res = await this.ApiRequest.post(`evaluation/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

}