export const TYPE_DOCUMENT = [
  'Cv',
  'Diplômes',
  'Certifications Techniques',
  'Lettre de motivation',
  'Certificat de travail',
  'Recommandations',
  'References',
  'Autres',
]

export const LANGUES = [
  'Albanais',
  'Allemand',
  'Afrikaans',
  'Araona',
  'Azéri',
  'Amazigh',
  'Arménien',
  'Aymara',
  'Anglais',
  'Arabe',
  'Bengali',
  'Bulgare',
  'Bésiro',
  'Bichelamar',
  'Biélorusse',
  'Birman',
  'Catalan',
  'Coréen',
  'Croate',
  'Cambodgien',
  'Chinois',
  'Danois',
  'Espagnol',
  'Finnois',
  'Français',
  'Japonais',
  'Portugais',
  'Russe',
  'Guarani',
  'Grec',
  'Hongrois',
  'Italien',
  'Kikongo',
  'Kiswahili',
  'Lingala',
  'Malais',
  'Mongol',
  'Néerlandais',
  'Occitan',
  'Ourdou',
  'Persan',
  'Quechua',
  'Roumain',
  'Samoan',
  'Serbe',
  'Sesotho',
  'Slovaque',
  'Slovène',
  'Suédois',
  'Tamoul',
  'Turc',
  'Canichana',
  'Cavineña',
  'Cayubaba',
  'Chácobo',
  'Chichewa',
  'Chimane',
  'Créole de Guinée-Bissau',
  'Créole haïtien',
  'Créole seychellois',
  'Divehi',
  'Dzongkha',
  "Ese 'ejja",
  'Estonien',
  'Éwé',
  'Fidjien',
  'Filipino',
  'Géorgien',
  'Gilbertin',
  "Guarasu'we",
  'Guarayu',
  'Hébreu',
  'Hindi',
  'Hindi des Fidji',
  'Hiri motu',
  'Indonésien',
  'Irlandais',
  'Islandais',
  'Itonama',
  'Kallawaya',
  'Kabiyè',
  'Kazakh',
  'Khmer',
  'Kirghiz',
  'Kirundi',
  'Lao',
  'Néo-zélandaise',
  'Papouasienne',
  'Latin',
  'Leko',
  'Letton',
  'Lituanien',
  'Luxembourgeois',
  'Macédonien',
  'Machineri',
  'Malgache',
  'Maltais',
  'Maori',
  'Maori des Îles Cook',
  'Maropa',
  'Marshallais',
  'Mirandais',
  'Mojeño-Trinitario',
  'Mojeño-Ignaciano',
  'Monténégrin',
  'Moré',
  'Mosetén',
  'Movima',
  'Nauruan',
  'Ndébélé du Transvaal',
  'Népalais',
  'Norvégien',
  'Ouzbek',
  'Pacahuara',
  'Pachto',
  'Paluan',
  'Polonais',
  'Puquina',
  'Sango',
  'Shikomor',
  'Shona',
  'Shuar',
  'Sindebele',
  'Singhalais',
  'Sirionó',
  'Somali',
  'Sotho du Nord',
  'Sotho du Sud',
  'Tacana',
  'Tadjik',
  'Tamazight',
  'Tapiete',
  'Tchèque',
  'Tétoum',
  'Tigrinya',
  'Thaï',
  'Tok pisin',
  'Tongien',
  'Toromona',
  'Turkmène',
  'Tuvaluan',
  'Ukrainien',
  'Uru-Chipaya',
  'Vietnamien',
  'Wichi',
  'Yaminahua',
  'Yuki',
  'Yaracaré',
  'Zamuco',
  'Zoulou',
  'Adyguéen',
  'Agul',
  'Altaï',
  'Assamais',
  'Avar',
  'Bachkir',
  'Bas saxon',
  'Basque',
  'Bouriate',
  'Cantonais',
  'Carolinien',
  'Chamorro',
  'Darguine',
  'Erzya',
  'Galicien',
  'Gallois',
  "Gwich'in",
  'Hawaïen',
  'Iakoute',
  'Ingouche',
  'Inuinnaqtun',
  'Inuktitut',
  'Kabarde',
  'Kalmouk',
  'Karatchaï-balkar',
  'Khakasse',
  'Komi',
  'Koumyk',
  'Lak',
  'Lezghien',
  'Mannois',
  'Mari',
  'Moksa',
  'Nogaï',
  'Occitan (aranais)',
  'Ossète',
  'Oudmourte',
  'Ouïghour',
  'Romanche',
  'Routoul',
  'Ruthène',
  'Same',
  'Sorabe',
  'Swati',
  'Tabassaran',
  'Tat',
  'Tatare',
  'Tchétchène',
  'Tchouvache',
  'Tibétain',
  'Tobi',
  'Touvain',
  'Tsakhur',
  'Tsonga',
  'Tswana',
  'Venda',
  'Xhosa',
]

export const TYPE_RDV = {
  fr: [
    { id: 'rdv_accompagnement', titre: 'Pour accompagnement' },
    { id: 'rdv_decouverte', titre: 'Pour un RDV découverte' },
    { id: 'autre', titre: 'Autre (tout autre sujets, aide par exemple)' },
  ],
  en: [
    { id: 'rdv_accompaniment', titre: 'For accompaniment' },
    { id: 'rdv_decouverte', titre: 'For a discovery meeting' },
    { id: 'autre', titre: 'Other (any other topics, help for example)' },
  ],
}
export const TYPE_ACTIONS = {
  fr: [
    { id: 'Appel', titre: 'Appel' },
    { id: 'Pré qualification', titre: 'Pré qualification' },
    { id: 'Premier entretien', titre: 'Premier entretien' },
    { id: 'Deuxième entretien', titre: 'Deuxième entretien' },
    { id: 'Troisième entretien', titre: 'Troisième entretien' },
    { id: 'Tests technique', titre: 'Tests technique' },
    { id: 'Positionnement', titre: 'Positionnement' },
    { id: 'Coaching entretien', titre: 'Coaching entretien' },
    { id: 'Rendez-vous partenaire', titre: 'Rendez-vous partenaire' },
    { id: 'Rendez-vous client', titre: 'Rendez-vous client' },
  ],
  en: [
    { id: 'rdv_accompaniment', titre: 'For accompaniment' },
    { id: 'rdv_decouverte', titre: 'For a discovery meeting' },
    { id: 'autre', titre: 'Other (any other topics, help for example)' },
  ],
}
export const TYPE_CONTRAT = [
  { id: 'CDI', titre: 'CDI' },
  { id: 'CDD', titre: 'CDD' },
]
export const TYPE_DEVISE = [
  { id: 'chf', titre: 'CHF' },
  { id: 'eur', titre: 'EUR' },
]
export const VALEURS = {
  fr: [
    'ambition',
    'amour',
    'altruisme',
    'assurance',
    'confiance en soi',
    'confiance',
    'courage',
    'découverte',
    'détermination',
    'discernement',
    'discrétion',
    'discipline',
    'engagement',
    'équité',
    'fiabilité',
    'fidélité',
    'honnêteté',
    'justice',
    'liberté',
    'loyauté',
    'paix',
    'partage',
    'réflexion',
    'respect',
    'rigueur',
    'sécurité',
    'sincérité',
    'sympathie',
    'tolérance',
    'vigilance',
    'volonté',
    'intégrité',
    'humain',
  ],
  en: [
    'ambition',
    'love',
    'altruism',
    'insurance',
    'self-confidence',
    'trust',
    'courage',
    'discovery',
    'determination',
    'discernment',
    'discretion',
    'discipline',
    'commitment',
    'equity',
    'reliability',
    'loyalty',
    'honesty',
    'justice',
    'freedom',
    'loyalty',
    'peace',
    'sharing',
    'reflection',
    'respect',
    'rigor',
    'security',
    'sincerity',
    'sympathy',
    'tolerance',
    'vigilance',
    'will',
    'integrity',
    'human',
  ],
}

export const SOFTSKILLS = {
  fr: [
    'passionné',
    'empathie',
    'efficacité',
    'flexibilité',
    'prise de décision',
    "travail d'équipe",
    'curiosité',
    'écoute active',
    'communication orale',
    'flexibilité et adaptabilité',
    'attitude positive',
    'faire confiance',
    'résolution de problèmes',
    'ouverture à la nouveauté',
    "capacité d'organisation",
    'adaptabilité',
    'autonomie',
    'sens des responsabilités',
    'discipline et autodiscipline',
    'gestion du stress',
    'audace',
    "esprit d'initiative",
    'gestion du temps',
    'communication',
    "esprit d'équipe ou de coopération",
    'intelligence émotionnelle ou empathie',
    'négociation',
    'capacité à déléguer',
    'résolution de conflits et la médiation',
    'leadership ou la capacité à motiver',
    'capacité à créer et entretenir un réseau',
    'mémoire',
    'attention ou concentration',
    'esprit critique',
    'créativité',
    'capacité à se remettre en question et à rebondir',
    'persévérance',
    'capacité de synthèse',
    'curiosité intellectuelle',
  ],
  en: [
    'keen',
    'empathy',
    'efficiency',
    'flexibility',
    'decision making',
    'teamwork',
    'curiosity',
    'active listening',
    'oral communication',
    'flexibility and adaptability',
    'positive attitude',
    'trust',
    'problems solving',
    'openness to novelty',
    'organizational capacity',
    'adaptability',
    'autonomy',
    'sense of responsibility',
    'discipline and self-discipline',
    'stress management',
    'audacity',
    'initiative',
    'management of time',
    'communication',
    'team spirit or cooperation',
    'emotional intelligence or empathy',
    'negotiation',
    'ability to delegate',
    'conflict resolution and mediation',
    'leadership or the ability to motivate',
    'ability to create and maintain a network',
    'memory',
    'attention or concentration',
    'critical mind',
    'creativity',
    'ability to question oneself and bounce back',
    'perseverance',
    'synthesis capacity',
    'Intellectual curiosity',
  ],
}

export const environnementsTechniques = [
  { title: "Systèmes d'exploitation", technology: 'Windows' },
  { title: "Systèmes d'exploitation", technology: 'macOS' },
  { title: "Systèmes d'exploitation", technology: 'Linux' },
  { title: 'Langages de programmation', technology: 'Java' },
  { title: 'Langages de programmation', technology: 'Python' },
  { title: 'Langages de programmation', technology: 'C++' },
  { title: 'Langages de programmation', technology: 'JavaScript' },
  { title: 'Langages de programmation', technology: 'Ruby' },
  { title: 'Langages de programmation', technology: 'PHP' },
  { title: 'Langages de programmation', technology: 'Swift' },
  { title: 'Langages de programmation', technology: 'C#' },
  { title: 'Langages de programmation', technology: 'Rust' },
  { title: 'Bases de données', technology: 'MySQL' },
  { title: 'Bases de données', technology: 'PostgreSQL' },
  { title: 'Bases de données', technology: 'Oracle' },
  { title: 'Bases de données', technology: 'Microsoft SQL Server' },
  { title: 'Bases de données', technology: 'MongoDB' },
  { title: 'Bases de données', technology: 'SQLite' },
  { title: 'Développement Web', technology: 'HTML/CSS' },
  {
    title: 'Développement Web',
    technology: 'Frameworks JavaScript (ex. React, Angular, Vue.js)',
  },
  {
    title: 'Développement Web',
    technology: 'Serveurs web (ex. Apache, Nginx)',
  },
  { title: 'Développement Web', technology: 'Protocoles HTTP/HTTPS' },
  { title: 'Développement Web', technology: 'CMS (ex. WordPress)' },
  { title: 'Infrastructure Cloud', technology: 'Amazon Web Services (AWS)' },
  { title: 'Infrastructure Cloud', technology: 'Microsoft Azure' },
  { title: 'Infrastructure Cloud', technology: 'Google Cloud Platform (GCP)' },
  { title: 'Infrastructure Cloud', technology: 'Docker' },
  { title: 'Infrastructure Cloud', technology: 'Kubernetes' },
  { title: 'Langages de Scripting', technology: 'Bash' },
  { title: 'Langages de Scripting', technology: 'PowerShell' },
  { title: 'Langages de Scripting', technology: 'Perl' },
  { title: 'Langages de Scripting', technology: 'Ruby' },
  {
    title: 'Réseaux et Sécurité',
    technology: 'Protocoles réseau (ex. TCP/IP, DNS)',
  },
  { title: 'Réseaux et Sécurité', technology: 'Firewalls' },
  { title: 'Réseaux et Sécurité', technology: 'VPN' },
  { title: 'Réseaux et Sécurité', technology: 'Cryptographie' },
  { title: 'Réseaux et Sécurité', technology: 'Sécurité informatique' },
  {
    title: 'Outils de Développement',
    technology:
      'Environnements de développement intégré (IDE) (ex. Visual Studio, IntelliJ IDEA)',
  },
  {
    title: 'Outils de Développement',
    technology: 'Outils de gestion de versions (ex. Git)',
  },
  {
    title: 'Outils de Développement',
    technology: 'Outils de construction (ex. Apache Maven, Gradle)',
  },
  { title: 'Automatisation et Ordonnancement', technology: 'Ansible' },
  { title: 'Automatisation et Ordonnancement', technology: 'Jenkins' },
  { title: 'Automatisation et Ordonnancement', technology: 'Chef' },
  { title: 'Automatisation et Ordonnancement', technology: 'Puppet' },
  { title: 'Automatisation et Ordonnancement', technology: 'Cron' },
  { title: 'Virtualisation', technology: 'VirtualBox' },
  { title: 'Virtualisation', technology: 'VMware' },
  { title: 'Virtualisation', technology: 'Hyper-V' },
  { title: 'Systèmes de gestion de contenu (CMS)', technology: 'WordPress' },
  { title: 'Systèmes de gestion de contenu (CMS)', technology: 'Drupal' },
  { title: 'Systèmes de gestion de contenu (CMS)', technology: 'Joomla' },
  { title: 'Analyse de données et Machine Learning', technology: 'R' },
  { title: 'Analyse de données et Machine Learning', technology: 'MATLAB' },
  { title: 'Analyse de données et Machine Learning', technology: 'TensorFlow' },
  {
    title: 'Analyse de données et Machine Learning',
    technology: 'scikit-learn',
  },
  { title: 'IoT (Internet des objets)', technology: 'Arduino' },
  { title: 'IoT (Internet des objets)', technology: 'Raspberry Pi' },
  { title: 'IoT (Internet des objets)', technology: 'MQTT' },
  {
    title: 'Langages de requête',
    technology: 'SQL (Structured Query Language',
  },
  { title: 'Langages de requête', technology: 'XPath' },
  { title: 'Langages de requête', technology: 'XQuery' },
  { title: 'Gestion de projet', technology: 'Agile/Scrum' },
  { title: 'Gestion de projet', technology: 'Microsoft Project' },
  { title: 'Gestion de projet', technology: 'Jira' },
]

export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'France', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
]

export const statusMaritalList = [
  {
    id: 'celibataire',
    titre: 'Célibataire',
  },
  {
    id: 'divorce',
    titre: 'Divorcé(e)',
  },
  {
    id: 'separe',
    titre: 'Séparé(e)',
  },
  {
    id: 'marie',
    titre: 'Marié(e)',
  },
  {
    id: 'pacse',
    titre: 'Pacsé(e)',
  },
  {
    id: 'concubin',
    titre: 'Concubin(e)',
  },
]

export const disponibilitesList = [
  {
    id: 1,
    titre: 'Disponible immédiatement',
  },
  {
    id: 2,
    titre: 'Disponible sous',
  },
  {
    id: 3,
    titre: 'En poste mais ouvert au job',
  },
  {
    id: 4,
    titre: 'Disponible dès le',
  },
  {
    id: 5,
    titre: 'Je suis en poste',
  },
]



export const disponibilitesList2 = [
  {
    id: 1,
    titre: {'fr' : 'Disponible immédiatement', 'en' : 'Available immediately'},
  },
  {
    id: 2,
    titre: {'fr' : 'Disponible sous ', 'en' : 'Available under '},
  },
  {
    id: 3,
    titre: {'fr' : 'En poste mais ouvert au job idéal', 'en' : 'Currently employed but open to the ideal job'},
  },
  {
    id: 4,
    titre: {'fr' : 'Disponible dès le ', 'en' : 'Available from '},
  },
  {
    id: 5,
    titre: {'fr' : 'En poste', 'en' : 'Not available'},
  },
]

export const disponibleSousList2 = [
  {
    id: 1,
    titre: {'fr' : '1 mois', 'en' : '1 month'},
  },
  {
    id: 2,
    titre: {'fr' : '2 mois', 'en' : '2 months'},
  },
  {
    id: 3,
    titre: {'fr' : '3 mois', 'en' : '3 months'},
  },
  {
    id: 4,
    titre: {'fr' : '6 mois', 'en' : '6 months'},
  },
]

export const disponibleSousList = [
  {
    id: 1,
    titre: '1 mois',
  },
  {
    id: 2,
    titre: '2 mois',
  },
  {
    id: 3,
    titre: '3 mois',
  },
  {
    id: 4,
    titre: '6 mois',
  },
]

export const niveauLanguesList = [
  {
    id: 1,
    titre: 'Capacité professionnelle limitée',
  },
  {
    id: 2,
    titre: 'Capacité professionnelle complète',
  },
  {
    id: 3,
    titre: 'Bilingue ou natif',
  },
]
