import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useSelector } from 'react-redux'

const SidebarLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: lightTheme.palette.grey.light,
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyle: 'none',
  height: 60,
  textDecoration: 'none',
  fontSize: 14,
  fontFamily: 'Century Gothic Bold',
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const Icon = styled(Box)({
  width: 25,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SidebarLabel = styled('span')(({ theme }) => ({
  marginLeft: '12px',
}))

const DropdownLink = styled(NavLink)({
  height: 60,
  paddingLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '15px',
  textDecoration: 'none',
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',

  '&:hover': {
    color: lightTheme.palette.grey.light,
    cursor: 'pointer',
  },

  '&.active': {
    color: lightTheme.palette.grey.light,
  },
})

const DropdownLabel = styled('span')({})

const Submenu = ({ item }) => {
  const userData = useSelector((state) => state.userAuth.user)
  const [subnav, setSubnav] = useState(false)
  const [countCandidat, setCountCandidat] = useState(0)
  const [countProspect, setCountProspect] = useState(0)
  const [countEntreprise, setCountEntreprise] = useState(0)
  const [countProspectEntreprise, setCountProspectEntreprise] = useState(0)
  const [countDemande, setCountDemande] = useState(0)

  const getUsers = async (role, status) => {
    try {
      const result = await Api.user.listWithPaginationAndFilter({
        role: role,
        status: status,
        //entreprises: userData.data.role === 'ROLE_COMMERCIAL' ? userData.data.id : '',
      })

      const res = handlingErrors(result)

      if (res.ok) {
        if (role === 'ROLE_CANDIDAT') {
          status === 'active'
            ? setCountCandidat(res.data['hydra:totalItems'])
            : setCountProspect(res.data['hydra:totalItems'])
        }
        if (role === 'ROLE_ENTREPRISE') {
          status === 'active'
            ? setCountEntreprise(res.data['hydra:totalItems'])
            : setCountProspectEntreprise(res.data['hydra:totalItems'])
        }
        //(role === 'ROLE_CANDIDAT')
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }
    } catch (error) {
      console.debug(error)
    }
  }

  useEffect(() => {
    if (item.counter && item.counter === 'entreprise') {
      getUsers('ROLE_ENTREPRISE', 'classic')
      getUsers('ROLE_ENTREPRISE', 'active')
    }
    if (item.counter && item.counter === 'candidat') {
      getUsers('ROLE_CANDIDAT', 'classic')
      getUsers('ROLE_CANDIDAT', 'active')
      getUsers(['ROLE_ENTREPRISE', 'ROLE_CANDIDAT'], 'contact')
    }
  }, [item])

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <SidebarLink
        /*to={item.path}*/ to={'#'}
        onClick={item.subNav && showSubnav}
      >
        <Title>
          <Icon>{item.icon}</Icon>
          <SidebarLabel>{item.title}</SidebarLabel>
        </Title>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subitem, index) => {
          return (
            <DropdownLink to={subitem.path} key={index}>
              {subitem.icon}
              <DropdownLabel>
                {subitem.title}{' '}
                {subitem.counter &&
                  item.counter === 'candidat' &&
                  subitem.counter === 'client' &&
                  '(' + countCandidat + ')'}
                {subitem.counter &&
                  item.counter === 'candidat' &&
                  subitem.counter === 'prospect' &&
                  '(' + countProspect + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'client' &&
                  '(' + countEntreprise + ')'}
                {subitem.counter &&
                  item.counter === 'entreprise' &&
                  subitem.counter === 'prospect' &&
                  '(' + countProspectEntreprise + ')'}
                {subitem.counter &&
                  subitem.counter === 'demande' &&
                  '(' + countDemande + ')'}
              </DropdownLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}

export default Submenu
