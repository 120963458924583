import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import { ListTable } from '../../../../components/tables'
import { AdminHeading } from '../../../../components/headings'
import { BorderButtonPurple } from '../../../../components/buttons'
import { SearchInputBasic } from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { DeleteProfilModal, FiltersModal } from '../../../../components/modals'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import CandidateProfil from './CandidateProfil'
import { useNavigate, useParams } from 'react-router-dom'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import AppPagination from '../../../../components/paginations'
import DetailCandidat from '../../../../components/modals/DetailCandidat'
import { useSelector } from 'react-redux'
import { debounce } from '../../../../config'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const MatchingCandidate = () => {
  const [open, setOpen] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [loading, setLoading] = useState(false)
  const [openDetailCandidat, setOpenDetailCandidat] = useState(false)
  const [id, setId] = useState(null)
  const [candidat, setCandidat] = useState(null)
  const [search, setSearch] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [besoinEntreprise, setBesoinEntreprise] = useState()

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleDetailCandidat = (candidat) => {
    setOpenDetailCandidat(true)
    setCandidat(candidat)
  }
  const handleCloseDetailCandidat = () => {
    setOpenDetailCandidat(false)
    //fetchData()
  }

  const params = useParams()


  useEffect(() => {
    getDetailBesoin(params.besoinId)
  }, [params.besoinId])

  const getDetailBesoin = async (besoinId) => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.besoinEntreprise.matchingCandidatesByOffre(
        besoinId
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoinEntreprise(res.data.besoin)
        setCandidates(res.data.candidats)
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (candidatId, candidat) => {
    handleDetailCandidat(candidat)
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading displayIconReturn={'none'}>
          Listes des candidats matcher avec"{besoinEntreprise?.poste?.titre}"
        </AdminHeading>

        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <SearchInputBasic
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <SearchInputBasic onChange={handleSearchChange} />
            {/* <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-client-candidat')
                }
              >
                Ajouter un candidat
              </BorderButtonPurple>
            </BlockButton> */}
          </WrapperButton>
          <ListTable
            type={'candidat'}
            items={candidates}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
            listeMatching={true}
            matchingPost={besoinEntreprise?.poste?.titre}
            isAdmin={true}
          />
        </Box>
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>

      <FiltersModal open={open} onClose={handleClose} />

      {openDetailCandidat && (
        <DetailCandidat
          open={openDetailCandidat}
          onClose={handleCloseDetailCandidat}
          candidat={candidat}
          companyId={besoinEntreprise?.entreprise?.id}
          poste={besoinEntreprise?.poste?.titre}
          isAdmin={true}
        />
      )}
    </>
  )
}

export default MatchingCandidate
