import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { ListTable } from '../../../components/tables'
import { AdminHeading } from '../../../components/headings'
import { SearchInputBasic, SelectInputObject } from '../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { DeleteProfilModal, FiltersModal } from '../../../components/modals'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import AppPagination from '../../../components/paginations'
import { debounce } from '../../../config'
import UserProfilHeading from '../../../components/headings/UserProfilHeading'
import ListTableCandidat from '../../../components/tables/ListTableCandidat'
import AddEvent from '../../../components/modals/AddEvent'
import { useCallback } from 'react'
import DetailCandidat from '../../../components/modals/DetailCandidat'
import DetailCandidatEvent from '../../../components/modals/DetailCandidatEvent'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const Candidate = () => {
  const { t, i18n } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [loading, setLoading] = useState(false)
  const [openDetailCandidat, setOpenDetailCandidat] = useState(false)
  const [id, setId] = useState(null)
  const [search, setSearch] = useState('')
  const [candidat, setCandidat] = useState(null)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [page, setPage] = useState(
    sessionStorage.getItem('pageCandidat')
      ? parseInt(sessionStorage.getItem('pageCandidat'))
      : 1
  )
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState([
    { id: 0, titre: t('filter.place'), filtre: 'ville' },
    { id: 1, titre: t('filter.poste'), filtre: 'candidat.poste.titre' },
    {
      id: 2,
      titre: t('filter.competences'),
      filtre: 'candidat.competences',
    },
    { id: 3, titre: t('filter.softskils'), filtre: 'candidat.softskils' },
    { id: 4, titre: t('filter.values'), filtre: 'candidat.valeurs' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])
  const handleSave = useCallback(() => {
    setOpen(false)
  }, [])
  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)
  const handleDetailCandidat = (candidat) => {
    setOpenDetailCandidat(true)
    setCandidat(candidat)
  }
  const handleCloseDetailCandidat = () => {
    setOpenDetailCandidat(false)
    //fetchData()
  }
  useEffect(() => {
    // Observer les changements de langue
    const languageChangeHandler = () => {
      setFilter([
        { id: 0, titre: t('filter.place'), filtre: 'ville' },
        { id: 1, titre: t('filter.poste'), filtre: 'candidat.poste.titre' },
        {
          id: 2,
          titre: t('filter.competences'),
          filtre: 'candidat.competences',
        },
        { id: 3, titre: t('filter.softskils'), filtre: 'candidat.softskils' },
        { id: 4, titre: t('filter.values'), filtre: 'candidat.valeurs' },
      ])
    }

    i18n.on('languageChanged', languageChangeHandler)
    return () => {
      i18n.off('languageChanged', languageChangeHandler)
    }
  }, [i18n])
  useEffect(() => {
    setFilterSelect(filter.find((obj) => obj.filtre === filterSelect.filtre))
  }, [filter])

  useEffect(() => {
    getCandidates(page)
  }, [id, search])

  const handleClickIcon = () => {
    navigate(`/user`)
  }

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getCandidates(page)
    sessionStorage.setItem('pageCandidat', page)
  }

  const getCandidates = async (page) => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)
    payload = {
      [filterSelect.filtre]: search,
    }

    try {
      const result = await Api.user.listWithPaginationAndFilter({
        /*status: "active",*/
        role: 'ROLE_CANDIDAT',
        status: ['active', 'classic'],
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCandidates(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const handleClickToProfil = (candidatId, candidat) => {
    handleDetailCandidat(candidat)
  }
  const handleClickEvent = (userId) => {
    setUserId(userId)
    setOpen(true)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <UserProfilHeading
          displayIconReturn={'display'}
          handleClickIcon={handleClickIcon}
        >
          {t('profile.candidatListTitle')}
        </UserProfilHeading>

        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <SearchInputBasic
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
            {/* <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-client-candidat')
                }
              >
                Ajouter un candidat
              </BorderButtonPurple>
            </BlockButton> */}
          </WrapperButton>
          <ListTableCandidat
            type={'candidat'}
            items={candidates}
            setId={setId}
            loading={loading}
            handleClickToProfile={handleClickToProfil}
            handleClick={handleClickEvent}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>

      {open && (
        <AddEvent
          open={open}
          onClose={handleClose}
          id={userId}
          onSave={handleSave}
        />
      )}
      {openDetailCandidat && (
        <DetailCandidatEvent
          open={openDetailCandidat}
          onClose={handleCloseDetailCandidat}
          candidat={candidat}
          companyId={userData.data.idProfile}
        />
      )}
    </>
  )
}

export default Candidate
