import ApiRequest from "../request/ApiRequest";

export default class OffresUtilisateurs {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("offres_utilisateurs", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }


}