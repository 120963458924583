import React from "react";
import AppNavBar from "../navbar/AppNavBar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { CircleLayout, QuadrantCircleLayout } from "./index";

const AuthLayout = () => {
  return (
    <div className={"AuthLayout"}>
      <AppNavBar/>
      <Box
        sx={{
          height: {xs: "calc(100vh - 100px)", lg: "calc(100vh - 100px)"},
          position: "relative",
          overflow: "hidden"
        }}
      >
        <QuadrantCircleLayout right={0} top={"-50px"} display={{xs: "none", lg: "block"}}/>
        <Outlet/>
        <CircleLayout bottom={"-200px"} left={"-200px"} display={{xs: "none", lg: "block"}}/>
      </Box>

    </div>
  )
}

export  default AuthLayout;