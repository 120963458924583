import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, styled, MenuItem } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faEuroSign, faFrancSign } from "@fortawesome/pro-solid-svg-icons";

const CustomSelect = styled(Select)(({ theme }) => ({
    width: "100%",
    height: 45,
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: lightTheme.palette.primary.main,
    color: "#FFFFFF",
    fontFamily: "Century Gothic Bold",
    textTransform: "uppercase",
    cursor: "pointer",
    position: "relative",
    '& .MuiOutlinedInput-notchedOutline': {
        border: "none",
    },
    '& .css-1irc8sz-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
        background: 'red'
    },


    [theme.breakpoints.up("lg")]: {
        height: 50,
        width: 140,
    },

}));

export default function SelectDevise() {
    const [devise, setDevise] = useState((sessionStorage.getItem("currency")) ? JSON.parse(sessionStorage.getItem("currency")).devise : 'CHF');
    const handleDeviseChange = evt => {
        const devise = evt.target.value;
        setDevise(devise);
        const currency = {
            "devise": devise,
            "currentRate": (devise === "CHF") ? 1 : JSON.parse(sessionStorage.getItem("currency")).rate,
            "rate": JSON.parse(sessionStorage.getItem("currency")).rate
        };
        sessionStorage.setItem("currency", JSON.stringify(currency))
        window.location.reload(false);
    };

    return (
        <CustomSelect
            onChange={handleDeviseChange}
            value={devise}
            defaultValue={"CHF"}
            IconComponent={() => (
                <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                        position: "absolute", right: "28px"
                    }}
                    onChange={handleDeviseChange}
                />
            )}
        >
            <MenuItem value="CHF">CHF (<FontAwesomeIcon
                icon={faFrancSign}
                onChange={handleDeviseChange}
            />)</MenuItem>
            <MenuItem value="EUR">EURO (<b>€</b>)</MenuItem>
        </CustomSelect>
    )
}