import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    breakpoints: {
      values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1100,
          xl: 1536,
      },
    },
    components: {
      MuiContainer: {
          styleOverrides: {
              root: {
                  paddingLeft: "25px",
                  paddingRight: "25px",
              }
          }
      },
    },
    typography: {
        fontFamily: "Century Gothic",
        h1: {
            fontSize: 16,
            lineHeight: "120%",
            fontFamily: "Century Gothic Bold",
            textTransform: "uppercase"
        },
        h2: {
            fontSize: 16,
            lineHeight: "120%",
            fontFamily: "Century Gothic Bold",
        },
        h3: {
            fontSize: 12,
            lineHeight: "120%",
            fontFamily: "Century Gothic Bold",
        },
        h4: {
            fontSize: 16,
            lineHeight: "120%",
            fontFamily: "Century Gothic Bold",
        },
        subtitle1: {
            fontSize: 12,
            lineHeight: "140%",
            fontFamily: "Century Gothic Bold",
            textTransform: "uppercase",
            letterSpacing: "0.2px",
            color: "#212121",
        },
        subtitle2: {
            fontSize: 10,
            lineHeight: "140%",
            fontFamily: "Century Gothic Bold",
            letterSpacing: "0.2px",
            color: "#333333",
        },
        body1: {
            fontSize: 12,
            lineHeight: "140%",
            fontFamily: "Century Gothic",
            letterSpacing: "0.2px",
            color: "#212121",
        },
        body2: {
            fontSize: 10,
            lineHeight: "140%",
            fontFamily: "Century Gothic",
            color: "#333333"
        },
        caption: {
            fontSize: 12,
            lineHeight: "140%",
            fontFamily: "Century Gothic",
            letterSpacing: "0.2px",
            color: "#9E9E9E"
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: "#2b9eaa",
            b2: "#3caab5",
            b3: "#A4ADFF",
            b4: "#B5BCFF",
            b5: "#CACFFE",
            b6: "#E3E6FF",
        },
        secondary: {
            main: "#BF4FCE",
            v2: "#D96CE8",
            v3: "#E57FF3",
            v4: "#F293FF",
            v5: "#F5ACFF",
            v6: "#F8C2FF",
        },
        error: {
            main: "#F76E11",
            light: "#FFBE92",
            dark: "#C14E00",
            nope: "#FF0000"
        },
        warning: {
            main: "#F3C440",
            light: "#FFE497",
            dark: "#DFB02D"
        },
        success: {
            main: "#96CEB4",
            light: "#C6F0DC",
            dark: "#69B190",
        },
        grey: {
            main: "#9E9E9E",
            light: "#EEEEEE",
            dark: "#333333",
            grey: "#7E7E7E",
        },
        admin: {
            adminMenu: "#37454B",
            adminSearch: "#424E53"
        },
        shadow: {
            headerShadow: "0px 4px 2px rgba(0, 0, 0, 0.1)",
            cardShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
            dropdownShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        },
        gradients: {
            gradientBlue: "linear-gradient(286.17deg, #BF4FCE 0%, #2b9eaa 100%)",
            gradientYellowOrange: "linear-gradient(286.17deg, #F76E11 0%, #F3C440 100%)",
            gradientGreen: "linear-gradient(283.08deg, #3caab5 4.44%, #96CEB4 100%)",
            gradientAll: "linear-gradient(286.17deg, #BF4FCE 0%, #2b9eaa 25.75%, #96CEB4 54.4%, #F3C440 82%, #F3C440 97.63%)",
            gradientB2: "linear-gradient(286.17deg, #3caab5 100%, #3caab5 100%)"
        },
        text: {
            primary: "#333333",
            secondary: "#FFFFFF",
        }
    },

});

lightTheme.components.MuiContainer = {
    ...lightTheme.components.MuiContainer,
    [lightTheme.breakpoints.up("xs")]: {
        paddingRight: "25px",
        paddingLeft: "25px"
    },
};

lightTheme.typography.h1 = {
    ...lightTheme.typography.h1,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 18,
    },
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 24,
    },
};

lightTheme.typography.h2 = {
    ...lightTheme.typography.h2,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 18,
    },
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 24,
    },
};

lightTheme.typography.h3 = {
    ...lightTheme.typography.h3,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 14,
    },
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 18,
    },
};

lightTheme.typography.h4 = {
    ...lightTheme.typography.h4,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 18,
    },
};

lightTheme.typography.subtitle1 = {
    ...lightTheme.typography.subtitle1,
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 14,
    },
};

lightTheme.typography.subtitle2 = {
    ...lightTheme.typography.subtitle2,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 12,
    },
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 14,
    },
};

lightTheme.typography.body1 = {
    ...lightTheme.typography.body1,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 14,
    },
};

lightTheme.typography.body2 = {
    ...lightTheme.typography.body2,
    [lightTheme.breakpoints.up("sm")]: {
        fontSize: 12,
    },
    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 14,
    },
};

export default lightTheme;