import React from "react";
import {Box, styled} from "@mui/material";
import logo from "../../assets/images/logos/hcube-logo.png";
import {keyframes} from "@emotion/react";

const CustomBox = styled(Box)(({theme}) => ({
    position: "absolute",
    width: 185,
    right: 0,
    top: "155px",
    transform: "translate(0, -50%)",

    [theme.breakpoints.up("sm")]: {
        width: 270,
        right: 0,
        top: "155px",
    },
    [theme.breakpoints.up("lg")]: {
        width: 420,
    },
}));

const ContainerCircle = styled(Box)(({theme}) => ({
    width: 185,
    height: 180,
    backgroundColor: "#F76E11",
    borderRadius: "1000px 0px 0px 1000px",
    animation: `${colorEffect} 15s linear infinite`,

    [theme.breakpoints.up("sm")]: {
        height: 285,
        width: 270
    },
    [theme.breakpoints.up("lg")]: {
        height: 310,
        width: 420
    },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #2b9eaa; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const ImageBox = styled(Box)(({theme}) => ({
    position: "absolute",
    width: 80,
    height: 80,
    top: "50%",
    left: "40px",
    transform: "translate(0 , -50%)",

    [theme.breakpoints.up("sm")]: {
        height: 120,
        width: 120,
        left: "70px",
    },
    [theme.breakpoints.up("lg")]: {
        height: 180,
        width: 180,
    },
}));

export default function AboutImageLogo() {
    return (
        <CustomBox>
            <ContainerCircle/>
            <ImageBox>
                <img src={logo} alt={"Hcub3 Cvtheque"} />
            </ImageBox>
        </CustomBox>
    )
}