import React from 'react'
import { Box, Grid, Modal, styled, Typography } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import StripeAppTest from '../Stripe/StripeAppTest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const PaymentModal = ({ open, onClose, onSuccess, userId, userOffre }) => {
  const { t } = useTranslation()
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
        {t('box.title.paimentOffer')}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            textAlign: 'center',
          }}
        >
          <Typography variant={'subtitle2'}>
            {userOffre?.offre?.titre}<br></br>
            {userOffre?.offre?.sousTitre}
          </Typography>
          <Typography variant={'body2'}>
          {t('profile.price')} :{' '}
            {(
              userOffre?.offre?.prix *
              JSON.parse(sessionStorage.getItem('currency')).currentRate
            ).toFixed(2)}{' '}
            {JSON.parse(sessionStorage.getItem('currency')).devise}
          </Typography>
        </Box>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
            >
              <StripeAppTest
                userId={userId}
                offreId={userOffre?.id}
                offrePrice={(
                  userOffre?.offre?.prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}
                onSuccess={onSuccess}
                onClose={onClose}
              />
            </Grid>
          </Grid>
        </BoxContainer>
      </ModalContainer>
    </Modal>
  )
}

export default PaymentModal
