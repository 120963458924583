import React from 'react'
import { AdminHeading } from '../../../components/headings'
import { Box, styled, Typography } from '@mui/material'
import { BorderButton } from '../../../components/buttons'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import lightTheme from '../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Menu = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 75,
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  zIndex: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '25px 30px',
}))

const MenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
}))

const TitleMenu = styled(Typography)(({ theme }) => ({
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const MenuDashboard = () => {
  let navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)

  return (
    <div style={{ width: '100%' }}>
      <AdminHeading displayIconReturn={'none'}>Paramètres</AdminHeading>

      <Box
        sx={{
          width: 'calc(100% - 50px)',
          height: '100%',
          margin: {
            xs: '75px 25px 25px 25px',
            sm: '105px 25px 25px 25px',
            lg: '95px 25px 25px 25px',
          },
        }}
      >
        <MenuContainer>
          <Menu>
            <TitleMenu variant={'subtitle2'}>Mon Profil</TitleMenu>
            <BorderButton
              type={'basic'}
              displayIconLeft={'none'}
              width={{ xs: 100, sm: 125 }}
              height={45}
              icon={
                <FontAwesomeIcon
                  icon={faEye}
                  className="arrowLeft"
                  color={lightTheme.palette.primary.b2}
                />
              }
              handleClick={() => navigate('mon-profil')}
            >
              Accéder
            </BorderButton>
          </Menu>
          {userData.data.role === 'ROLE_SUPER_ADMIN' && (
            <Menu>
              <TitleMenu variant={'subtitle2'}>
                Gestion des administrateurs
              </TitleMenu>
              <BorderButton
                type={'basic'}
                displayIconLeft={'none'}
                width={{ xs: 100, sm: 125 }}
                height={45}
                icon={
                  <FontAwesomeIcon
                    icon={faEye}
                    className="arrowLeft"
                    color={lightTheme.palette.primary.b2}
                  />
                }
                handleClick={() => navigate('gestion-admins')}
              >
                Accéder
              </BorderButton>
            </Menu>
          )}
          {(userData.data.role === 'ROLE_SUPER_ADMIN' ||
            userData.data.role === 'ROLE_ADMIN') && (
            <Menu>
              <TitleMenu variant={'subtitle2'}>
                Gestion des Commerciaux
              </TitleMenu>
              <BorderButton
                type={'basic'}
                displayIconLeft={'none'}
                width={{ xs: 100, sm: 125 }}
                height={45}
                icon={
                  <FontAwesomeIcon
                    icon={faEye}
                    className="arrowLeft"
                    color={lightTheme.palette.primary.b2}
                  />
                }
                handleClick={() => navigate('gestion-commerciaux')}
              >
                Accéder
              </BorderButton>
            </Menu>
          )}

          {/* <Menu>
            <TitleMenu variant={'subtitle2'}>Gestion des données</TitleMenu>
            <BorderButton
              type={'basic'}
              displayIconLeft={'none'}
              width={{ xs: 100, sm: 125 }}
              height={45}
              icon={
                <FontAwesomeIcon
                  icon={faEye}
                  className="arrowLeft"
                  color={lightTheme.palette.primary.b2}
                />
              }
              handleClick={() => navigate('gestion-donnees')}
            >
              Accéder
            </BorderButton>
          </Menu> */}
        </MenuContainer>
      </Box>
    </div>
  )
}

export default MenuDashboard
