import { Routes, Route } from "react-router-dom";
import { UserLayout } from "../components/layouts";
import UserDashboard from "../pages/Dashboard/User";
import UserProfile from "../pages/Profile/UserProfile";
import MatchingCandidate from "../pages/Dashboard/Candidate/MatchingCandidate";
import Candidate from "../pages/Dashboard/Candidate/Candidate";
import Meeting from "../pages/Dashboard/Meeting";
import FavoriteCandidats from "../pages/Dashboard/Candidate/FavoriteCandidats";

const UserRouter = () => {
  return (
    <Routes>
      <Route element={<UserLayout/>}>
        <Route index element={<UserDashboard/>} />
        <Route path={"mon-profil"} element={<UserProfile/>} />
        <Route path={"/candidat/matching/:besoinId"} element={<MatchingCandidate />}/>
        <Route path={"/candidat/favoris"} element={<FavoriteCandidats/>}/>
        <Route path={"/event/:eventId"} element={<UserDashboard />}/>
        <Route path={"/candidats"} element={<Candidate/>}/>
        <Route path={"/meeting/:meetingId"} element={<Meeting />}/>
      </Route>
    </Routes>
  )
}

export default UserRouter