import React, { useEffect, useState } from "react";
import { Box, Typography, Container, styled, Skeleton } from "@mui/material";
import Steps from "./Steps/index.js";
import { useTranslation } from "react-i18next";
import Api from "../../../services/api.service";
import { handlingErrors } from "../../../utils";
import { Markup } from "interweave";
import { useParams } from "react-router-dom";

const ContentBox = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "50px",

  [theme.breakpoints.up("lg")]: {
    width: "50%"
  },
}));

const ContentBody = styled(Box)(({theme}) => ({
  display: "none",
  flexDirection: "column",
  rowGap: "30px",

  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const Contact = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [contactFR, setContactFR] = useState([]);
  const [contactEN, setContactEN] = useState([]);


  useEffect(() => {
    getContactFr();
    getContactEn();
  }, [])

  const getContactFr = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getContenuFr({
        section: "contact"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setContactFR(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  };

  const getContactEn = async () => {
    setLoading(true);

    try {
      const result = await Api.siteVitrine.getContenuEn({
        section: "contact"
      });
      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur");
      }

      if(res.ok) {
        setContactEN(res.data);
      }

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: {xs: "column", lg: "row"},
        justifyContent: {xs: "center", lg: "space-between"},
        marginTop: {xs: "30px",md: "80px", lg: "50px"},
        rowGap: { xs: "30px" , md: "80px" }
      }}
    >
      <ContentBox>
        {i18n.language === "fr" ? (
          <>
            {loading ? (
              <>
                {contactFR.map((item, index) => (
                  <>
                    <Typography variant={"h1"} textAlign={{xs: "center", lg: "left"}}>
                      {item.titre}
                    </Typography>
                    <ContentBody>
                      <Typography variant={"h2"}>
                        {item.sousTitre}
                      </Typography>
                      <Typography>
                        <Markup content={item.detail} />
                      </Typography>
                    </ContentBody>
                  </>
                ))}
              </>
            ) : (
              <>
                <Skeleton animation={"wave"} variant={"text"} sx={{height: 24}} />
                <Skeleton animation={"wave"} variant={"text"} sx={{height: 24, width: "50%"}} />
                <div>
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14, width: "60%"}} />
                </div>
              </>
              )}
          </>
        ) : (
          <>
            {loading ? (
              <>
                {contactEN.map((item, index)=> (
                  <>
                    <Typography variant={"h1"} textAlign={{xs: "center", lg: "left"}}>
                      {item.titre}
                    </Typography>
                    <ContentBody>
                      <Typography variant={"h2"}>
                        {item.sousTitre}
                      </Typography>
                      <Typography>
                        <Markup content={item.detail} />
                      </Typography>
                    </ContentBody>
                  </>
                ))}
              </>
            ) : (
              <>
                <Skeleton animation={"wave"} variant={"text"} sx={{height: 24}} />
                <Skeleton animation={"wave"} variant={"text"} sx={{height: 24, width: "50%"}} />
                <div>
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14}} />
                  <Skeleton animation={"wave"} variant={"text"} sx={{height: 14, width: "60%"}} />
                </div>
              </>
              )}
          </>
          )}
      </ContentBox>
      <Box width={{ xs: "100%", lg: "50%" }} sx={{display: "flex", justifyContent: "flex-end"}}>
        <Steps />
      </Box>
    </Container>
  )
}

export default Contact;