import axios from "axios";
import { accountService } from "./account.service";
import { BASE_URL_UP } from "../config";

const Axios = axios.create({
  baseURL: BASE_URL_UP
})

/**
 * Intercepteur pour le token
 */
Axios.interceptors.request.use(request => {

  if(accountService.isLogged()) {
    request.headers.Authorization = 'Bearer '+accountService.getToken()
  }

  return request
})

export default Axios