import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Grid,
  styled,
  Box,
  Typography,
  CircularProgress,
  FormControlLabel,
  TextField,
  Autocomplete,
} from '@mui/material'
import lightTheme from '../../../../styles/theme/lightTheme'
import RegisterCard from '../../../../components/cards/RegisterCard'
import {
  ButtonRadioInput,
  RadioInputBasic,
  SelectInputBasic,
  TextInput,
} from '../../../../components/inputs'
import { CustomButton, BorderButton } from '../../../../components/buttons'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { userLogout } from '../../../../store/slices/userAuthReducer'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faFaceLaugh,
} from '@fortawesome/pro-regular-svg-icons'
import { useDispatch } from 'react-redux'
import '../../../../styles/components/_errors.scss'
import TextareaInputClassic from '../../../../components/inputs/TextareaInputClassic'
import '../../../../styles/components/_grids.scss'
import { useNavigate } from 'react-router-dom'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Checkbox from '@mui/material/Checkbox'
import { countries } from '../../../../data'

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  rowGap: '5px',
}))

const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [lightTheme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [lightTheme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const Steps = () => {
  const { t, i18n } = useTranslation()
  let navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(0)
  const [type, setType] = useState('entreprise')
  const [loading, setLoading] = useState(false)
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [err, setErr] = useState('')
  const dispatch = useDispatch()
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState('')
  const [fonctions, setFonctions] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('')
  const [value, setValue] = useState(dayjs(new Date()))
  const [slots, setSlots] = useState([])
  const [times, setTimes] = useState('')
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState(false)
  const [phoneValue, setPhoneValue] = useState()
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'France',
  })
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('Paris')
  const [open, setOpen] = React.useState(false)
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)

  const proSchema = yup.object({
    raisonSocial: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.companyName')),
    /*fonction: yup
      .string(t("errorMessage.string"))
      .required(t("errorMessage.function")),*/
    prenom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.firstname')),
    nom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.lastname')),
    email: yup
      .string(t('errorMessage.string'))
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.email')),
    telephone: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.number')),
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide."),*/
    adresse: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.address')),
    /* ville: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.city')), */
    codePostal: yup
      .number()
      .typeError(t('errorMessage.zipCodeValid'))
      .required(t('errorMessage.zipCode')),
    /* pays: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.country')), */
    /*offre: yup
      .string()
      .required(),*/
    besoin: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.needs')),
    acceptTermes: yup
      .boolean()
      .test('acceptTermes', t('errorMessage.terms'), (val) => {
        return val
      }),
    /*dateRdv: yup
      .string()
      .test("dateRdv", "You must be 18 years or older", function (value) {
        return moment().diff(moment(value, "YYYY-MM-DD"), "years") <= 1;
      })
      .required(),*/
    slot: yup
      .string()
      .test('slot', t('errorMessage.selectHour'), (val) => {
        return val
      })
      .nullable(),
  })

  const parSchema = yup.object({
    prenom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.firstname')),
    nom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.lastname')),
    email: yup
      .string(t('errorMessage.string'))
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.email')),
    telephone: yup.string().required(t('errorMessage.number')),
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide."),*/
    adresse: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.address')),
    /* ville: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.city')), */
    codePostal: yup
      .number()
      .typeError(t('errorMessage.typeNumber'))
      .required(t('errorMessage.zipCode')),
    /* pays: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.country')), */
    /*offre: yup
      .string()
      .required(),*/
    besoin: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.needs')),
    acceptTermes: yup
      .boolean()
      .test('acceptTermes', t('errorMessage.terms'), (val) => {
        return val
      }),
    /*dateRdv: yup
      .string()
      .test("dateRdv", "You must be 18 years or older", function (value) {
        return moment().diff(moment(value, "YYYY-MM-DD"), "years") <= 1;
      })
      .required(),*/
    slot: yup.string().test('slot', t('errorMessage.selectHour'), (val) => {
      return val
    }),
  })

  const defaultValue = {
    acceptTermes: false,
  }

  const {
    register,
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(type === 'entreprise' ? proSchema : parSchema),
    mode: 'onChange',
    defaultValue,
  })

  useEffect(() => {
    handleCountryChange(selectedCountry)
  }, ['selectedCountry'])

  const handleCountryChange = async (country) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.bebug(error)
    }
  }

  useEffect(() => {
    getFonctions()
  }, [])

  const nextStep = async () => {
    let isValidStepOne = false
    let isValidStepTwo = false
    let isValidStepTree = false
    let isValidStepFour = false
    switch (currentStep) {
      case 0:
        if (type === 'entreprise') {
          isValidStepOne = await trigger([
            'raisonSocial',
            'fonction',
            'prenom',
            'nom',
            'email',
          ])
        } else {
          isValidStepOne = await trigger([
            'prenom',
            'nom',
            'email',
            'telephone',
          ])
        }
        break

      case 1:
        if (type === 'entreprise') {
          isValidStepTwo = await trigger([
            'telephone',
            //'pays',
            'adresse',
            //'ville',
            'codePostal',
          ])
        } else {
          isValidStepTwo = await trigger([
            'adresse',
            //'ville',
            'codePostal',
            //'pays',
          ])
        }
        break

      case 2:
        isValidStepTree = await trigger(['besoin', 'acceptTermes'])
        break

      case 3:
        isValidStepFour = true
        break

      default:
        break
    }

    if (isValidStepOne) {
      setCurrentStep(1)
    } else {
      if (isValidStepTwo) {
        setCurrentStep(2)
      } else {
        if (isValidStepTree) {
          setCurrentStep(3)
        }
      }
    }
  }

  const handleChangeRadio = (inputValue) => {
    setType(inputValue)
  }

  const handlePosteSelect = (event) => {
    const inputValue = event.target.value
    setPosteSelect(inputValue)
  }

  const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  }

  const [role, setRole] = useState(null)

  useEffect(() => {
    getOffers()
    getPostes()

    if (type === 'entreprise') {
      setRole('ROLE_ENTREPRISE')
    } else {
      setRole('ROLE_CANDIDAT')
    }
  }, [type])

  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie: type === 'entreprise' ? 'entreprise' : 'candidat',
      })

      setOffres(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setOffres(res)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const getFonctions = async () => {
    try {
      let result = await Api.fonction.listWithFilter({
        status: true,
      })
      setFonctions(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setFonctions(res)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)
    try {
      let result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })
      setPostes(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingPoste(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingPoste(false)
        setPostes(res)
      } else {
        setLoadingPoste(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingPoste(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    getDateDisponible()
  }, [value])

  const DateValue = format(new Date(value), 'yyyy-MM-dd')

  const getDateDisponible = async () => {
    const DateFormat = format(new Date(value), 'yyyy-MM-dd')

    try {
      let result = await Api.calendar.listPerDate(DateFormat)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data

        const DateValue = format(new Date(value), 'dd/MM/yyyy')

        setSlots(res[DateValue])
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const handleChangeButtonRadio = (inputValue) => {
    setTimes(inputValue)
  }

  const [addHour, setAddHour] = useState('')

  useEffect(() => {
    addMinutes()
  }, [times])

  const addMinutes = () => {
    const dureeRdv = 30
    const calculSlot = Number(times.split(':')[1]) + Number(dureeRdv)
    const formattedEndHours = calculSlot >= 60 ? calculSlot - 60 : calculSlot
    const splitSlot = Number(times.split(':')[1])

    if (formattedEndHours === 0) {
      const calculSlot = Number(times.split(':')[0]) + Number(1)
      const end = calculSlot.toString().concat(':').concat('00:00')
      setAddHour(end)
    } else if (formattedEndHours === 15) {
      const calculSlot = Number(times.split(':')[0]) + Number(1)
      const end = calculSlot
        .toString()
        .concat(':')
        .concat(formattedEndHours)
        .concat(':00')
      setAddHour(end)
    } else {
      const hour = Number(times.split(':')[0])
      const end = hour
        .toString()
        .concat(':')
        .concat(formattedEndHours)
        .concat(':00')
      setAddHour(end)
    }
  }

  const onSubmit = async (data) => {
    setErr('')
    setLoading(true)

    //return
    const obj = {
      ...data,
      roles: [role],
      password: 'userPass',
      pays: selectedCountry.label,
      ville: selectedCity,
      /* userOffres: offreSelect, */
      status: 'contact',
      entreprise:
        type === 'entreprise'
          ? {
              besoin: data.besoin,
              /*fonction: data.fonction,*/
              fonctions: `/api/fonctions/${fonctionSelect}`,
              raisonSocial: data.raisonSocial,
              calendriers: [
                {
                  dateDebut: `${DateValue}T${times}`,
                  dateFin: `${DateValue}T${addHour}`,
                  type: 'rdv_decouverte',
                  detail: 'Rendez-vous découverte',
                  statusAdmin: 'demande',
                },
              ],
            }
          : null,
      candidat:
        type === 'candidat'
          ? {
              besoin: data.besoin,
              //disponible: '2022-10-01T09:00:00',
              mobilite: 50,
              poste: `/api/postes/${posteSelect.id}`,
              techno: null,
              calendriers: [
                {
                  dateDebut: `${DateValue}T${times}`,
                  dateFin: `${DateValue}T${addHour}`,
                  type: 'rdv_decouverte',
                  detail: 'Rendez-vous découverte',
                  statusAdmin: 'demande',
                },
              ],
            }
          : null,
    }

    const result = await Api.user.add(obj)
    const handling = handlingErrors(result)

    if (handling.status === 401) {
      dispatch(userLogout())
      return
    }
    if (handling.ok) {
      const res = handling.data

      setId(res.id)
      setRole(res.role)
      setOpenCard(true)
      setLoading(false)
    } else {
      if (handling.status === 422) {
        toast.error(t('successMessage.emailAlreadyUsed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      } else {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
      setLoading(false)
    }
  }

  return (
    <>
      <RegisterCard>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}
        >
          <Grid container spacing={2} className={'gridContainerLogin'}>
            {!openCard ? (
              <>
                {currentStep === 0 && (
                  <>
                    <Grid item xs={12} sm={6} lg={12}>
                      <CustomFormControl fullWidth>
                        <CustomFormLabel>{t('contact.iAm')}</CustomFormLabel>
                        <Box
                          sx={{
                            display: 'flex',
                            columnGap: '20px',
                            marginTop: '10px',
                          }}
                        >
                          <RadioInputBasic
                            value={'entreprise'}
                            label={t(`contact.company`)}
                            isChecked={type === 'entreprise'}
                            handleChange={handleChangeRadio}
                          />
                          <RadioInputBasic
                            value={'candidat'}
                            label={t(`contact.candidate`)}
                            isChecked={type === 'candidat'}
                            handleChange={handleChangeRadio}
                          />
                        </Box>
                      </CustomFormControl>
                    </Grid>
                    {type === 'entreprise' && (
                      <>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.companyName`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.companyName')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('raisonSocial') }}
                            />
                            {errors.raisonSocial && (
                              <Typography className={'error-message'}>
                                {errors.raisonSocial.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.function`)}
                            </CustomFormLabel>
                            {/*<TextInput
                              placeholder={t('contact.function')}
                              width={"100%"}
                              type={"text"}
                              register={{...register("fonction")}}
                            />*/}

                            {/*<SelectInputBasic
                              items={fonctions}
                              value={fonctionSelect}
                              handleChange={handleFonctionSelect}
                              register={{...register("fonction")}}
                            />*/}

                            {/*<SelectInputCustom
                              placeholder={"Fontion"}
                              items={fonctions}
                              register={{...register("fonction")}}
                            />*/}

                            <SelectInputBasic
                              items={fonctions}
                              value={fonctionSelect}
                              handleChange={handleFonctionSelect}
                            />

                            {errors.fonction && (
                              <Typography className={'error-message'}>
                                {errors.fonction.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.firstName`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.firstName')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('prenom') }}
                            />
                            {errors.prenom && (
                              <Typography className={'error-message'}>
                                {errors.prenom.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.lastName`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.lastName')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('nom') }}
                            />
                            {errors.nom && (
                              <Typography className={'error-message'}>
                                {errors.nom.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.email`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.email')}
                              width={'100%'}
                              type={'email'}
                              register={{ ...register('email') }}
                            />
                            {errors.email && (
                              <Typography className={'error-message'}>
                                {errors.email.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}

                    {type === 'candidat' && (
                      <>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.firstName`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.firstName')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('prenom') }}
                            />
                            {errors.prenom && (
                              <Typography className={'error-message'}>
                                {errors.prenom.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.lastName`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.lastName')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('nom') }}
                            />
                            {errors.nom && (
                              <Typography className={'error-message'}>
                                {errors.nom.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.email`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.email')}
                              width={'100%'}
                              type={'email'}
                              register={{ ...register('email') }}
                            />
                            {errors.email && (
                              <Typography className={'error-message'}>
                                {errors.email.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.number`)}
                            </CustomFormLabel>
                            <Controller
                              name="phone-input"
                              control={control}
                              rules={{
                                validate: (value) => isValidPhoneNumber(value),
                              }}
                              render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                  international
                                  value={phoneValue}
                                  onChange={setPhoneValue}
                                  defaultCountry="CH"
                                  id="phone-input"
                                  {...register('telephone')}
                                />
                              )}
                            />

                            {errors.telephone && (
                              <Typography className={'error-message'}>
                                {errors.telephone.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {currentStep === 1 && (
                  <>
                    {type === 'entreprise' && (
                      <>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.number`)}
                            </CustomFormLabel>
                            <Controller
                              name="phone-input"
                              control={control}
                              rules={{
                                validate: (value) => isValidPhoneNumber(value),
                              }}
                              render={({ field: { onChange, value } }) => (
                                <PhoneInput
                                  international
                                  value={phoneValue}
                                  onChange={setPhoneValue}
                                  defaultCountry="CH"
                                  id="phone-input"
                                  {...register('telephone')}
                                />
                              )}
                            />

                            {errors.telephone && (
                              <Typography className={'error-message'}>
                                {errors.telephone.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.country`)}
                            </CustomFormLabel>
                            <Autocomplete
                              disableClearable
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              value={selectedCountry ? selectedCountry : {}}
                              onChange={(event, newValue) => {
                                handleCountryChange(newValue)
                              }}
                              id="country-select"
                              options={countries}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label} ({option.code})
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.address`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.address')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('adresse') }}
                            />
                            {errors.adresse && (
                              <Typography className={'error-message'}>
                                {errors.adresse.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.city`)}
                            </CustomFormLabel>
                            <Autocomplete
                              disableClearable
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              value={selectedCity ? selectedCity : {}}
                              onChange={(event, newValue) => {
                                setSelectedCity(newValue)
                              }}
                              id="country-select"
                              options={cities}
                              autoHighlight
                              //getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.zipCode`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.zipCode')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('codePostal') }}
                            />
                            {errors.codePostal && (
                              <Typography className={'error-message'}>
                                {errors.codePostal.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}

                    {type === 'candidat' && (
                      <>
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.job`)}
                            </CustomFormLabel>
                            <Autocomplete
                              disableClearable
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              id="asynchronous-postes"
                              open={open}
                              onChange={(event, newValue) => {
                                setPosteSelect(newValue)
                              }}
                              onKeyDown={(event, newInputValue) => {
                                clearTimeout(typingTimer)
                                if (event.target.value) {
                                  typingTimer = setTimeout(function () {
                                    getPostes(event.target.value)
                                  }, doneTypingInterval)
                                }
                              }}
                              onOpen={() => {
                                setOpen(true)
                              }}
                              onClose={() => {
                                setOpen(false)
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) => option.titre}
                              options={postes}
                              loading={loadingPoste}
                              renderInput={(params) => (
                                <TextField
                                  id="text-asynchronous-postes"
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loadingPoste ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.address`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.address')}
                              width={'100%'}
                              type={'text'}
                              register={{ ...register('adresse') }}
                            />
                            {errors.adresse && (
                              <Typography className={'error-message'}>
                                {errors.adresse.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.city`)}
                            </CustomFormLabel>
                            <Autocomplete
                              disableClearable
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              value={selectedCity ? selectedCity : {}}
                              onChange={(event, newValue) => {
                                setSelectedCity(newValue)
                              }}
                              id="country-select"
                              options={cities}
                              autoHighlight
                              //getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.zipCode`)}
                            </CustomFormLabel>
                            <TextInput
                              placeholder={t('contact.zipCode')}
                              width={'100%'}
                              type={'tel'}
                              register={{ ...register('codePostal') }}
                            />
                            {errors.codePostal && (
                              <Typography className={'error-message'}>
                                {errors.codePostal.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.country`)}
                            </CustomFormLabel>
                            <Autocomplete
                              disableClearable
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              value={selectedCountry ? selectedCountry : {}}
                              onChange={(event, newValue) => {
                                handleCountryChange(newValue)
                              }}
                              id="country-select"
                              options={countries}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label} ({option.code})
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                  }}
                                />
                              )}
                            />
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    {type === 'entreprise' && (
                      <>
                        {/*<Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>{t(`contact.offerInterested`)}</CustomFormLabel>
                            <SelectInputBasic
                              items={offres}
                              value={offreSelect}
                              handleChange={handleChangeSelect}
                            />
                            {errors.offre && (
                              <Typography className={"error-message"}>
                                {errors.offre.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>*/}
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.need`)}
                            </CustomFormLabel>
                            <TextareaInputClassic
                              placeholder={t(`contact.text`)}
                              register={{ ...register('besoin') }}
                            />
                            {errors.besoin && (
                              <Typography className={'error-message'}>
                                {errors.besoin.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <FormControlLabel
                              sx={{
                                margin: 0,
                                display: 'flex',
                              }}
                              control={
                                <Controller
                                  name="acceptTermes"
                                  control={control}
                                  render={({ field }) => (
                                    <Checkbox {...field} />
                                  )}
                                />
                              }
                              label={t(`contact.terms`)}
                            />
                            {errors.acceptTermes && (
                              <Typography className={'error-message'}>
                                {errors.acceptTermes.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}

                    {type === 'candidat' && (
                      <>
                        {/*<Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>{t(`contact.offerInterested`)}</CustomFormLabel>
                            <SelectInputBasic
                              items={offres}
                              value={offreSelect}
                              handleChange={handleChangeSelect}
                            />
                          </CustomFormControl>
                        </Grid>*/}
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <CustomFormLabel>
                              {t(`contact.need`)}
                            </CustomFormLabel>
                            <TextareaInputClassic
                              placeholder={t(`contact.text`)}
                              register={{ ...register('besoin') }}
                            />
                            {errors.besoin && (
                              <Typography className={'error-message'}>
                                {errors.besoin.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <CustomFormControl fullWidth>
                            <FormControlLabel
                              sx={{
                                margin: 0,
                                display: 'flex',
                              }}
                              control={
                                <Controller
                                  name="acceptTermes"
                                  control={control}
                                  render={({ field }) => (
                                    <Checkbox {...field} />
                                  )}
                                />
                              }
                              label={t(`contact.terms`)}
                            />
                            {errors.acceptTermes && (
                              <Typography className={'error-message'}>
                                {errors.acceptTermes.message}
                              </Typography>
                            )}
                          </CustomFormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <Grid item xs={12} sm={12} lg={12}>
                      <CustomFormControl>
                        <CustomFormLabel>{t(`contact.date`)}</CustomFormLabel>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={'fr'}
                        >
                          <DatePicker
                            label="Custom input"
                            disablePast
                            inputFormat={'DD/MM/YYYY'}
                            value={value}
                            onChange={(newValue) => {
                              setValue(newValue)
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '45px',
                                  backgroundColor:
                                    lightTheme.palette.primary.b2,
                                  borderRadius: '5px',

                                  '& .css-1laqsz7-MuiInputAdornment-root': {
                                    height: '100%',
                                    width: '50px',
                                    margin: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                                      {
                                        margin: 0,
                                        color: '#FFFFFF',
                                      },
                                  },
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '5px 0 0 5px',
                                    border: 'none',
                                    padding: '10px 15px',
                                    fontFamily: 'Century Gothic',
                                    fontSize: 14,
                                    lineHeight: '140%',
                                    letterSpacing: '0.2px',
                                    color: lightTheme.palette.text.primary,
                                    backgroundColor: '#FFFFFF',
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                        {errors.date && (
                          <Typography className={'error-message'}>
                            {errors.date.message}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <CustomFormControl>
                        <CustomFormLabel>{t(`contact.slots`)}</CustomFormLabel>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            rowGap: '20px',
                            height: 280,
                            width: '100%',
                            overflowY: 'scroll',

                            '&::-webkit-scrollbar': {
                              width: 10,
                            },

                            '&::-webkit-scrollbar-track': {
                              background: lightTheme.palette.primary.b5,
                            },

                            '&::-webkit-scrollbar-thumb': {
                              background: lightTheme.palette.primary.b3,
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                              background: lightTheme.palette.primary.b2,
                            },
                          }}
                        >
                          {slots.map((slot) => {
                            return (
                              <ButtonRadioInput
                                value={slot}
                                handleChange={handleChangeButtonRadio}
                                width={'calc(50% - 10px)'}
                                register={{ ...register('slot') }}
                              >
                                {slot.replace(/:/g, 'h').substr(0, 5)}
                              </ButtonRadioInput>
                            )
                          })}
                        </Box>
                        {errors.slot && (
                          <Typography className={'error-message'}>
                            {errors.slot.message}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    rowGap: '50px',
                  }}
                >
                  <Box
                    sx={{
                      width: 125,
                      height: 125,
                      borderRadius: '50%',
                      background: lightTheme.palette.gradients.gradientGreen,
                      position: 'relative',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFaceLaugh}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: 126,
                        color: '#FFFFFF',
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{
                      fontFamily: 'Century Gothic Bold',
                      fontSize: { xs: 20, lg: 20 },
                      lineHeight: '120%',
                      color: lightTheme.palette.text.primary,
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    {t(`contact.thanks`)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '20px',
                    }}
                  >
                    <Typography>{t(`contact.sendEmail`)}</Typography>
                    <Typography>{t(`contact.sendEmail2`)}</Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '20px',
              justifyContent: openCard ? 'center' : 'flex-end',
            }}
          >
            {currentStep > 0 && !openCard && (
              <Box width={{ xs: '50%', md: 'auto', lg: '50%' }}>
                <BorderButton
                  width={{ md: 180, lg: '100%' }}
                  type={'basic'}
                  displayIconRight={'none'}
                  handleClick={() => setCurrentStep(currentStep - 1)}
                >
                  {t('contact.prevStep')}
                </BorderButton>
              </Box>
            )}
            <Box
              width={{ xs: '50%', md: 'auto', lg: openCard ? '100%' : '50%' }}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {!openCard ? (
                    <>
                      {currentStep === 0 ||
                      currentStep === 1 ||
                      currentStep === 2 ? (
                        <CustomButton
                          handleClick={nextStep}
                          width={{ md: 180, lg: '100%' }}
                        >
                          {t('contact.nextStep')}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          handleClick={handleSubmit(onSubmit)}
                          width={{ md: 180, lg: '100%' }}
                          alignSelf={'end'}
                        >
                          {t('contact.send')}
                        </CustomButton>
                      )}
                    </>
                  ) : (
                    <CustomButton
                      displayIcon={'none'}
                      width={{ md: 180, lg: '100%' }}
                      alignSelf={'end'}
                      handleClick={() => navigate('/')}
                    >
                      {t('contact.return')}
                    </CustomButton>
                  )}
                </>
              )}
            </Box>
          </Box>
        </form>
      </RegisterCard>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default Steps
