import ApiRequest from "../request/ApiRequest";

export default class Actions {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("actions", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("actions");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`actions/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`actions/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`actions/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("actions.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }


}