import React, { useEffect, useState } from 'react'
import { Box, Pagination } from '@mui/material'

const AppPagination = ({ handlePageChange, count, page, pageSize = 10 }) => {

  useEffect(() => {
  }, [])

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Pagination
        defaultPage={page} 
        siblingCount={0}
        color={'primary'}
        count={Math.ceil(count / pageSize)}
        onChange={handlePageChange}
      />
    </Box>
  )
}

export default AppPagination
