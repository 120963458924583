import { ProfilAdmin } from "../Admin/Admin";
import { ProspectCompanyProfil } from "../Admin/User/Prospect/CompanyProspect";
import { ProspectCandidateProfil } from "../Admin/User/Prospect/CandidateProspect";

const Profile = ({role, id}) => {

  const profilLayout = () => {
    switch (role) {
      case "ROLE_SUPER_ADMIN" || "ROLE_ADMIN":
        return <ProfilAdmin id={id} />;
      case "ROLE_ENTREPRISE":
        return <ProspectCompanyProfil id={id}/>;
      case "ROLE_CANDIDAT":
        return <ProspectCandidateProfil id={id} />
      default:
        return <ProfilAdmin id={id}/>;
    }
  }

  return (
    <>
      {profilLayout()}
    </>
  )
}

export default Profile;