import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { CssBaseline, ThemeProvider } from "@mui/material";
import lightTheme from "./styles/theme/lightTheme";
import AppNavigation from "./navigation/AppNavigation";
import { Provider } from "react-redux";
import store from "./store";


i18next.use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: '/translations/{{ lng }}/translations.json'
    },
    lng: 'fr',
    fallbackLng: 'fr'
  })



/*function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<Provider store={store}>*/
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AppNavigation />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
  /*</Provider>*/
);

