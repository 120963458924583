import React from "react";
import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";


const CustomInput = styled('input')(({theme, width}) => ({
  height: 45,
  width: width ? width : "100%",
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: "none",
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.grey.main,
  padding: "10px",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 10,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.grey.main,
  },

  "&:active, &:focus": {
    outline: "none"
  },


  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,

    "&::placeholder": {
      fontSize: 12,
    },
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontSize: 14,

    "&::placeholder": {
      fontSize: 14,
    },
  },

}));

const TextInput = ({placeholder, width, register, type, handleChange}) => {
  return (
      <CustomInput
        placeholder={placeholder}
        width={width}
        type={type ? type : "text"}
        autoComplete={"off"}
        required
        {...register}
        onChange={handleChange}
      />
  )
}

export default TextInput;