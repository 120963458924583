import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { ListTable } from '../../../../components/tables'
import { AdminHeading } from '../../../../components/headings'
import {
  SearchInputBasic,
  SelectInputObject,
} from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCirclePlus } from '@fortawesome/pro-regular-svg-icons'
import { DeleteProfilModal, FiltersModal } from '../../../../components/modals'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import AppPagination from '../../../../components/paginations'
import { debounce } from '../../../../config'
import { useTranslation } from 'react-i18next'
import { BorderButtonPurple } from '../../../../components/buttons'
import { useSelector } from 'react-redux'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const Candidate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  const [open, setOpen] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [id, setId] = useState(null)
  const [search, setSearch] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()
  const [page, setPage] = useState(
    sessionStorage.getItem('pageCandidat')
      ? parseInt(sessionStorage.getItem('pageCandidat'))
      : 1
  )
  const [count, setCount] = useState(0)
  const [filter, setFilter] = useState([
    { id: 0, titre: t('filter.place'), filtre: 'ville' },
    { id: 1, titre: t('filter.poste'), filtre: 'candidat.poste.titre' },
    { id: 1, titre: t('filter.competences'), filtre: 'candidat.competences' },
    { id: 2, titre: t('filter.softskils'), filtre: 'candidat.softskils' },
    { id: 3, titre: t('filter.values'), filtre: 'candidat.valeurs' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  useEffect(() => {
    getCandidates(page)
  }, [id, search])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getCandidates(page)
    sessionStorage.setItem('pageCandidat', page)
  }

  const getCandidates = async (page) => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)
    if (filterSelect.id != 0) {
      payload = {
        [filterSelect.filtre]: search,
      }
    }

    try {
      const result = await Api.user.listWithPaginationAndFilter({
        /*status: "active",*/
        role: 'ROLE_CANDIDAT',
        status: 'active',
        //entreprises: (userData.data.role === 'ROLE_COMMERCIAL') ? userData.data.id : '',
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCandidates(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-client/profil-client-candidat/${userId}`)
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setOpenDeleteModal(false)
        const indexCandidate = candidates.findIndex(
          (candidate) => candidate.id === userId
        )
        if (indexCandidate > -1) {
          candidates.splice(indexCandidate, 1)
        }
        setCandidates(candidates)
        setLoadingDelete(false)
      }

      if (res.status === 0) {
        getCandidates(page)
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getCandidates(page)
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading displayIconReturn={'none'}>
          {t('profile.candidatListTitle')}
        </AdminHeading>

        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <SearchInputBasic
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-candidat/client')
                }
              >
                Ajouter un candidat
              </BorderButtonPurple>
            </BlockButton>
          </WrapperButton>
          <ListTable
            type={'candidat'}
            items={candidates}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>

      <FiltersModal open={open} onClose={handleClose} />
      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />
    </>
  )
}

export default Candidate
