import React, { useEffect, useState } from "react";
import { Box, Container, Skeleton, styled, Typography } from "@mui/material";
import {DoubleRoundedImage} from "../../../../../components/images";
import {CustomButton} from "../../../../../components/buttons";
import lightTheme from "../../../../../styles/theme/lightTheme";
import {CircleLayout} from "../../../../../components/layouts";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { contentsService } from "../../../../../services/contents.service";
import { contentsOptions } from "../../../../../services/request";
import { Markup } from "interweave";
import smallLogo from "../../../../../assets/images/logos/smallLogo.svg";
import "../../../../../styles/components/_skeleton.scss";

const LabelButton = styled(Typography)(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  color: lightTheme.palette.grey.dark,
  textTransform: "uppercase",
  letterSpacing: "0.2px"
}));

const SkeletonContainer = styled(Box)(({theme}) => ({
  display: "flex",
  position: "relative",
  width: 70,
  height: 35,

  ".firstCircular": {
    position: "absolute",
    left: "5%",
  },

  ".secondCircular": {
    position: "absolute",
    right: "5%"
  },

  ".first": {
    alignSelf: "start",
    alignItems: "start"
  },

  ".second": {
    alignSelf: "center",
    alignItems: "center"
  },

  ".third": {
    alignSelf: "end",
    alignItems: "start"
  },

  [theme.breakpoints.up("sm")]: {
    width: 160,
    height: 80
  },
  [theme.breakpoints.up("lg")]: {
    width: 200,
    height: 100,
  },

}));

const LogoContainer = styled(Box)(({theme}) => ({
  width: 18,
  height: 18,
  position: "absolute",
  top: "-24%",
  left: "40%",
  zIndex: 1,

  img: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },

  [theme.breakpoints.up("sm")]: {
    width: 38,
    height: 38,
  },
  [theme.breakpoints.up("lg")]: {
    width: 47,
    height: 47,
  },
}))

const SkeletonCircle = styled(Skeleton)(({theme}) => ({
  width: 35,
  height: 35,

  [theme.breakpoints.up("sm")]: {
    width: 80,
    height: 80,
  },

  [theme.breakpoints.up("lg")]: {
    width: 100,
    height: 100,
  },
}))

export default function RecruitmentSection () {
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const [ceoSections, setCeoSections] = useState([]);

  useEffect(() => {
    contentsService.getAllContents(contentsOptions)
      .then(res => {
        const response = res.data;
        const dataCeoSection = (response.filter(data => data.section === "section-fondateur"));
        setCeoSections(dataCeoSection);
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <Box
      component={"section"}
      sx={{
        position: "relative",
      }}
    >
      <CircleLayout
        left={{xs: "-53%", sm: "-33%", lg: "-28%"}}
        top={{lg: "9%"}}
      />
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "60px",
          paddingLeft: {xs: 0 },
          paddingRight: {xs: 0 }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: {sm: "100px"},
            width: {xs: "100%", lg: "95%" },
            alignSelf: "end"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: {xs: 200, sm: 380, lg: 445},
              width: {xs : "100%", sm : "85%", lg: "100%"},
              alignSelf: {xs: "center", sm: "end"}
            }}
          >
            <Box
              sx={{
                width: {xs: "40%", sm: "30%"},
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {i18n.language === "fr" ? (
                <>
                  {ceoSections
                    .filter(ceoSection => ceoSection.lang === "fr")
                    .map((ceoSection, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          position: "relative",
                        }}
                      >
                        {!ceoSection.photos[0]
                          && !ceoSection.photos[1]
                          && !ceoSection.photos[2]
                          && !ceoSection.photos[3]
                          && !ceoSection.photos[4]
                          && !ceoSection.photos[5] ? (
                          <>
                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: 0,
                                top: 0
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>

                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: "17%",
                                top: "39%"
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>

                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: 0,
                                bottom: 0
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>
                          </>
                        ) : (
                          <>
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[0].lien}
                              picture2={ceoSection.photos[1].lien}
                              alignSelf={"start"}
                              alignItems={"start"}
                            />
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[2].lien}
                              picture2={ceoSection.photos[3].lien}
                              alignSelf={"center"}
                              alignItems={"center"}
                            />
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[4].lien}
                              picture2={ceoSection.photos[5].lien}
                              alignSelf={"end"}
                              alignItems={"start"}
                            />
                          </>
                        )}
                      </Box>
                    ))}
                </>
              ):(
                <>
                  {ceoSections
                    .filter(ceoSection => ceoSection.lang === "en")
                    .map((ceoSection, index) => (
                      <Box
                        key={index}
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          position: "relative"
                        }}
                      >
                        {!ceoSection.photos[0]
                          && !ceoSection.photos[1]
                          && !ceoSection.photos[2]
                          && !ceoSection.photos[3]
                          && !ceoSection.photos[4]
                          && !ceoSection.photos[5] ? (
                          <>
                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: 0,
                                top: 0
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>

                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: "17%",
                                top: "39%"
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>

                            <SkeletonContainer
                              sx={{
                                position: "absolute",
                                left: 0,
                                bottom: 0
                              }}
                            >
                              <LogoContainer>
                                <img src={smallLogo} alt={"Hcub3 Cvtheque"}/>
                              </LogoContainer>
                              <SkeletonCircle variant={"circular"} className={"firstCircular"}/>
                              <SkeletonCircle variant={"circular"} className={"secondCircular"}/>
                            </SkeletonContainer>
                          </>
                        ) : (
                          <>
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[0].lien}
                              picture2={ceoSection.photos[1].lien}
                              alignSelf={"start"}
                              alignItems={"start"}
                            />
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[2].lien}
                              picture2={ceoSection.photos[3].lien}
                              alignSelf={"center"}
                              alignItems={"center"}
                            />
                            <DoubleRoundedImage
                              picture1={ceoSection.photos[4].lien}
                              picture2={ceoSection.photos[5].lien}
                              alignSelf={"end"}
                              alignItems={"start"}
                            />
                          </>
                        )}
                      </Box>
                    ))}
                </>
                )}
            </Box>

            <Box
              sx={{
                width: {xs: "60%", sm: "70%"},
                display: "flex",
                flexDirection: "column",
                rowGap: {sm: "50px"}
              }}
            >
              {i18n.language === "fr" ? (
                <>
                  {ceoSections
                    .filter(ceoSection => ceoSection.lang === "fr")
                    .map((ceoSection, index) => (
                      <Box
                        sx={{
                          /*width: {xs: "60%", sm: "70%"},*/
                          display: "flex",
                          flexDirection: "column",
                          rowGap: {sm: "50px"}
                        }}
                        key={index}
                      >

                        {!ceoSection.titre ? (
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                        ) : (
                          <Typography
                            variant={"h3"}
                            textTransform={"uppercase"}
                            sx={{
                              textAlign: "left"
                            }}
                          >
                            {ceoSection.titre}
                          </Typography>
                          )}




                        <Box
                          sx={{
                            display: {xs: "none", sm: "flex"},
                            flexDirection: "column",
                            rowGap: "20px"
                          }}
                        >
                          {!ceoSection.detail ? (
                            <Box width={"100%"}>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                              <br/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                              <br/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                            </Box>
                          ) : (
                            <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>
                              <Markup content={ceoSection.detail} />
                            </Typography>
                            )}
                        </Box>
                      </Box>
                    ))}
                </>
              ):(
                <>
                  {ceoSections
                    .filter(ceoSection => ceoSection.lang === "en")
                    .map((ceoSection, index) => (
                      <Box
                        sx={{
                          /*width: {xs: "60%", sm: "70%"},*/
                          display: "flex",
                          flexDirection: "column",
                          rowGap: {sm: "50px"}
                        }}
                        key={index}
                      >

                        {!ceoSection.titre ? (
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
                        ) : (
                          <Typography
                            variant={"h3"}
                            textTransform={"uppercase"}
                            sx={{
                              textAlign: "left"
                            }}
                          >
                            {ceoSection.titre}
                          </Typography>
                          )}

                        <Box
                          sx={{
                            display: {xs: "none", sm: "flex"},
                            flexDirection: "column",
                            rowGap: "20px"
                          }}
                        >

                          {!ceoSection.detail ? (
                            <Box width={"100%"}>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                              <br/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                              <br/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                            </Box>
                          ) : (
                            <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>
                              <Markup content={ceoSection.detail} />
                            </Typography>
                            )}
                        </Box>
                      </Box>
                    ))}
                </>
                )}
              <Box
                sx={{
                  display: {xs: "none", lg: "flex"},
                  justifyContent: "space-between",
                  flexDirection: {xs: "column", sm: "row"},
                  rowGap: "40px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "25px",
                    alignItems: "center"
                  }}
                >
                  <LabelButton>{t('home.searchJob')}</LabelButton>
                  <CustomButton displayIcon={"none"} width={300} handleClick={() => navigation("/candidats")}>{t('home.getCareer')}</CustomButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "25px",
                    alignItems: "center"
                  }}
                >
                  <LabelButton>{t('home.searchCandidat')}</LabelButton>
                  <CustomButton displayIcon={"none"} width={300} handleClick={() => navigation("/entreprise")}>{t('home.findCandidat')}</CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: {xs: "none", sm: "flex", lg: "none"},
              justifyContent: "space-around",
              flexDirection: {xs: "column", sm: "row"},
              rowGap: "40px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
                alignItems: "center"
              }}
            >
              <LabelButton>{t('home.searchJob')}</LabelButton>
              <CustomButton displayIcon={"none"} width={300} handleClick={() => navigation("/candidats")}>{t('home.getCareer')}</CustomButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
                alignItems: "center"
              }}
            >
              <LabelButton>{t('home.searchCandidat')}</LabelButton>
              <CustomButton displayIcon={"none"} width={300} handleClick={() => navigation("/entreprise")}>{t('home.findCandidat')}</CustomButton>
            </Box>
          </Box>


        </Box>

        <Box
          sx={{
            display: {xs: "flex", sm: "none"},
            flexDirection: "column",
            rowGap: "50px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px"
            }}
          >
            {i18n.language === "fr" ? (
              <>
                {ceoSections
                  .filter(ceoSection => ceoSection.lang === "fr")
                  .map((ceoSection, index) => (
                    <>

                      {!ceoSection.detail ? (
                        <Box width={"100%"}>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                          <br/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                          <br/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                        </Box>
                      ) : (
                        <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>
                          <Markup content={ceoSection.detail} />
                        </Typography>
                        )}

                    </>
                  ))}
              </>
            ):(
              <>
                {ceoSections
                  .filter(ceoSection => ceoSection.lang === "en")
                  .map((ceoSection, index) => (
                    <>

                      {!ceoSection.detail ? (
                        <Box width={"100%"}>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                          <br/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                          <br/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                        </Box>
                      ) : (
                        <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>
                          <Markup content={ceoSection.detail} />
                        </Typography>
                        )}

                    </>
                  ))}
              </>
              )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: {xs: "column", sm: "row"},
              rowGap: "40px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
                alignItems: "center"
              }}
            >
              <LabelButton>{t('home.searchJob')}</LabelButton>
              <CustomButton displayIcon={"none"} width={250} handleClick={() => navigation("/candidat")}>{t('home.getCareer')}</CustomButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "25px",
                alignItems: "center"
              }}
            >
              <LabelButton>{t('home.searchCandidat')}</LabelButton>
              <CustomButton displayIcon={"none"} width={250} handleClick={() => navigation("/entreprise")}>{t('home.findCandidat')}</CustomButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}