import ApiRequest from '../request/ApiRequest'

export default class SiteVitrine {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('utilisateurs', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`utilisateurs/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`utilisateurs/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get('utilisateurs')
      return res
    } catch (error) {
      return error.response
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`utilisateurs/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('sites.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listTemoignagesWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('temoignages.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getContenuFr(data) {
    try {
      const res = await this.ApiRequest.get('sites.json?lang=fr', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getContenuEn(data) {
    try {
      const res = await this.ApiRequest.get('sites.json?lang=en', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getOffers(data = {}, lang, categorie = '') {
    try {
      const res = await this.ApiRequest.get(
        'offres.json?lang='+lang+'&categorie=' + categorie,
        data
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async getOffersEn(data) {
    try {
      const res = await this.ApiRequest.get('offres.json?lang=en', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getPartners() {
    try {
      const res = await this.ApiRequest.get('partenariats.json')
      return res
    } catch (error) {
      return error.response
    }
  }

  async getConsultants() {
    try {
      const res = await this.ApiRequest.get('consultants.json')
      return res
    } catch (error) {
      return error.response
    }
  }
}
