import React, { useEffect, useState } from "react";
import { Box, Container, Skeleton, styled, Typography } from "@mui/material";
import {SectionsHeading} from "../headings";
import lightTheme from "../../styles/theme/lightTheme";
import {MasonryImages} from "../images";
import {BackgroundSection} from "../layouts";
import "../../styles/components/_skeleton.scss";
import Api from "../../services/api.service";
import { handlingErrors } from "../../utils";
import { useNavigate } from "react-router-dom";


const CustomBox = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "80px",

  [theme.breakpoints.up("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "47%",
  },
}));

const TextBox = styled(Box)(({theme}) => ({
  display: "flex",
}));

/*const CustomLinkButton = styled(Box)(({theme}) => ({
  width: "max-content",
  height: 38,
  backgroundColor: lightTheme.palette.primary.b2,
  color: "white",
  textTransform: "uppercase",
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 10px",
  borderRadius: "3px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: lightTheme.palette.primary.main,
  },
  "&:active": {
    backgroundColor: lightTheme.palette.primary.b2
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
    height: 41
  },

  [theme.breakpoints.up("lg")]: {
    height: 45
  },
}))*/

export default function TextWithMasonryImage({title, text1, itemProps}) {
/*  const [button, setButton] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getLinkButton();
  }, [])

  const getLinkButton = async () => {
    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: "fr"
      });

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        const datas = res.data.filter(data => data.section === "recherche-job-boutton");
        setButton(datas);
      }


    } catch (error) {
      console.log(error);
    }
  }*/

  return (
    <BackgroundSection padding={"150px 0 65px 0"}>
      <Container
        maxWidth={"lg"}
        sx={{
          display: "flex",
          flexDirection: {xs: "column", sm: "row"},
          justifyContent: "space-between",
          rowGap: "50px"
        }}
      >
        <CustomBox width={"100%"}>
          {!title ? (
            <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"} />
          ) : (
            <SectionsHeading
              justifyContent={{xs: "center", sm: "flex-start"}}
              width={"100%"}
              maxWidthBox={"100%"}
              type={"animation"}
            >
              {title}
            </SectionsHeading>
          )}

          <TextBox>
            {!text1 ? (
              <Box width={"100%"} display={"block"}>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                <br/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                <br/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
              </Box>
            ) : (
              <Typography variant={"body2"} color={lightTheme.palette.text.primary}>
                {text1}
              </Typography>
            )}
          </TextBox>
        </CustomBox>

        <MasonryImages items={itemProps} type={"animation"} />
      </Container>
    </BackgroundSection>
  )
}