import React from "react";
import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InputBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "5px"
});

const Label = styled('label')({
  fontFamily: "Century Gothic Bold",
  fontSize: "10px",
  lineHeight: "140%",
  color: lightTheme.palette.text.primary,
  letterSpacing: "0.2px",

  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontSize: 14,
  },
})

const CustomInput = styled("input", {
  shouldForwardProp: (prop) => prop !== "EmailInput",
})(({ theme, width}) => ({
  height: 45,
  width: width,
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: "none",
  fontFamily: "Century Gothic",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.grey.main,
  padding: "10px 15px",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 10,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.grey.main,
  },

  "&:active, &:focus": {
    outline: "none"
  },

  [lightTheme.breakpoints.up("md")]: {
    /*width: 320*/
    fontSize: 12,

    "&::placeholder": {
      fontSize: 12,
    },
  },

  [lightTheme.breakpoints.up("lg")]: {
    /*width: 320*/
    fontSize: 14,

    "&::placeholder": {
      fontSize: 14,
    },
  },

}));

export default function PasswordInput({width, value, onChange, register}) {
  const { t } = useTranslation()
  return (
    <InputBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          a: {
            fontFamily: "Century Gothic",
            fontSize: {xs: 8, md: 10, lg: 12},
            lineHeight: "140%",
            letterSpacing: "0.2px",
            color: lightTheme.palette.primary.main,
          }
        }}
      >
        <Label>{t('login.password')}</Label>
        <Link to={"../mot-de-passe-oublie"}>{t('login.forgotPassword')}</Link>
      </Box>

      <CustomInput
        placeholder={t('login.password')}
        type={"password"}
        width={width}
        value={value}
        onChange={onChange}
        autoComplete={"off"}
        name={"password"}
        required
        {...register}
      />
    </InputBox>

  )

}