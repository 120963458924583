import axios from "axios";
import { BASE_URL_UP } from "../../config";

export const contentsOptions =  {
  method: 'GET',
  url: `${BASE_URL_UP}sites`,
  headers: {
    'Accept': 'application/json'
  }
}


export const contentsOptionsOffers =  {
  method: 'GET',
  url: `${BASE_URL_UP}offres`,
  headers: {
    'Accept': 'application/json'
  }
}

export const postDataOptions = {
  method: 'POST',
  url: `${BASE_URL_UP}newsletters`,
  headers: {
    'Accept': 'application/json'
  }
}

export const fetchData = async (url, options) => {
  const response = await fetch(url, options);
  const data = await response.json();

  return data;
}